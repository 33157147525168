import { default as Axios, AxiosResponse } from 'axios';
import { AuthInfo } from '../model/types';
import { CountrySearchFilters } from '../entities/countries-v1/countrySearchFilters';
import {
  CountryBooleanFilter,
  CountryContainsFilter,
  CountrySearchResponse,
  CountryStringEqualsFilter,
  SubdivisionSearchFilters,
  SubdivisionSearchResponse,
} from '../entities/countries-v1/models';
import { formatStateData } from '../utils/util';

class CountriesStates {
  private authInfo: AuthInfo | null;

  private baseUrl: string;

  constructor(authInfo: AuthInfo | null, baseUrl: string) {
    this.authInfo = authInfo;
    this.baseUrl = baseUrl;
  }

  fetchCountries(input: CountrySearchFilters): Promise<AxiosResponse<CountrySearchResponse>> {
    const { token } = this.authInfo as AuthInfo;

    return Axios.post<CountrySearchResponse>(`${this.baseUrl}/countries/search`, input, {
      params: {
        limit: 1000,
        sortBy: 'countryName',
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  fetchSubdivisions(input: string): Promise<AxiosResponse<SubdivisionSearchResponse>> {
    const { token } = this.authInfo as AuthInfo;
    const body = {
      logicalExpression: '$0',
      filters: [
        {
          propertyName: 'alphaThreeCountryCode',
          type: 'stringEquals',
          values: [input],
        },
      ],
    };

    return Axios.post<SubdivisionSearchResponse>(
      `${this.baseUrl}/subdivisions/search?limit=1000&orderBy=ASC&sortBy=subdivisionName`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
}

const statePromiseCache = new Map<string, Promise<{ title: string; code: string }[]>>();

export const getSubdivisionByCountryCache = (
  country: string,
  apiConfig: any,
): Promise<{ title: string; code: string }[]> => {
  if (!country) {
    return Promise.resolve([]);
  }

  if (!statePromiseCache.has(country)) {
    const countriesApi = new CountriesStates(
      apiConfig.authInfo,
      apiConfig.environmentVariables.countriesV1,
    );

    const userPromise = countriesApi.fetchSubdivisions(country)?.then((res: any) => {
      return res?.data?.subdivisions ? formatStateData(res.data.subdivisions) : [];
    });
    statePromiseCache.set(country, userPromise);
  }
  return statePromiseCache.get(country)!;
};

export type FetchCountriesRequest = {
  filters: Array<CountryBooleanFilter | CountryContainsFilter | CountryStringEqualsFilter>;
};
export type FetchSubdivisionsRequest = {
  body: SubdivisionSearchFilters;
  xCatUserPreferences?: string;
};

export default CountriesStates;
