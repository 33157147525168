/* eslint-disable unicorn/consistent-destructuring */
import { Box, IconButton, InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { FC, useState, useEffect, memo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import useStyles from './styles';
import SearchIcon from '../../assets/icons/search-icon';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';
import { useInputWithBlurPrevention } from '../../hooks/input-with-blur-prevention';

declare global {
  interface Window {
    OnetrustActiveGroups: any;
  }
}
export type SearchInputProperties = {
  onClickOfSearch: Function;
  searchHistory: string;
  disabled?: boolean;
  placeholder?: string;
  searchFieldValue: string;
  clearInput: Function;
  searchOnFieldCleared: boolean;
  trimInput?: boolean;
  textFieldWidth?: number;
  onChange?: Function;
  inputError?: string;
};

const SearchInput: FC<SearchInputProperties> = (props: SearchInputProperties) => {
  const {
    onClickOfSearch,
    disabled,
    placeholder,
    searchHistory,
    searchFieldValue,
    clearInput,
    searchOnFieldCleared,
    trimInput,
    textFieldWidth,
    onChange,
    inputError,
  } = props;
  const theme = useTheme();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [input, setInput] = useState<string>(searchFieldValue);
  const [cookieStatus, setCookieStatus] = useState<boolean>(false);
  const [options, setOptions] = useState<Array<string>>([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const { inputRef, handleBlur } = useInputWithBlurPrevention();

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    trimInput ? setInput(event.target.value.trimStart().trim()) : setInput(event.target.value);
  };

  useEffect(() => {
    if (input !== searchFieldValue) {
      setInput(searchFieldValue);
    }
  }, [searchFieldValue]);

  useEffect(() => {
    setCookieStatus(window?.OnetrustActiveGroups?.includes('C0003'));
  }, [window?.OnetrustActiveGroups]);

  const { localStorage } = window;

  useEffect(() => {
    const unparsedRecentHistory = localStorage.getItem(searchHistory);
    if (cookieStatus && unparsedRecentHistory && unparsedRecentHistory.includes('[')) {
      let recentSearches = JSON.parse(unparsedRecentHistory);
      const recentSearchesSet = new Set<string>();
      for (const item of recentSearches) {
        recentSearchesSet.add(item);
      }
      recentSearches = [];
      /* eslint-disable unicorn/no-array-for-each */
      recentSearchesSet.forEach((item: string) => recentSearches.push(item));
      setOptions(recentSearches);
    }
  }, [cookieStatus, localStorage]);
  const handleSearching = () => {
    if (input.length > 0) {
      // eslint-disable-next-line unicorn/prefer-spread
      const newOptions = Array.from(new Set([input, ...options.slice(0, 9)]));
      setOptions(newOptions);
      if (cookieStatus) {
        localStorage.setItem(searchHistory, JSON.stringify(newOptions));
      }
      onClickOfSearch(input);
    } else if (input.length === 0 && searchOnFieldCleared) {
      handleClearInputForSearch();
    }
  };

  const handleClearInputForSearch = () => {
    onClickOfSearch('');
    setInput('');
    clearInput();
  };

  return (
    <Box
      data-testid="search-input-container"
      height={theme.spacing(2.5)}
      width={theme.spacing(textFieldWidth || 40)}
    >
      <Autocomplete
        freeSolo
        id="search-input"
        open={openDropdown}
        onOpen={() => setOpenDropdown(true)}
        onClose={() => setOpenDropdown(false)}
        options={options.map((option) => option)}
        renderOption={(propsAutoComplete, option) => (
          <li
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...propsAutoComplete}
            onClick={() => {
              setOpenDropdown(false);
              onClickOfSearch(option);
            }}
          >
            <Typography noWrap>{option}</Typography>
          </li>
        )}
        onInputChange={(event: object, newInputValue: string) => {
          setInput(newInputValue.trim());
        }}
        value={input || ''}
        className={classes.autoCompletePadding}
        blurOnSelect
        disabled={disabled}
        renderInput={(parameters) => (
          <TextField
            variant="standard"
            {...parameters}
            ref={inputRef}
            disabled={disabled}
            data-testid="search-input"
            className={classes.SearchInput}
            autoComplete="on"
            fullWidth
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSearching();
              }
            }}
            value={input}
            placeholder={placeholder || t(TEXT.COMMON.SEARCH)}
            onChange={handleSearchInputChange}
            onBlur={(e) => {
              handleBlur(e, handleSearching);
            }}
            InputProps={{
              disableUnderline: true,
              ...parameters.InputProps,
              autoComplete: 'search-input',
              endAdornment: (
                <InputAdornment position="end">
                  {input ? (
                    <IconButton
                      data-testid="search-input-clear-btn"
                      onClick={handleClearInputForSearch}
                      size="large"
                      disabled={disabled}
                    >
                      <CancelIcon className={classes.cancelIcon} />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    data-testid="search-input-btn"
                    onClick={handleSearching}
                    size="medium"
                  >
                    <SearchIcon
                      width={theme.spacing(2)}
                      height={theme.spacing(2)}
                      stroke="#43485C"
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {inputError && <div className={classes.inputError}>{inputError}</div>}
    </Box>
  );
};

export default memo(SearchInput);
