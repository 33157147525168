/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RecommendationSecondaryCustomerOrganizationPartialResponse } from './recommendationSecondaryCustomerOrganizationPartialResponse';
import { RecommendedSecondaryCustomerOrganizationDetails } from './recommendedSecondaryCustomerOrganizationDetails';

/**
 * Partial failure response for recommendation item of type \"ASSOCIATE MULTIPLE CAT ASSOCIATION\".
 */
export interface AssociateMultipleCatAssociationsPartialFailureResponse { 
    /**
     * Error Code represents a alpha-numeric error code received from the error.
     */
    code?: string;
    /**
     * Message represents a textual description of a given error code.
     */
    description?: string;
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType?: AssociateMultipleCatAssociationsPartialFailureResponse.RecommendationTypeEnum;
    /**
     * A collection of configs with successful items and additional error details for failures.
     */
    recommendedAssociations?: Array<RecommendedSecondaryCustomerOrganizationDetails | RecommendationSecondaryCustomerOrganizationPartialResponse & any>;
}
export namespace AssociateMultipleCatAssociationsPartialFailureResponse {
    export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEMULTIPLECATASSOCIATION: 'ASSOCIATE MULTIPLE CAT ASSOCIATION' as RecommendationTypeEnum
    };
}