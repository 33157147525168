import { Box, Grid, IconButton, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import React, { ReactElement, FC, MouseEventHandler } from 'react';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';

export type AppPaginationProperties = {
  totalRecordCount: number;
  currentPage: number;
  limit: number;
  onClickActionForPrevious: MouseEventHandler<HTMLButtonElement>;
  onClickActionForNext: MouseEventHandler<HTMLButtonElement>;
  previousButtonDisabled?: boolean;
  customComponents?: ReactElement[];
  bottomPaginationFlag?: boolean;
  isLoading?: boolean;
};

const AppPagination: FC<AppPaginationProperties> = ({
  currentPage,
  limit,
  totalRecordCount,
  onClickActionForNext,
  onClickActionForPrevious,
  previousButtonDisabled,
  customComponents = [],
  bottomPaginationFlag,
  isLoading,
}: AppPaginationProperties) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      pt={bottomPaginationFlag ? '1vh' : 1}
      mb={2}
      pr={2.75}
      pl={2.5}
      data-testid="app-pagination-container"
    >
      <Grid container justifyContent="flex-start" alignItems="center" direction="row">
        <Grid
          container
          data-testid="app-pagination-custom-components"
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>{customComponents?.[0]}</Grid>
          <Grid item data-testid="app-pagination-info">
            <Box display="flex" justifyContent="flex-start">
              {customComponents?.map((component: ReactElement, index: number) => {
                if (index > 0) {
                  return (
                    <React.Fragment key={index}>
                      <Box>{component}</Box>
                      <Typography className={classes.dividerText}>|</Typography>
                    </React.Fragment>
                  );
                }
                return null;
              })}

              <Typography className={classes.paginationText}>
                {t(TEXT.COMMON.PAGINATION, {
                  startCount: (totalRecordCount > 0
                    ? (currentPage * limit + 1).toLocaleString()
                    : 0
                  ).toLocaleString(),
                  endCount: ((currentPage + 1) * limit > totalRecordCount
                    ? totalRecordCount
                    : (currentPage + 1) * limit
                  ).toLocaleString(),
                  totalCount: totalRecordCount.toLocaleString(),
                })}
              </Typography>
              <IconButton
                data-testid="pagination-previous-btn"
                disabled={previousButtonDisabled}
                className={classes.navigationIconPervious}
                onClick={onClickActionForPrevious}
                size="large"
              >
                <NavigateBeforeIcon />
              </IconButton>
              <IconButton
                data-testid="pagination-next-btn"
                disabled={(currentPage + 1) * limit >= totalRecordCount || isLoading}
                className={classes.navigationIconNext}
                onClick={onClickActionForNext}
                size="large"
              >
                <NavigateNextIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppPagination;
