import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ArrowDown = (props: iconType) => {
  const { width, height, fill } = props;
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 10 6"
      style={{ width: width || '10', height: height || '6' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill}
        d="M8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683417 10.0976 1.31658 9.70711 1.70711L8.29289 0.292893ZM5 5L5.70711 5.70711C5.31658 6.09763 4.68342 6.09763 4.29289 5.70711L5 5ZM0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L0.292893 1.70711ZM9.70711 1.70711L5.70711 5.70711L4.29289 4.29289L8.29289 0.292893L9.70711 1.70711ZM4.29289 5.70711L0.292893 1.70711L1.70711 0.292893L5.70711 4.29289L4.29289 5.70711Z"
      />
    </SvgIcon>
  );
};
export default ArrowDown;
