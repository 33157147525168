/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * User Request properties that can be used for sorting. 
 */
export type UserRequestPropertiesForSorting = 'createdTime' | 'email' | 'applicationName' | 'status' | 'partyName';

export const UserRequestPropertiesForSorting = {
    CreatedTime: 'createdTime' as UserRequestPropertiesForSorting,
    Email: 'email' as UserRequestPropertiesForSorting,
    ApplicationName: 'applicationName' as UserRequestPropertiesForSorting,
    Status: 'status' as UserRequestPropertiesForSorting,
    PartyName: 'partyName' as UserRequestPropertiesForSorting
};