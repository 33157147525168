import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Backdrop, Box, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import SubHeader from '../../components/sub-header';
import { Tabs } from '../../components/tabs';
import { AppState, useDispatchTs } from '../../store';
import VariableRowInfiniteScrollTable from '../../components/variable-row-infinite-scroll-table';
import getScreenWidth from '../../hooks/get-screen-width';
import NoDataComponent from '../../components/variable-row-infinite-scroll-table/components/no-data-component';
import AppPagination from '../../components/app-pagination';
import useStyles from './styles';
import dayjs from 'dayjs';
import AccessRequestStatusText from '../../components/access-request-status';
import { UserAccessRequestStatus } from '../../entities/user-onboarding-v1/userAccessRequestStatus';
import AccessRequestColumnSort from '../access-requests/components/access-request-column-sort';
import SortLabelComponent from '../../components/sort-label';
import {
  ReceivedSentType,
  RequestTypes,
  ownershipRequestsActionNames,
} from '../../store/ownership-requests/types';
import Loading from '../../components/loading';
import MultilineTextWithToolTip from '../../components/multiline-text-with-tooltip';
import {
  fetchOwnershipRequests,
  getOwnershipRequest,
} from '../../store/ownership-requests/actions';
import { OwnershipRequestDetails } from '../../entities/customerAssetWorkflow-v1/ownershipRequestDetails';
import { OwnershipRequestSortingProperties } from '../../entities/customerAssetWorkflow-v1/ownershipRequestSortingProperties';
import OwnershipRequestsFilters from './components/ownership-requests-filters';
import FilterTags from '../../components/filter-tags';
import { filterTagsType } from '../../globals/generic-types';
import { displayFirstNameLastName, isIdeographicText } from '../../utils/util';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';
import { assetManagementActionNames } from '../../store/asset-management/types';
import { MODULE_CODES, loginActionNames } from '../../store/login/types';
import MoreOptions from './components/more-options';
import SearchInput from '../../components/search-input';
import searchWarningLogo from '../../assets/images/search-warning.png';

const OwnershipRequests = () => {
  const { t } = useTranslation();
  const dispatch = useDispatchTs();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { environmentVariables, customSettings } = useSelector((state: AppState) => state.login);
  const {
    count,
    cursors,
    isLoadingOwnershipRequests,
    ownershipRequests,
    requestType,
    received,
    sent,
    isLoadingDeleteOwnershipRequest,
  } = useSelector((state: AppState) => state.ownershipRequests);

  const tabs = [
    {
      id: RequestTypes.RECEIVED,
      label: t(TEXT.OWNERSHIP_REQUESTS.TAB.RECEIVED),
      relatedActionComponent: null,
    },
    {
      id: RequestTypes.SENT,
      label: t(TEXT.OWNERSHIP_REQUESTS.TAB.SENT),
      relatedActionComponent: null,
    },
  ];

  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [limit] = useState<number>(100);
  const [tabIndex, setTabIndex] = useState<number>(tabs.findIndex((tab) => tab.id === requestType));
  const [pageInitializing, setPageInitializing] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sortOptionsOpen, setSortOptionsOpen] = useState<boolean>(false);
  const [sortingSelected, setSortingSelected] = useState<boolean>(false);
  const [filterTags, setFilterTags] = useState<filterTagsType>([]);
  const [receivedSentFromStore, setReceivedSentFromStore] = useState<ReceivedSentType>({
    filters: { status: [] },
    filterTags: [],
    sortBy: [],
    orderBy: [],
    searchValue: '',
  });
  const [deleteOwnershipRequestSuccessful, setDeleteOwnershipRequestSuccessful] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const screenWidth = getScreenWidth();

  useEffect(() => {
    setPageInitializing(false);
    const newReceivedSentFromStore =
      requestType.toLocaleUpperCase() === RequestTypes.RECEIVED
        ? structuredClone(received)
        : structuredClone(sent);
    setReceivedSentFromStore(newReceivedSentFromStore);
    buildFilterTags(newReceivedSentFromStore.filters);
    fetchData(true);
  }, [requestType, received, sent, deleteOwnershipRequestSuccessful]);
  useEffect(() => {
    dispatch({
      type: loginActionNames.SET_MODULE_CODE,
      payload: MODULE_CODES.ASSET_MANAGEMENT,
    });
    dispatch({
      type: assetManagementActionNames.SET_SELECTED_ASSET,
      payload: undefined,
    });
  }, []);
  const buildFilterTags = (newFilters: any) => {
    if (newFilters?.status) {
      setFilterTags(
        newFilters.status.map((statusName: string) => {
          return {
            code: statusName,
            removeFunction: () => {
              const updatedFilterTags = newFilters.status.filter(
                (status: string) => status !== statusName,
              );
              dispatch({
                type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTERS,
                payload: {
                  requestType: requestType.toLocaleLowerCase(),
                  filters: {
                    status: updatedFilterTags,
                  },
                },
              });
              dispatch({
                type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTER_TAGS,
                payload: {
                  requestType: requestType.toLocaleLowerCase(),
                  filterTags: updatedFilterTags,
                },
              });
              buildFilterTags({ status: updatedFilterTags });
              fetchData(true);
            },
          };
        }),
      );
    } else {
      setFilterTags([]);
    }
  };
  const fetchData = async (isNew: boolean) => {
    const currentCursor = !isNew && cursors.length > 0 ? cursors[currentPage - 1] : undefined;
    if (isNew) {
      setCurrentPage(0);
      dispatch({
        type: ownershipRequestsActionNames.RESET_CURSORS_FOR_OWNERSHIP_REQUESTS,
      });
    }
    dispatch(fetchOwnershipRequests(currentCursor));
  };

  useEffect(() => {
    if (!pageInitializing) {
      fetchData(false);
    }
  }, [currentPage]);
  const handleClickForPrevious = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleClickForNext = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleSortChange = (newSort: string) => {
    dispatch({
      type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_SORT_BY_VALUE,
      payload: {
        requestType: requestType.toLocaleLowerCase(),
        sortBy: [newSort],
      },
    });
  };
  const handleOrderChange = (newOrder: string) => {
    dispatch({
      type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_ORDER_BY_VALUE,
      payload: {
        requestType: requestType.toLocaleLowerCase(),
        orderBy: [newOrder],
      },
    });
  };
  const handleRowClick = (requestId: string) => {
    dispatch(getOwnershipRequest(requestId));
    navigate('/ownership-details');
  };
  const applyFilters = (newFilters: any) => {
    dispatch({
      type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTERS,
      payload: {
        requestType: requestType.toLocaleLowerCase(),
        filters: newFilters,
      },
    });
    dispatch({
      type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTER_TAGS,
      payload: {
        requestType: requestType.toLocaleLowerCase(),
        filterTags: newFilters.status,
      },
    });
    buildFilterTags(newFilters);
    fetchData(true);
  };
  const clearAllFilters = () => {
    let refetch = false;
    if (receivedSentFromStore.filters?.status?.length > 0) {
      refetch = true;
      dispatch({
        type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTERS,
        payload: {
          requestType: requestType.toLocaleLowerCase(),
          filters: {},
        },
      });
      dispatch({
        type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTER_TAGS,
        payload: {
          requestType: requestType.toLocaleLowerCase(),
          filterTags: [],
        },
      });
      buildFilterTags([]);
    }
    if (receivedSentFromStore.searchValue) {
      refetch = true;
      dispatch({
        type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_SEARCH_VALUE,
        payload: {
          requestType: requestType.toLocaleLowerCase(),
          value: '',
        },
      });
    }
    if (refetch) {
      fetchData(true);
    }
  };
  const customComponentsForAppPagination = [
    environmentVariables.AMTChangesOwnershipFlag ? (
      <SearchInput
        textFieldWidth={48}
        onClickOfSearch={(value: string) => {
          if (isIdeographicText(value) || value.length >= 3) {
            setErrorMessage('');
            dispatch({
              type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_SEARCH_VALUE,
              payload: {
                requestType: requestType.toLocaleLowerCase(),
                value,
              },
            });
          } else {
            setErrorMessage(t(TEXT.ASSETS.ERRORS.MINIMUM_THREE_SYMBOLS));
          }
        }}
        searchHistory="ownershipRequestsSearchHistory"
        placeholder={t(TEXT.OWNERSHIP_REQUESTS.PLACEHOLDERS.SEARCH_SERIAL_NUMBER)}
        searchFieldValue={receivedSentFromStore.searchValue}
        inputError={errorMessage}
        onChange={() => setErrorMessage('')}
        clearInput={() => {
          setErrorMessage('');
          dispatch({
            type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_SEARCH_VALUE,
            payload: {
              requestType: requestType.toLocaleLowerCase(),
              value: '',
            },
          });
        }}
        searchOnFieldCleared
        trimInput
      />
    ) : (
      <div></div>
    ),
    <Box display="flex">
      <Box>
        <OwnershipRequestsFilters
          onClickOfApply={applyFilters}
          setOpenFilterModal={setOpenFilters}
          openFilterModal={openFilters}
          clearAllFilters={clearAllFilters}
          selectedFilters={filterTags.length > 0}
        />
      </Box>
    </Box>,
  ];
  return (
    <Box data-testid="ownership-reqeuests-container">
      <Backdrop
        invisible
        open={!!(isLoadingOwnershipRequests || isLoadingDeleteOwnershipRequest)}
        style={{ zIndex: 2 }}
        onClick={(event: React.MouseEvent) => {
          event.stopPropagation();
        }}
      >
        <Loading blocks={environmentVariables.blocksComponents} />
      </Backdrop>
      <SubHeader title={t(TEXT.COMMON.TITLE.OWNERSHIP_REQUESTS)} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          currentTabIndex={tabIndex}
          onTabChange={(event: React.ChangeEvent<{}>, newValue: number) => {
            setTabIndex(newValue);
            dispatch({
              type: ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_TYPE,
              payload: tabs[newValue].id,
            });
          }}
          tabProps={{ width: '100%' }}
          tabs={tabs}
          data-testid="ownership-tabs"
        />
      </Box>
      <AppPagination
        customComponents={customComponentsForAppPagination}
        currentPage={currentPage}
        limit={limit}
        totalRecordCount={count}
        onClickActionForPrevious={handleClickForPrevious}
        onClickActionForNext={handleClickForNext}
        previousButtonDisabled={currentPage === 0 || isLoadingOwnershipRequests}
        isLoading={isLoadingOwnershipRequests}
      />
      {customSettings?.filterTags && (
        <Box>
          <FilterTags expandable={true} filterTags={filterTags} tagPrefix="ownership-requests" />
        </Box>
      )}
      <Box>
        <VariableRowInfiniteScrollTable
          isNextPageLoading={isLoadingOwnershipRequests}
          rows={ownershipRequests || []}
          nonExpandedRowSizeBasedOnScreenRes={{ xl: 80, lg: 80, md: 96, sm: 160, xs: 160 }}
          expandedRowSizeBasedOnScreenRes={{
            xl: 180,
            lg: 180,
            md: 180,
            sm: 180,
            xs: 180,
          }}
          screenWidth={screenWidth}
          columns={[
            {
              name: 'serial-number',
              title: (
                <Box className={classes.firstColValue}>
                  {t(TEXT.OWNERSHIP_REQUESTS.COLUMN.SERIAL_NUMBER)}
                </Box>
              ),
              width: '15%',
              getCellValue: (row: OwnershipRequestDetails) => (
                <Box className={classes.firstColValue}>
                  <Link
                    className={classes.standardText}
                    onClick={() => {
                      handleRowClick(row?.ownershipRequestIdentifier || '');
                    }}
                  >
                    {row?.asset?.serialNumber}
                  </Link>
                </Box>
              ),
            },
            {
              name: 'make',
              title: t(TEXT.OWNERSHIP_REQUESTS.COLUMN.MAKE),
              width: '8%',
              getCellValue: (row: OwnershipRequestDetails) => (
                <Box className={classes.standardText}>{row?.asset?.make}</Box>
              ),
            },
            {
              name: 'new-owner',
              title: t(TEXT.OWNERSHIP_REQUESTS.COLUMN.NEW_OWNER),
              width: '15%',
              getCellValue: (row: OwnershipRequestDetails) => (
                <Box>
                  <Box>
                    <MultilineTextWithToolTip
                      className={classes.boldRowText}
                      text={`${row?.recommendedCustomerOrganization?.customerOrganizationBusinessName}`}
                      numberOfLines={1}
                    />
                  </Box>
                  <Box>
                    <MultilineTextWithToolTip
                      className={classes.smallRowText}
                      text={`${t(TEXT.COMMON.TITLE.CCID)}: ${row?.recommendedCustomerOrganization?.customerOrganizationIdentifier}`}
                      numberOfLines={1}
                    />
                  </Box>
                </Box>
              ),
            },
            {
              name: 'requested-by',
              title: t(TEXT.OWNERSHIP_REQUESTS.COLUMN.REQUESTED_BY),
              width: '15%',
              getCellValue: (row: OwnershipRequestDetails) => (
                <Box>
                  <Box>
                    <MultilineTextWithToolTip
                      className={classes.boldRowText}
                      text={displayFirstNameLastName(
                        row?.requestedBy?.firstName,
                        row?.requestedBy?.lastName,
                      )}
                      numberOfLines={1}
                    />
                  </Box>
                  <Box>
                    <MultilineTextWithToolTip
                      className={classes.smallRowText}
                      text={row?.requestedBy?.catrecid}
                      numberOfLines={1}
                    />
                  </Box>
                </Box>
              ),
            },
            {
              name: 'requesting-dealer',
              title: t(TEXT.OWNERSHIP_REQUESTS.COLUMN.REQUESTING_DEALER),
              width: '12%',
              getCellValue: (row: OwnershipRequestDetails) => (
                <Box>
                  <Box>
                    <MultilineTextWithToolTip
                      className={classes.smallRowText}
                      text={row?.requestingDealerCode}
                      numberOfLines={1}
                    />
                  </Box>
                </Box>
              ),
            },
            {
              name: 'requested-date',
              title: (
                <SortLabelComponent
                  title={t(TEXT.OWNERSHIP_REQUESTS.COLUMN.REQUESTED_DATE)}
                  name={OwnershipRequestSortingProperties.RequestedTime}
                  sortBy={receivedSentFromStore.sortBy.toString()}
                  columnIdentifier={OwnershipRequestSortingProperties.RequestedTime}
                  sortDirection={receivedSentFromStore.orderBy.toString()}
                  sortOptionsOpen={sortOptionsOpen}
                  setSortOptionsOpen={setSortOptionsOpen}
                  sortingSelected={sortingSelected}
                >
                  <AccessRequestColumnSort
                    propertyName={OwnershipRequestSortingProperties.RequestedTime}
                    options={[
                      {
                        key: 'DESC',
                        value: t(TEXT.COMMON.SORT.MOST_RECENT),
                      },
                      {
                        key: 'ASC',
                        value: t(TEXT.COMMON.SORT.LEAST_RECENT),
                      },
                    ]}
                    sortBy={receivedSentFromStore.sortBy.toString()}
                    orderBy={receivedSentFromStore.orderBy.toString()}
                    handleSortChange={handleSortChange}
                    handleOrderByChange={handleOrderChange}
                    sortPopUpOpen={setSortOptionsOpen}
                    setSortingSelected={setSortingSelected}
                  />
                </SortLabelComponent>
              ),
              width: '15%',
              getCellValue: (row: OwnershipRequestDetails) => (
                <Box className={classes.standardText}>
                  {row?.requestedTime ? dayjs(row.requestedTime).format('MMM DD, YYYY') : ''}
                </Box>
              ),
            },
            {
              name: 'status',
              title: (
                <SortLabelComponent
                  title={t(TEXT.COMMON.TITLE.STATUS)}
                  name="status"
                  sortBy={receivedSentFromStore.sortBy.toString()}
                  columnIdentifier={OwnershipRequestSortingProperties.OwnershipRequestStatusLabel}
                  sortDirection={receivedSentFromStore.orderBy.toString()}
                  sortOptionsOpen={sortOptionsOpen}
                  setSortOptionsOpen={setSortOptionsOpen}
                  sortingSelected={sortingSelected}
                  informationIconFlag={true}
                  reverseSortArrow={true}
                  statusMap={{
                    [`${t(TEXT.COMMON.STATUS.PENDING)}:`]: t(
                      TEXT.OWNERSHIP_REQUESTS.STATUS_DESCRIPTION.PENDING_STATUS,
                    ),
                    [`${t(TEXT.COMMON.STATUS.APPROVED)}:`]: t(
                      TEXT.OWNERSHIP_REQUESTS.STATUS_DESCRIPTION.APPROVED_STATUS,
                    ),
                    [`${t(TEXT.COMMON.STATUS.DENIED)}:`]: t(
                      TEXT.OWNERSHIP_REQUESTS.STATUS_DESCRIPTION.DENIED_STATUS,
                    ),
                  }}
                >
                  <AccessRequestColumnSort
                    propertyName={OwnershipRequestSortingProperties.OwnershipRequestStatusLabel}
                    options={[
                      {
                        key: 'ASC',
                        value: t(TEXT.COMMON.SORT.A_TO_Z),
                      },
                      {
                        key: 'DESC',
                        value: t(TEXT.COMMON.SORT.Z_TO_A),
                      },
                    ]}
                    sortBy={receivedSentFromStore.sortBy.toString()}
                    orderBy={receivedSentFromStore.orderBy.toString()}
                    handleSortChange={handleSortChange}
                    handleOrderByChange={handleOrderChange}
                    sortPopUpOpen={setSortOptionsOpen}
                    setSortingSelected={setSortingSelected}
                  />
                </SortLabelComponent>
              ),
              width: '19%',
              getCellValue: (row: OwnershipRequestDetails) => (
                <Box>
                  {row?.ownershipRequestStatus?.code && (
                    <AccessRequestStatusText
                      status={row.ownershipRequestStatus.code as UserAccessRequestStatus}
                    />
                  )}
                </Box>
              ),
            },
            {
              name: 'moreOptions',
              title: <div style={{ width: 0.026 * window.innerWidth + 16 }} />,
              getCellValue: (row: OwnershipRequestDetails) => {
                return (
                  <div
                    style={{ width: 0.026 * window.innerWidth + 16 }}
                    data-testid="moreOptionsTestId"
                  >
                    {row?.ownershipRequestStatus?.code === UserAccessRequestStatus.PENDING &&
                    requestType.toLocaleUpperCase() === RequestTypes.SENT ? (
                      <MoreOptions
                        row={row}
                        setDeleteOwnershipRequestSuccessful={setDeleteOwnershipRequestSuccessful}
                      />
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="row-reverse"
                        mr={5}
                        data-testid="placeholder-option"
                      ></Box>
                    )}
                  </div>
                );
              },
              align: 'center',
              width: '1%',
            },
          ]}
          hasNextPage={false}
          height={window.innerHeight - 0.34 * window.innerHeight}
          loadNextPage={() => {
            return fetchData(false);
          }}
          expandedRowFlag={false}
          expandedRowComponent={(item: any) => null}
          skipEntitlementCheck={true}
          hideNoDataComponent={!!(isLoadingOwnershipRequests || isLoadingDeleteOwnershipRequest)}
          noDataComponent={
            isLoadingOwnershipRequests ? null : receivedSentFromStore.searchValue ? (
              <NoDataComponent
                line1={t(TEXT.COMMON.NO_DATA)}
                line2={t(TEXT.COMMON.METADATA.BASE.TRY_REVISING_CRITERIA)}
                searchWarningImage={searchWarningLogo}
              />
            ) : (
              <NoDataComponent line1={t(TEXT.OWNERSHIP_REQUESTS.COLUMN.NO_REQUESTS)} />
            )
          }
        />
      </Box>
    </Box>
  );
};
export default OwnershipRequests;
