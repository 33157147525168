import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  lessTagContainer: {
    textAlign: 'end',
    display: 'inline-flex',
    overflow: 'hidden',
    //flexFlow: 'wrap',
    alignContent: 'flex-start',
  },
  moreTagContainer: {
    textAlign: 'end',
    display: 'flex',
    flexFlow: 'wrap',
    alignContent: 'flex-start',
    placeContent: 'flex-end',
    width: '100%',
  },
  readOnlyIdentifier: {
    paddingLeft: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '12px',
  },
  linkButton: {
    textAlign: 'end',
  },

  dropdownLabel: {
    color: CAT_COLOR.STORM_GREY,
    fontSize: '12px',
  },
  roleLabel: {
    color: CAT_COLOR.DARK_GRAYISH_BLUE,
    fontSize: '14px',
    fontWeight: 700,
  },
  placeholder: {
    fontStyle: 'italic',
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    fontSize: '14px',
  },
  noWrap: {
    textWrap: 'nowrap',
  },
}));
