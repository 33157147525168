/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactNode, ChangeEvent } from 'react';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Tabs as MuiTabs, Tab, Box } from '@mui/material';
import { useTabsStyles, useTabItemStyles } from './styles';
import { RelatedAction } from './components/related-actions';

const theme = createTheme();
interface TabItemProps {
  id: string;
  label: string;
  relatedActionComponent: ReactNode | null;
  isHidden?: boolean;
}

interface TabsProps {
  tabs: TabItemProps[];
  tabProps: {};
  currentTabIndex: number;
  onTabChange: (event: ChangeEvent<{}>, value: any) => void;
}

const Tabs: FC<TabsProps> = (properties) => {
  const { currentTabIndex, onTabChange, tabs, tabProps, ...otherProperties } = properties;

  const { classes: tabsClasses } = useTabsStyles(otherProperties);
  const { classes: tabClasses } = useTabItemStyles(tabProps);
  const visibleTabs = tabs.filter((tab) => tab.isHidden !== true);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <MuiTabs
              textColor="inherit"
              classes={tabsClasses}
              {...otherProperties}
              value={currentTabIndex}
              onChange={onTabChange}
            >
              {visibleTabs.map((tab: TabItemProps) => {
                return (
                  <Tab
                    key={tab?.id}
                    data-testid={tab?.id}
                    {...tabProps}
                    classes={tabClasses}
                    label={tab.label}
                  />
                );
              })}
            </MuiTabs>
          </Box>
          <Box>
            {visibleTabs.map((tab: TabItemProps, index: number) => {
              return (
                <RelatedAction key={tab?.id} value={currentTabIndex} index={index}>
                  {tab.relatedActionComponent}
                </RelatedAction>
              );
            })}
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export { Tabs };
export type { TabsProps };
