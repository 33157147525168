import React, { ReactElement, FC } from 'react';
import { Box, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { CatProgressTracker } from 'blocks-react/bedrock/components/ProgressTracker';
import { CatProgressTrackerItem } from 'blocks-react/bedrock/components/ProgressTrackerItem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

export type AppStepperProperties = {
  steps: Array<string>;
  getStepContent: (stepIndex?: number) => string | ReactElement;
  activeStep: number;
  noResults?: boolean;
  blocks?: boolean;
};

export type StepperIcon = 'visited' | 'current' | 'unvisited' | 'warning' | 'error';

const AppStepper: FC<AppStepperProperties> = (props: AppStepperProperties) => {
  const { activeStep, steps, getStepContent, noResults, blocks } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const StepIcon = (index: number) => {
    let result: any = '';
    if (
      index === activeStep &&
      activeStep !== steps.length - 1 &&
      !(index === activeStep && noResults)
    ) {
      result = <FiberManualRecordOutlinedIcon className={classes.currentStep} />;
    } else if (
      (index === activeStep && noResults) ||
      index < activeStep ||
      activeStep === steps.length - 1
    ) {
      result = <CheckCircleIcon className={classes.completedIcon} />;
    } else {
      result = <FiberManualRecordIcon className={classes.pendingSteps} />;
    }
    return result;
  };

  const catStepIcon = (index: number) => {
    let result: StepperIcon;
    if (
      index === activeStep &&
      activeStep !== steps.length - 1 &&
      !(index === activeStep && noResults)
    ) {
      result = 'current';
    } else if (
      (index === activeStep && noResults) ||
      index < activeStep ||
      activeStep === steps.length - 1
    ) {
      result = 'visited';
    } else {
      result = 'unvisited';
    }
    return result;
  };

  return (
    <>
      {blocks ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ padding: 24 }}>
            <CatProgressTracker>
              {steps.map((label, index) => (
                <CatProgressTrackerItem
                  key={label}
                  label={t(label)}
                  variant={catStepIcon(index)}
                  stepNumber={index.toString()}
                />
              ))}
            </CatProgressTracker>
          </div>
        </div>
      ) : (
        <Grid container alignItems="center" data-testid="app-stepper-label">
          <Grid xs={2} item />
          <Grid xs={8} item>
            <Stepper
              style={{ padding: 24 }}
              activeStep={activeStep}
              alternativeLabel
              className={classes.stepStyle}
            >
              {steps.map((label, index) => (
                <Step key={t(label)}>
                  <StepLabel icon={StepIcon(index)}>{t(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid xs={2} item />
        </Grid>
      )}
      <Box data-testid="app-stepper-content-container">
        <Grid container className={classes.customerDetails}>
          <Grid xs={1} item />
          <Grid xs={10} item>
            <Box display="flex" justifyContent="center">
              {getStepContent(activeStep)}
            </Box>
          </Grid>
          <Grid xs={1} item />
        </Grid>
      </Box>
    </>
  );
};
export default AppStepper;
