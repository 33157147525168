/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AssociateMultipleCatAssociationResponseArray } from './associateMultipleCatAssociationResponseArray';
import { RecommendationGroupId } from './recommendationGroupId';
import { ResponseMetadata } from './responseMetadata';

/**
 * Response with details of recommendations available for recommended parent organization.
 */
export interface RecommendedCustomerOrganizationsResponse { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType?: RecommendedCustomerOrganizationsResponse.RecommendationTypeEnum;
    recommendationGroupId?: RecommendationGroupId;
    recommendationItems?: AssociateMultipleCatAssociationResponseArray;
    responseMetadata?: ResponseMetadata;
}
export namespace RecommendedCustomerOrganizationsResponse {
    export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEMULTIPLECATASSOCIATION: 'ASSOCIATE MULTIPLE CAT ASSOCIATION' as RecommendationTypeEnum
    };
}