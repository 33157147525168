import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const InactiveAccountIcon = ({
  marginTop,
  marginLeft,
  stroke = '#78869E',
  ...restProps
}: iconType) => (
  <SvgIcon
    stroke={stroke}
    {...restProps}
    viewBox="0 0 17 17"
    style={{
      height: 'auto',
      fill: 'none',
      width: restProps.width || '20px',
      marginTop: marginTop || 0,
      marginLeft: marginLeft || 0,
    }}
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path
      d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.17383 4.91309V8.50004L12.0869 12.087"
      stroke="#43485C"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default InactiveAccountIcon;
