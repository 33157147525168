import { Button } from '@mui/material';
import React, { FC, MouseEventHandler } from 'react';

type StepperButtonProperties = {
  id: string;
  type?: 'submit' | 'reset' | 'button';
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  onClick?: MouseEventHandler;
  buttonText: string;
  disabled?: boolean;
  className?: string;
};

const StepperButton: FC<StepperButtonProperties> = (props: StepperButtonProperties) => {
  const {
    id,
    type = 'button',
    color = 'primary',
    onClick = () => {},
    buttonText,
    disabled = false,
    className,
  } = props;
  return (
    <Button
      className={className}
      data-testid={id}
      type={type}
      variant="contained"
      color={color || 'primary'}
      onClick={onClick}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  );
};

export default StepperButton;
