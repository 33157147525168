import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const FilterIcon = (props: iconType) => {
  const { width, height, stroke } = props;
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 22 20"
      style={{ fill: 'none', width: width || '22px', height: height || '20' }}
    >
      <path
        stroke={stroke}
        strokeWidth={'1.25'}
        d="M20 1H2L8.28571 8.76048V19L13.7143 17.0599V8.76048L20 1Z"
      />
    </SvgIcon>
  );
};

export default FilterIcon;
