/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, TextField, Checkbox, Chip, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckedIcon from '../../assets/icons/checked-icon';
import useStyles from './styles';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckedIcon width="16" height="16" />;

type DropdownMultiSelectProperties = {
  label: string;
  dropdownValues?: string[];
  onSelectCallbackFunction: Function;
  prevSelectedValue: string[];
  disabled?: boolean;
  customClass?: string;
  dataTestID?: string;
  placeholder?: string;
  marginTop?: number;
};
const DropdownMultiSelect = (props: DropdownMultiSelectProperties) => {
  const {
    label,
    dropdownValues = [],
    onSelectCallbackFunction,
    prevSelectedValue,
    disabled = false,
    customClass,
    dataTestID,
    placeholder,
    marginTop = 4,
  } = props;
  const { classes } = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<Array<string>>([]);

  const handleChange = (event: React.ChangeEvent<{}>, value: Array<string>) => {
    onSelectCallbackFunction(value);
    setSelectedOptions(value);
  };

  useEffect(() => {
    if (prevSelectedValue) {
      setSelectedOptions(prevSelectedValue);
    }
  }, [prevSelectedValue]);
  return (
    <Box
      mt={marginTop}
      alignContent="center"
      data-testid={dataTestID || 'drop-down-multi-select-container'}
    >
      <Autocomplete
        data-testid="auto-complete-input-container"
        multiple
        id="dealer-codes-multi-select"
        options={dropdownValues || []}
        disableCloseOnSelect
        disabled={disabled}
        disableClearable
        getOptionLabel={(option) => option || ''}
        value={selectedOptions}
        classes={{
          input: classes.input,
          paper: classes.paper,
        }}
        onChange={handleChange}
        renderTags={(value: string[], getTagProperties) =>
          value.map((option: string, index: number) => (
            <Chip
              data-testid={`selected-option-chip-${option}`}
              variant="outlined"
              label={option}
              {...getTagProperties({ index })}
              onDelete={() => {
                const selectedChip: string = option;
                const currentValues = [...selectedOptions];
                const chipIndex = currentValues.indexOf(selectedChip);
                currentValues.splice(chipIndex, 1);
                onSelectCallbackFunction(currentValues);
                setSelectedOptions(currentValues);
              }}
            />
          ))
        }
        renderOption={(renderProps, option: string, { selected }) => (
          <li {...renderProps} data-testid="multi-drop-down-renderer" className={classes.option}>
            <Box>
              <Checkbox
                color="secondary"
                data-testid={option}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                sx={{ '&.Mui-checked': { marginLeft: '2px' } }}
                checked={selected}
              />
            </Box>
            <Box mt={1.3} ml={selected ? '1px' : 0}>
              <Typography>{option}</Typography>
            </Box>
          </li>
        )}
        renderInput={(parameters) => (
          <TextField
            variant="standard"
            className={customClass ? customClass : classes.textField}
            {...parameters}
            label={label}
            InputLabelProps={{ shrink: true }}
            fullWidth
            placeholder={placeholder || ''}
          />
        )}
      />
    </Box>
  );
};

export default DropdownMultiSelect;
