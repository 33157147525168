import React, { useMemo } from 'react';
import { Tooltip, Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import InfoIcon from '../../assets/icons/info-icon';
import { iconVariant } from '../../assets/icons/icon-types';
import useStyles from './styles';
import parse from 'html-react-parser';

type InformationIconPopOverProperties = {
  width?: string;
  height?: string;
  variant?: iconVariant;
  shape?: string;
  marginTop?: string;
  statusMap?: { [key: string]: string | undefined };
  keySuffix?: string;
  placement?: 'bottom-end' | 'top-end';
  tooltipMaxWidth?: string;
  color?: string;
  noBold?: boolean;
  htmlElement?: string;
};

const InformationIconPopOverStatus = ({
  width = '14px',
  height = '14px',
  variant,
  shape = 'info',
  marginTop,
  statusMap,
  keySuffix,
  htmlElement,
  placement = 'bottom-end',
  tooltipMaxWidth = '450px',
  color,
  noBold,
}: InformationIconPopOverProperties) => {
  const LightTooltip = withStyles(Tooltip, (theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[10],
      padding: theme.spacing(2),
      fontSize: 12,
      maxWidth: tooltipMaxWidth,
      left: 3,
    },
    arrow: {
      color: theme.palette.common.white,
    },
  }));
  const { classes } = useStyles();
  const buildStatusElements = useMemo(() => {
    const statusElements = statusMap
      ? Object.keys(statusMap).map((key) => (
          <Box key={key}>
            {statusMap[key] && (
              <Box>
                <Box component="span" className={noBold ? '' : classes.bold}>
                  {key}
                  {keySuffix ? keySuffix : ''}
                </Box>{' '}
                {statusMap[key]}
              </Box>
            )}
          </Box>
        ))
      : htmlElement && <Box>{parse(htmlElement)}</Box>;

    return <Box>{statusElements}</Box>;
  }, [statusMap, noBold, classes, htmlElement]);
  return (
    <LightTooltip
      data-testid="information-icon-tooltip"
      title={buildStatusElements}
      arrow
      placement={placement}
      sx={{ display: 'flex' }}
    >
      <Box data-testid="info-icon">
        {shape === 'info' && (
          <InfoIcon
            width={width}
            height={height}
            variant={variant}
            marginTop={marginTop}
            iconColor={color}
          />
        )}
      </Box>
    </LightTooltip>
  );
};

export default InformationIconPopOverStatus;
