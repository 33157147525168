import TEXT from '../globals/translation-map';
export const VISIONLINK = 'VisionLink';

export const addAssetSteps = [
  TEXT.ASSETS.ADD_ASSET.ADD_ASSET_STEPS.ENTER_ASSET_INFO,
  TEXT.ASSETS.ADD_ASSET.ADD_ASSET_STEPS.ASSIGN_OWNER,
  TEXT.COMMON.TITLE.REVIEW_RESULTS,
];

export const sessionExpiredText =
  'Please refresh your internet browser as your session has expired. If you have not been active for an extended period of time, you may be redirected to the log in screen.';

export const Routes = {
  HOMEPAGE: '/home-page',
};

export const ApplicationName = {
  VISIONLINK: 'VisionLink',
  PCC: 'Parts.cat.com',
  PCC_NON_PROD: 'Parts.cat.com (Non Prod)',
  CAT_CENTRAL: 'Cat Central',
};

export const EnvironmentName = {
  LOCAL: 'local',
  DEV: 'dev',
  INT: 'int',
  PROD: 'prod',
};
