import React from 'react';
import HeaderGrid from '../../../../components/header-grid';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import { useTheme } from '@mui/material';

type AssetDetailsProperties = {
  makeModelText: string;
  serialNumberText: string;
  productFamilyText: string;
  yearText: string;
  dealerAssetIDText: string;
};
const AssetDetails = (props: AssetDetailsProperties) => {
  const { makeModelText, serialNumberText, productFamilyText, yearText, dealerAssetIDText } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <HeaderGrid
      width={theme.spacing(100)}
      columns={5}
      items={[
        {
          label: t(TEXT.ASSETS.COLUMNS.MAKE_AND_MODEL),
          text: makeModelText,
        },
        { label: t(TEXT.ASSETS.COLUMNS.SERIAL_NUMBER), text: serialNumberText || '--' },
        { label: t(TEXT.ASSETS.COLUMNS.PRODUCT_FAMILY), text: productFamilyText || '--' },
        { label: t(TEXT.ASSETS.COLUMNS.MFG_YEAR), text: yearText || '--' },
        {
          label: t(TEXT.ASSETS.COLUMNS.DEALER_ASSET_ID),
          text: dealerAssetIDText,
        },
      ]}
    />
  );
};

export default AssetDetails;
