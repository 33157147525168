import { MetadataState, metadataActionNames, MetadataActionTypes } from './types';

const initialState: MetadataState = {
  applications: [],
  applicationRoles: [],
  isLoadingDC: false,
  isLoadingRole: false,
  manufacturers: [],
  isLoadingRoleFailed: false,
};

const metadataReducer = (state = initialState, action: MetadataActionTypes): MetadataState => {
  switch (action.type) {
    case metadataActionNames.SET_APPLICATIONS: {
      return {
        ...state,
        applications: action.payload,
      };
    }
    case metadataActionNames.SET_APPLICATION_ROLES: {
      return {
        ...state,
        applicationRoles: action.payload,
      };
    }

    case metadataActionNames.SET_APPLICATIONS_LOADING_DC: {
      return {
        ...state,
        isLoadingDC: action.payload,
      };
    }

    case metadataActionNames.SET_APPLICATIONS_LOADING_ROLE: {
      return {
        ...state,
        isLoadingRole: action.payload,
      };
    }

    case metadataActionNames.SET_APPLICATIONS_MANUFACTURERS: {
      return {
        ...state,
        manufacturers: action.payload,
      };
    }

    case metadataActionNames.SET_APPLICATIONS_LOADING_FAILED: {
      return {
        ...state,
        isLoadingRoleFailed: action.payload,
      };
    }

    default:
      return state;
  }
};

export default metadataReducer;
