import { createTheme, Theme } from '@mui/material/styles';
import CAT_COLOR from '../color-properties';

// eslint-disable-next-line unicorn/prefer-module
require('./noto-sans');

const letterSpacing = '0.5px';

const theme: Theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: CAT_COLOR.DARK_GRAYISH_BLUE,
          '&.Mui-selected': {
            color: CAT_COLOR.DARK_GRAYISH_BLUE,
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          marginTop: 6,
          boxShadow: '0px 0px 4px 0px #00000052',
          borderRadius: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiInput-underline:before': {
            borderBottom: `1px solid ${CAT_COLOR.DARK_SLATE_GRAY}`,
          },
        },
        input: {
          fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 400,
          borderBottom: `1px solid ${CAT_COLOR.DARK_SLATE_GRAY}`,
          '&:hover': {
            borderBottom: `1px solid ${CAT_COLOR.BRIGHT_GOLD}`,
          },
          '&:focus': {
            backgroundColor: CAT_COLOR.WHITE_SMOKE,
            borderBottom: `1px solid ${CAT_COLOR.BRIGHT_GOLD}`,
          },
        },
        multiline: {
          padding: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: CAT_COLOR.PERSIAN_RED,
          '&.Mui-error': {
            color: CAT_COLOR.PERSIAN_RED,
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${CAT_COLOR.BRIGHT_GOLD}`,
          },
          '&:after': {
            borderBottom: `1px solid ${CAT_COLOR.BRIGHT_GOLD}`,
          },
        },
        input: {
          '&::placeholder': {
            fontStyle: 'italic',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          boxShadow: 'none',
          fontSize: '14px',
          fontWeight: 400,
          textTransform: 'none',
        },
        containedError: {
          backgroundColor: `${CAT_COLOR.PIGMENT_RED} !important`,
        },
        contained: {
          backgroundColor: CAT_COLOR.BLACK,
          color: CAT_COLOR.WHITE,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        containedPrimary: {
          backgroundColor: CAT_COLOR.BRIGHT_GOLD,
          color: CAT_COLOR.BLACK,
          fontWeight: 600,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: CAT_COLOR.LIGHTEST_YELLOW,
          },
          '&:disabled': {
            backgroundColor: CAT_COLOR.BRIGHT_GOLD,
            opacity: '0.5',
            color: CAT_COLOR.BLACK,
          },
        },
        containedSecondary: {
          backgroundColor: CAT_COLOR.WHITE,
          border: `1px solid #6E7880`,
          color: CAT_COLOR.BLACK,
          fontWeight: 600,
          '&:hover': {
            boxShadow: 'none',
            backgroundColor: CAT_COLOR.ALABASTER,
            border: `1px solid ${CAT_COLOR.SLATE}`,
          },
          '&:disabled': {
            border: '1px solid #DBDEDF',
            background: CAT_COLOR.ALABASTER,
            color: CAT_COLOR.SILVER,
          },
        },
        containedInherit: {
          backgroundColor: CAT_COLOR.BLACK,
          border: `1px solid ${CAT_COLOR.PALE_SKY}`,
          color: CAT_COLOR.WHITE,
          fontWeight: 600,
          '&:hover': {
            boxShadow: 'none',
            border: `1px solid #0000000a`,
            // eslint-disable-next-line regex/invalid
            backgroundColor: '#0000000f',
          },
          '&:disabled': {
            border: `1px solid ${CAT_COLOR.MEDIUM_GRAY}`,
            // eslint-disable-next-line regex/invalid
            background: '#0000001f',
            // eslint-disable-next-line regex/invalid
            color: '#00000042',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          color: CAT_COLOR.WATER_BLUE,
          cursor: 'pointer',
          '&:hover': {
            fontWeight: 700,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthMd: {
          borderRadius: '0',
          height: '80%',
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#78869E',
      main: '#43485c',
      dark: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      light: '#F7F7F3',
      main: '#FFCC11',
      dark: '#DCB520',
      contrastText: '#000000',
    },
    error: {
      light: CAT_COLOR.LIGHT_GRAY,
      main: CAT_COLOR.PIGMENT_RED,
      dark: CAT_COLOR.PIGMENT_RED,
      contrastText: '#fff',
    },
    warning: {
      light: CAT_COLOR.SUNSET,
      main: CAT_COLOR.ORANGE,
      dark: CAT_COLOR.ORANGE,
      contrastText: CAT_COLOR.WHITE,
    },
    info: {
      light: CAT_COLOR.TROPICAL_BLUE,
      main: CAT_COLOR.AZURE,
      dark: CAT_COLOR.AZURE,
      contrastText: CAT_COLOR.WHITE,
    },
    success: {
      light: CAT_COLOR.SEA_MIST,
      main: CAT_COLOR.FOREST_GREEN,
      dark: CAT_COLOR.FOREST_GREEN,
      contrastText: CAT_COLOR.WHITE,
    },
  },
  typography: {
    fontFamily: ['NotoSans', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '48px',
      lineHeight: '58px',
      letterSpacing,
      color: CAT_COLOR.DARK_GRAYISH_BLUE,
    },
    h2: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '24px',
      lineHeight: '34px',
      letterSpacing,
    },
    // Title within Sub Header
    h3: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '20px',
      lineHeight: '32px',
      fontWeight: 700,
      letterSpacing,
    },
    h4: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: 600,
      letterSpacing,
      '& .subheading': {
        fontWeight: '400',
      },
    },
    h5: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      lineHeight: '18px',
      letterSpacing,
    },
    h6: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing,
    },
    subtitle1: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: 600,
      letterSpacing,
    },
    // Button Text
    subtitle2: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 600,
      letterSpacing,
    },
    body1: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 400,
      letterSpacing,
    },
    body2: {
      fontFamily: 'NotoSans, Helvetica, Arial, sans-serif',
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400,
      letterSpacing,
    },
    button: {
      borderRadius: 0,
      boxShadow: 'none',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1024,
      lg: 1366,
      xl: 1920,
    },
  },
});

export default theme;
