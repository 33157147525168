import { Box, IconButton, TextField, Popover, InputAdornment } from '@mui/material';
import React, { useState } from 'react';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import { CalendarTodayOutlined, Close } from '@mui/icons-material';
import StepperButton from '../stepper-button';
import useStyles from './styles';
import * as dateFnsLocales from 'date-fns/locale';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import TEXT from '../../globals/translation-map';
import { useTranslation } from 'react-i18next';

type DateRangePickerProperties = {
  selectedDate?: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
  handleApply(date: { startDate: Date | undefined; endDate: Date | undefined }): void;
  handleClose: Function;
  minDateSelection?: Date;
  focused?: boolean;
  maxDate?: Date | null;
};

const MyContainer = (props: { children: React.ReactNode[]; className: any }): React.ReactNode => {
  const { children, className } = props;
  return (
    <CalendarContainer className={className}>
      <div data-testid="calendar-container" style={{ position: 'relative' }}>
        {children}
      </div>
    </CalendarContainer>
  );
};

const DateRangePicker = (props: DateRangePickerProperties) => {
  const { classes } = useStyles();
  const {
    handleApply,
    handleClose,
    selectedDate = undefined,
    minDateSelection = null,
    focused = false,
    maxDate = null,
  } = props;
  const { t } = useTranslation();
  const { language } = useSelector((state: AppState) => state.globalPreferences.userPreferences);
  const [anchor, setAnchor] = useState<Element | null>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [customDate, setCustomDate] = useState<
    | {
        startDate: Date | undefined;
        endDate: Date | undefined;
      }
    | undefined
  >(selectedDate);

  const currentLanguage = language ? language.replace('-', '') : 'enUS';
  const openAE = Boolean(anchor);
  const id = openAE ? 'simple-popover' : undefined;
  const formatDate = () => {
    return [customDate?.startDate || new Date(), customDate?.endDate || new Date()];
  };

  const onOpen = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
    setOpenPopup(true);
  };

  registerLocale(currentLanguage, dateFnsLocales[currentLanguage as keyof typeof dateFnsLocales]);

  const getSelectedDateRange = () =>
    selectedDate?.startDate && selectedDate.endDate
      ? t(TEXT.COMMON.CALENDAR_DATE_RANGE, {
          start: selectedDate?.startDate,
          end: selectedDate?.endDate,
          dateFormat: 'MMM D, YYYY',
        }).toLocaleUpperCase()
      : '';

  return (
    <Box mb={3}>
      <Box alignItems="center" data-testid="custom-date-range-picker">
        <Box mb={2} display="flex">
          <IconButton
            data-testid="calendar-icon"
            className={classes.customDatePickerContainer}
            onClick={onOpen}
            aria-describedby={id}
            disableRipple
            disableFocusRipple
            size="large"
          >
            <TextField
              variant="standard"
              aria-describedby={id}
              data-testid="date-range-input"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayOutlined className={classes.datePickerCalendarIcon} />
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={getSelectedDateRange()}
            />
          </IconButton>

          {selectedDate?.startDate && selectedDate.endDate && (
            <IconButton
              data-testid="close-icon"
              onClick={() => {
                setCustomDate({ startDate: undefined, endDate: undefined });
                handleClose();
              }}
              size="large"
            >
              <Close className={classes.datePickerCloseIcon} />
            </IconButton>
          )}

          {
            <Popover
              className={classes.customDatePicker}
              data-testid="date-range-picker-popover"
              id={id}
              open={openPopup}
              anchorEl={anchor}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <DatePicker
                locale={currentLanguage}
                data-testid="date-range-picker-container"
                selected={customDate?.startDate}
                onChange={(dates: [Date | null, Date | null]) => {
                  const [start, end] = dates as [Date, Date];
                  setCustomDate({ startDate: start, endDate: end });
                }}
                startDate={customDate?.startDate}
                endDate={customDate?.endDate}
                selectsRange
                inline
                minDate={minDateSelection}
                maxDate={maxDate}
                monthsShown={2}
                calendarContainer={MyContainer}
                highlightDates={formatDate() as Date[]}
                focusSelectedMonth={focused}
                disabledKeyboardNavigation
              />
              <Box display="flex" justifyContent="flex-end" className={classes.buttonContainer}>
                <Box mr={2}>
                  <StepperButton
                    buttonText="Cancel"
                    id="cancel-calendar-selection"
                    color="secondary"
                    onClick={(event) => {
                      setTimeout(() => {
                        setOpenPopup(false);
                      }, 100);
                      setCustomDate(selectedDate);
                    }}
                  />
                </Box>
                <Box mr={2}>
                  <StepperButton
                    type="submit"
                    buttonText="Apply"
                    id="apply-calendar-selection"
                    color="primary"
                    disabled={!customDate?.startDate || !customDate.endDate}
                    onClick={async () => {
                      setTimeout(() => {
                        setOpenPopup(false);
                      }, 100);
                      handleApply({
                        startDate: customDate?.startDate,
                        endDate: customDate?.endDate,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Popover>
          }
        </Box>
      </Box>
    </Box>
  );
};

export default DateRangePicker;
