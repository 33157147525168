import { Grid, Link } from '@mui/material';
import React, { useRef, useState } from 'react';
import useStyles from './styles';
import FilterTagChip from '../filter-tag-chip';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';

type FilterTagsProperties = {
  filterTags:
    | Array<{
        code: string;
        removeFunction: Function;
      }>
    | undefined;
  expandable?: Boolean;
  tagPrefix?: string;
  tagLength?: number;
  tagMaxWidth?: string;
};

const MAX_FILTERS_WITHOUT_HIDE = 4;

const FilterTags = (properties: FilterTagsProperties) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();
  const { filterTags, expandable, tagLength, tagMaxWidth, tagPrefix = 'default' } = properties;
  const [expanded, setExpanded] = useState(false);

  return (
    <Grid
      container
      ref={containerRef}
      spacing={1}
      data-testid="filter-tags-container"
      className={
        expandable
          ? expanded
            ? classes.filterTagContainerExpanded
            : classes.filterTagContainer
          : classes.noExpansionContainer
      }
    >
      <Grid item xs={11.1}>
        <Grid
          container
          spacing={1}
          className={expandable ? classes.tagsContainer : classes.noExpansionTagsContainer}
        >
          {filterTags &&
            !!filterTags.length &&
            filterTags
              .slice(
                0,
                expandable
                  ? expanded
                    ? filterTags.length
                    : MAX_FILTERS_WITHOUT_HIDE
                  : filterTags.length,
              )
              .map((item: any, index: number) => {
                return (
                  <Grid item key={index}>
                    <FilterTagChip
                      key={index}
                      text={item.code}
                      tagLength={tagLength}
                      removeFilter={item.removeFunction}
                      tagPrefix={tagPrefix}
                      maxWidth={tagMaxWidth}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </Grid>
      {expandable && filterTags && filterTags?.length > MAX_FILTERS_WITHOUT_HIDE && (
        <Grid
          item
          onClick={() => setExpanded(!expanded)}
          className={classes.showHideContainer}
          data-testid="show-hide"
        >
          {
            <Link className={classes.showHide}>
              {expanded
                ? t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.SHOW_LESS, { value: filterTags?.length })
                : t(TEXT.COMMON.FILTERS.BUTTON_OPTIONS.SHOW_ALL, { value: filterTags?.length })}
            </Link>
          }
        </Grid>
      )}
    </Grid>
  );
};

export default FilterTags;
