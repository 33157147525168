import * as yup from 'yup';
import { PartyType } from '../entities/user-onboarding-v1/partyType';
import countriesData, { countriesWithNoZipCode } from '../globals/countries-data';
import oldCountriesData from '../globals/old-countries-data';
import { getSubdivisionByCountryCache } from '../services/countries-states';
import { presetFilterValue } from '../store/access-request/types';
import i18n from 'i18next';
import TEXT from '../globals/translation-map';
const oldFormatedDataForCountries = oldCountriesData;

const createCustomerValidationSchema = (
  countries: any,
  stateApiConfig: any,
  disableStateField: Function,
) => {
  return yup.object({
    customerName: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
      .max(200, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 200 })),
    streetAddress1: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
      .max(360, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 360 })),
    streetAddress2: yup
      .string()
      .max(360, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 360 })),
    streetAddress3: yup
      .string()
      .max(360, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 360 })),
    emailAddress: yup
      .string()
      .email(i18n.t(TEXT.COMMON.VALIDATIONS.VALID_EMAIL))
      .max(200, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 200 })),
    businessPhone: yup
      .string()
      .max(40, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 40 }))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES)),
    mobilePhone: yup
      .string()
      .max(40, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 40 }))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES)),
    city: yup
      .string()
      .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
      .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
      .matches(
        /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
        i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
      )
      .max(450, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 450 })),

    zipCode: yup.string().when(['country'], {
      is: (country: string) => country && !countriesWithNoZipCode[country],
      then: () =>
        yup
          .string()
          .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
          .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          )
          .max(135, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 135 })),
      otherwise: () =>
        yup
          .string()
          .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          ),
    }),

    country: yup.number().when(['countriesApiFlag'], {
      is: (countriesApiFlag: boolean) => countriesApiFlag,
      then: () =>
        yup
          .string()

          .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          )
          .test('is-country-valid', function (value, { createError, path }) {
            return value && !countriesData(countries)[value]
              ? createError({
                  path,
                  message: i18n.t(TEXT.COMMON.VALIDATIONS.COUNTRY_CODE, { countryCode: value }),
                })
              : true;
          }),
      otherwise: () =>
        yup
          .string()
          .required(i18n.t(TEXT.COMMON.VALIDATIONS.REQUIRED_FIELD))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          ),
    }),

    stateOrProvince: yup.string().when(['country'], {
      is: (country: string, countriesApiFlag: boolean) =>
        !countriesApiFlag && country && oldFormatedDataForCountries[country],
      then: () =>
        yup
          .string()
          .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          )
          .max(450, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 450 }))
          .test('is-state-valid', function (value, { createError, path }) {
            const res = oldFormatedDataForCountries[this.parent.country].states;

            if (res?.length && res?.length > 0 && !value) {
              disableStateField(false);
              return createError({
                path,
                message: i18n.t(TEXT.COMMON.VALIDATIONS.STATE_IS_REQUIRED),
              });
            } else if (
              res?.length &&
              res.length > 0 &&
              value &&
              !res?.find((state: { title: string; code: string }) => {
                disableStateField(false);
                return (
                  state.title === value.toLocaleUpperCase() ||
                  state.code === value.toLocaleUpperCase()
                );
              })
            ) {
              return createError({
                path,
                message: i18n.t(TEXT.COMMON.VALIDATIONS.STATE_CODE, { stateCode: value }),
              });
            } else if (!res?.length) {
              disableStateField(true);
              return true;
            } else {
              return true;
            }
          }),

      otherwise: () =>
        yup
          .string()
          .matches(/^(?!.* {3})/, i18n.t(TEXT.COMMON.VALIDATIONS.CONSECUTIVE_SPACES))
          .matches(
            /^(?!.* {3})[^!"$%*<=>?@[\]{}]+$/,
            i18n.t(TEXT.COMMON.VALIDATIONS.SPECIAL_CHARACTERS),
          )
          .max(450, i18n.t(TEXT.COMMON.VALIDATIONS.EXCEED_LENGTH, { numberOfCharacters: 450 }))
          .test('is-state-valid', function (value, { createError, path }) {
            return getSubdivisionByCountryCache(this.parent.country, stateApiConfig).then((res) => {
              if (res.length > 0 && !value) {
                disableStateField(false);
                return createError({
                  path,
                  message: i18n.t(TEXT.COMMON.VALIDATIONS.STATE_IS_REQUIRED),
                });
              } else if (
                res.length > 0 &&
                value &&
                !res?.find((state: { title: string; code: string }) => {
                  disableStateField(false);
                  return (
                    state.title === value.toLocaleUpperCase() ||
                    state.code === value.toLocaleUpperCase()
                  );
                })
              ) {
                return createError({
                  path,
                  message: i18n.t(TEXT.COMMON.VALIDATIONS.STATE_CODE, { stateCode: value }),
                });
              } else if (res.length === 0) {
                disableStateField(true);
                return true;
              } else {
                return true;
              }
            });
          }),
    }),
  });
};

const myCustomersAdvanceFiltersValidationSchema = yup.object({
  dealerCode: yup.lazy((value) =>
    Array.isArray(value)
      ? yup.array().of(yup.string())
      : yup
          .string()
          .min(4, 'minimum 4 characters required')
          .max(6, 'maximum 6 characters required'),
  ),
  country: yup.string(),
  stateOrProvince: yup.string(),
  startDate: yup.date(),
  endDate: yup.date(),
  hasAssociationToMyDealerCodes: yup.string(),
});

const myWorkQueueAdvanceFilterValidationSchema = yup.object({
  customerName: yup.array(),
  dealerCode: yup.lazy((value) =>
    Array.isArray(value)
      ? yup.array().of(yup.string())
      : yup
          .string()
          .min(4, 'minimum 4 characters required')
          .max(6, 'maximum 6 characters required'),
  ),
  workItemType: yup.string(),
  startDate: yup.date(),
  endDate: yup.date(),
});

const assetRecommendationAdvanceFilterValidationSchema = yup.object({
  dealerCode: yup.lazy((value) =>
    Array.isArray(value)
      ? yup.array().of(yup.string())
      : yup
          .string()
          .min(4, 'minimum 4 characters required')
          .max(6, 'maximum 6 characters required'),
  ),
});

const invitationsFiltersSchema = yup.object({
  selectedAccount: yup.string(),
  selectedCustomers: yup
    .array()
    .of(yup.object())
    .when('selectedAccount', {
      is: (account: string) => account === 'CAT',
      then: () =>
        yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              customerOrganizationDetails: yup
                .object()
                .shape({ customerOrganizationIdentifier: yup.string() }),
            }),
          ),
    }),
});
const accessRequestFiltersSchema = yup.object({
  selectedAccount: yup.string().required(),
  presetValue: yup.string().when('selectedAccount', {
    is: (account: string) => account === 'CAT',
    then: () => yup.string().min(1).required(),
  }),
  selectedCustomers: yup
    .array()
    .of(yup.object())
    .when(['presetValue', 'selectedAccount'], {
      is: (preset: string, account: string) =>
        preset === presetFilterValue.APPLICATION_ACCESS && account === PartyType.CAT,
      then: () =>
        yup
          .array()
          .min(1)
          .of(
            yup.object().shape({
              customerOrganizationDetails: yup
                .object()
                .shape({ customerOrganizationIdentifier: yup.string() }),
            }),
          ),
    }),
  application: yup.array().of(yup.string()),
  applicationRequestStatus: yup.array().of(yup.string()),
  startDate: yup.date(),
  endDate: yup.date(),
});

export {
  createCustomerValidationSchema,
  myCustomersAdvanceFiltersValidationSchema,
  myWorkQueueAdvanceFilterValidationSchema,
  assetRecommendationAdvanceFilterValidationSchema,
  invitationsFiltersSchema,
  accessRequestFiltersSchema,
};
