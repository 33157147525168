/**
 * Customer Asset Workflow
 * Customer Asset Workflow service allows to make changes to customer asset associations  through recommendations and ownership change requests. 
 *
 * OpenAPI spec version: 1.2.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The recommendation relationship type represents the various types of asset recommendation like \"OWNERSHIP_CHANGE\" . 
 */
export interface AssetRecommendationType { 
    /**
     * Asset recommendation type code. Represents the actual type of asset recommendation in default language - ‘English’.
     */
    code?: AssetRecommendationType.CodeEnum;
    /**
     * Asset recommendation type label in user's preferred language. 
     */
    label?: string;
}
export namespace AssetRecommendationType {
    export type CodeEnum = 'OWNERSHIP_CHANGE';
    export const CodeEnum = {
        OWNERSHIPCHANGE: 'OWNERSHIP_CHANGE' as CodeEnum
    };
}