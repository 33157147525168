import React, { FC, useEffect, useState, useRef, lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import SideNav from '../../components/side-nav';
import Header from '../../components/header';
import { loginActionNames } from '../../store/login/types';
import { ICustomRoles, navListBasedOnEntitlements, NavSection } from '../nav-list';
import errorMessages from '../error-message';
import { AppState, useDispatchTs } from '../../store';
import useMountEffect from '../hooks/use-mount-effect';
import InitialLoading from '../../components/initial-loading';
import { termsAndConditionsVersion } from '../../assets/text/terms-and-conditions';
import TermsAndConditions from '../../assets/text/terms-and-conditions';
import { UserBasedPermission } from '../../entities/entitlements-v1/userBasedPermission';
import env from '../../environment.json';
import { printFeatureFlagConsole, showNotification, timeGate } from '../../utils/util';
import { acceptEULA, getCustomPreferences } from '../../store/login/actions';
import fetchCountries from '../../store/countries-states/actions';
import UserManagement from '../../services/user-management';
import AddUserEmails from '../../pages/invitations/pages/add-user-emails';
import InviteUsersReview from '../../pages/invitations/pages/review/index';
import ReviewDisassociateDealerCustomerFlow from '../../pages/disassociate-dealer-customer/pages/review';
import dayjs from 'dayjs';
import ReviewAssetResults from '../../pages/asset-management/pages/review';
import { useTranslation } from 'react-i18next';
import { fetchUserGlobalPreferences } from '../../store/global-preferences/actions';
import TEXT from '../translation-map';
import { getManufacturers } from '../../store/metadata/actions';
import { getCustomRoles } from '../../utils/parties.util';

const SelectApplicationAccess = lazy(
  () => import('../../pages/invitations/pages/select-application-access'),
);

const MergeOrganization = lazy(() => import('../../pages/merge-organization'));
const Warning = lazy(() => import('../../components/warning'));
const AssociateDcn = lazy(() => import('../../pages/associate-dealer-customer/pages/manual'));
const DisAssociateDcn = lazy(() => import('../../pages/disassociate-dealer-customer/pages/manual'));
const AssetManagement = lazy(() => import('../../pages/asset-management'));
const AssetManagementDcDcn = lazy(() => import('../../pages/asset-management-dcdcn'));
const ManageAssetsPage = lazy(() => import('../../pages/asset-management-dcdcn/manage-assets'));

const Review = lazy(() => import('../../pages/review-add-customer'));
const ErrorIcon = lazy(() => import('../../assets/icons/error-icon'));
const AssociateDcnRecommendations = lazy(
  () => import('../../pages/associate-dealer-customer/pages/recommendations'),
);
const ReviewAssociateDealerCustomerFlow = lazy(
  () => import('../../pages/associate-dealer-customer/pages/review'),
);
const AssociateCustomerRecommendations = lazy(
  () => import('../../pages/associate-customer/pages/recommendations'),
);
const ReviewAssociateCustomerFlow = lazy(
  () => import('../../pages/associate-customer/pages/review'),
);
const AssociateCustomer = lazy(() => import('../../pages/associate-customer/pages/manual'));
const AssociateUser = lazy(() => import('../../pages/associate-user'));
const DuplicateEntries = lazy(() => import('../../pages/duplicate-entries'));
const EditCustomer = lazy(() => import('../../pages/edit-customer'));
const EnterCustomerInfo = lazy(() => import('../../pages/enter-customer-info'));
const ErrorPage = lazy(() => import('../../pages/error'));
const AssociateDcnToNewCustomer = lazy(
  () =>
    import('../../pages/items-feedback/pages/associate-dealer-customer-numbers-to-new-customer'),
);
const AssociateDcnToNewCustomerReview = lazy(
  () =>
    import(
      '../../pages/items-feedback/pages/associate-dealer-customer-numbers-to-new-customer-review'
    ),
);

const AddAsset = lazy(() => import('../../pages/asset-management/pages/add-asset'));
const AssignOwner = lazy(() => import('../../pages/asset-management/pages/assign-owner'));

const UpdateOwnership = lazy(() => import('../../pages/asset-management/pages/update-ownership'));

const DcnToSingleExistingOrg = lazy(
  () => import('../../pages/items-feedback/pages/dcn-to-single-existing-org'),
);
const MultipleDcnToExistingOrg = lazy(
  () => import('../../pages/items-feedback/pages/multiple-dcn-to-existing-org'),
);
const MultipleOrgToPrimary = lazy(
  () => import('../../pages/items-feedback/pages/multiple-org-to-primary'),
);
const ReviewMultipleDcnToExistingOrgFlow = lazy(
  () => import('../../pages/items-feedback/pages/review-multiple-dcn-to-existing-org'),
);
const MySettings = lazy(() => import('../../pages/my-settings'));
const InviteUsers = lazy(() => import('../../pages/invitations/pages/invite'));
const AccessManagement = lazy(() => import('../../pages/access-management'));
const AssetRecommendations = lazy(() => import('../../pages/asset-recommendations'));
const OwnershipRequests = lazy(() => import('../../pages/ownership-requests'));
const OwnershipRequestDetails = lazy(
  () => import('../../pages/ownership-requests/pages/ownership-request-details'),
);
const AssetOwnershipChange = lazy(
  () => import('../../pages/asset-recommendations/pages/asset-ownership-change'),
);

const appDynamicsInitializerComponent = (appDynamicsKey: string) => {
  (window as any)['appDynamicsKey'] = appDynamicsKey;
  const adrumConfigScript = document.createElement('script');
  adrumConfigScript.src = '/adrum-config.js';
  document.head.append(adrumConfigScript);
  // eslint-disable-next-line unicorn/prefer-add-event-listener
  adrumConfigScript.onload = () => {
    const adrumScript = document.createElement('script');
    adrumScript.src = '//cdn.appdynamics.com/adrum/adrum-23.10.1.4359.js';
    document.head.append(adrumScript);
  };
};

const RoutesComponent: FC = () => {
  const dispatch = useDispatchTs();
  const locationPath = useLocation();
  const { t } = useTranslation();
  const steps = [
    t(TEXT.COMMON.TITLE.ENTER_CUSTOMER_INFO),
    t(TEXT.COMMON.TITLE.CHECK_FOR_DUPLICATES),
    t(TEXT.COMMON.TITLE.ASSOCIATE_RECOMMENDATIONS),
    t(TEXT.COMMON.TITLE.ASSOCIATE_DEALER_CUSTOMERS),
    t(TEXT.COMMON.TITLE.ASSOCIATE_USERS),
    t(TEXT.COMMON.TITLE.REVIEW_RESULTS),
  ];
  const stepsForAssociateDealerCustomers = [
    t(TEXT.COMMON.TITLE.ASSOCIATE_RECOMMENDATIONS),
    t(TEXT.COMMON.TITLE.ASSOCIATE_DEALER_CUSTOMERS),
    t(TEXT.COMMON.TITLE.REVIEW_RESULTS),
  ];
  const stepsForAssociateCustomers = [
    t(TEXT.COMMON.TITLE.ASSOCIATE_RECOMMENDATIONS),
    t(TEXT.COMMON.TITLE.ASSOCIATE_CUSTOMERS),
    t(TEXT.COMMON.TITLE.REVIEW_RESULTS),
  ];
  const stepsForInviteUsers = [
    t(TEXT.INVITATIONS.STEPS.SELECT_A_CUSTOMER),
    t(TEXT.INVITATIONS.STEPS.ADD_USER_EMAILS),
    t(TEXT.INVITATIONS.STEPS.SELECT_APPLICATION_ACCESS),
    t(TEXT.COMMON.TITLE.REVIEW_RESULTS),
  ];

  const [permissions, setPermissions] = useState(false);
  const [open, setOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);

  const [clickedUri, setClickedUri] = useState<string>('/my-work-queue');
  const [isDealerSupportAdmin, setDealerSupportAdminFlag] = useState<boolean>(false);
  const errorMessagesi18n = errorMessages(t);

  useEffect(() => {
    if (locationPath.pathname !== '/redirect') {
      if ((window as any).reloadOTBanner) {
        (window as any).reloadOTBanner();
      } else {
        setTimeout(() => {
          if ((window as any).reloadOTBanner) {
            (window as any).reloadOTBanner();
          }
        }, 3000);
      }
    }
  }, [locationPath]);

  const [preferencesRetrievalDisplayed, setPreferencesRetrievalDisplayed] =
    useState<boolean>(false);

  const { location } = window;
  const savedPath =
    window.sessionStorage.getItem('is-refreshed') === 'true' &&
    window.localStorage.getItem('saved-path');

  const globalRoutesReference: any = useRef(null);
  const executeScroll = () => globalRoutesReference.current?.scrollIntoView();

  const {
    authInfo,
    isPermissionFetching,
    environmentVariables,
    permissionsResponse,
    partiesResponse,
    userInfoResponse,
    loginError,
    legalAgreementTimestamp,
    legalAgreementVersion,
    customSettings,
  } = useSelector((state: AppState) => state.login);
  const { countries } = useSelector((state: AppState) => state.shared.countriesAndStates);
  const { userPreferences } = useSelector((state: AppState) => state.globalPreferences);

  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  const lastYearinMilis = date.getTime();
  const localLegalAgreement = window.localStorage.getItem('legal-agreement-status');

  const displayTerms = () => {
    if (legalAgreementTimestamp && legalAgreementVersion?.[termsAndConditionsVersion]) {
      return legalAgreementTimestamp < lastYearinMilis ? true : false;
    }

    if (localLegalAgreement !== 'true') return true;
    return false;
  };

  useEffect(() => {
    if (displayTerms()) {
      setWarningOpen(true);
    } else setWarningOpen(false);
  }, [legalAgreementTimestamp]);

  useEffect(() => {
    if (permissionsResponse?.dataPermissions?.manufacturerMetadata) {
      dispatch(getManufacturers());
    }
  }, [permissionsResponse]);

  useEffect(() => {
    if (
      !countries.length &&
      authInfo &&
      environmentVariables.countriesV1 !== '' &&
      environmentVariables.countriesApiFlag
    ) {
      dispatch(fetchCountries());
    }
  }, [authInfo, environmentVariables.countriesV1]);

  useEffect(() => {
    executeScroll();
  }, [locationPath]);

  useEffect(() => {
    if (partiesResponse) {
      if (partiesResponse.parties && partiesResponse.parties?.length > 0) {
        setPermissions(true);
      }
      // This is temporary, we would be changing this once Phani confirm's the approach
      // start for temp solution
      setDealerSupportAdminFlag(
        (partiesResponse.parties || []).length === 1 &&
          partiesResponse.parties?.findIndex((item) => {
            return item.roles[0].roleName === 'Dealer Support Admin';
          }) !== -1,
      );
      if (navList?.admin?.subNavItems && navList?.admin?.subNavItems.length === 0)
        setClickedUri('/home-page');
      else setClickedUri('/admin/manage-access');
    }
  }, [dispatch, partiesResponse]);

  const getPreferences = async () => {
    dispatch(fetchUserGlobalPreferences());
    dispatch(getCustomPreferences(preferencesRetrievalDisplayed));
    setPreferencesRetrievalDisplayed(true);
  };
  useMountEffect(() => {
    const pathName = location.pathname;
    if (
      (pathName !== '/redirect' && pathName !== '/' && pathName === '/home-page') ||
      pathName === '/my-customers' ||
      pathName === '/my-work-queue' ||
      pathName === '/invitations' ||
      pathName === '/assets' ||
      pathName === '/asset-recommendations' ||
      pathName === '/ownership-requests' ||
      pathName === '/my-settings' ||
      pathName === '/admin/manage-access' ||
      pathName === '/access-management' ||
      pathName === '/my-work-queue/access-request' ||
      pathName === '/admins'
    ) {
      localStorage.setItem('saved-path', pathName);
    }
  });
  const handleDrawerToggle = () => {
    if (!open) {
      (window as any)?.reloadOTBanner();
    }

    setOpen(!open);
  };

  const acceptLegalAgreement = async () => {
    dispatch(acceptEULA());
    setWarningOpen(false);
  };
  const declineLegalAgreement = () => {
    setAccessDenied(true);
    setWarningOpen(false);
  };

  // TODO: check if the error can be removed with localhost run ResizeObserver loop limit exceeded AB#600641
  useEffect(() => {
    window.addEventListener('error', (e) => {
      if (
        e.message === 'ResizeObserver loop limit exceeded' ||
        e.message === 'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.querySelector(
          '#webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.querySelector('#webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  const handleEnvVariables = (envHandle: any) => {
    printFeatureFlagConsole(
      {
        assetManagementFlag: envHandle.assetManagementFlag,
        dcnDisassociationFlag: envHandle.dcnDisassociationFlag,
        multipleDCNRecommendation: envHandle.multipleDCNRecommendation,
        testAdminUserOnboardingApps: envHandle.testAdminUserOnboardingApps,
        applicationUserManagementFM3Apps: envHandle.applicationUserManagementFM3Apps,
        userOnboardingApps: envHandle.userOnboardingApps,
        ownershipRequestsFlag: envHandle.ownershipRequestsFlag,
        assetRecommendationsFlag: envHandle.assetRecommendationsFlag,
        testAdminAccessRequestAndManageusersFlag:
          envHandle.testAdminAccessRequestAndManageusersFlag,
        standardTest: envHandle.standardTest,
        applicationUserManagementFM3Flag: envHandle.applicationUserManagementFM3Flag,
        cuobTestFlag: envHandle.cuobTestFlag,
        adminsFlag: envHandle.adminsFlag,
        serialNumberValidationFlag: envHandle.serialNumberValidationFlag,
        accessRequestsDateFilterFlag: envHandle.accessRequestsDateFilterFlag,
        resentInvitationDetailsFlag: envHandle.resentInvitationDetailsFlag,
        notificationsFlag: envHandle.notificationsFlag,
        autoApproveFlag: envHandle.autoApproveFlag,
        assetManagementFilters: envHandle.assetManagementFilters,
        restrictedAccountsFlag: envHandle.restrictedAccountsFlag,
        assetsDCDCNFlag: envHandle.assetsDCDCNFlag,
        customersConsolidateFlag: envHandle.customersConsolidateFlag,
        AMTChangesOwnershipFlag: envHandle.AMTChangesOwnershipFlag,
      },
      envHandle.currentEnvironment,
    );
    dispatch({ type: loginActionNames.SET_ENV_VARIABLES, payload: envHandle });
    if (process.env.REACT_APP_ENV !== 'local') {
      appDynamicsInitializerComponent(env.appDynamicsKey);
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'local') {
      handleEnvVariables(env);
    } else {
      fetch('/environment.json')
        .then((response) => {
          response.json().then((envResponse) => {
            handleEnvVariables(envResponse);
          });
        })
        .catch((error) => console.log(error));
    }
  }, [dispatch]);

  const navList: NavSection | null = permissionsResponse
    ? navListBasedOnEntitlements(
        {
          ...(permissionsResponse as UserBasedPermission).applicationPermissions,
          admins: { '*': ['*'] },
        },
        t,
        environmentVariables,
        partiesResponse,
        getCustomRoles(partiesResponse?.parties) as ICustomRoles,
        customSettings,
      )
    : null;

  if (navList?.workQueue?.subNavItems) {
    navList.workQueue.subNavItems = navList?.workQueue?.subNavItems.sort((a, b) =>
      a.title > b.title ? 1 : -1,
    );
  }
  useEffect(() => {
    const fetchUserNamanaement = async () => {
      try {
        const userManagement = new UserManagement(authInfo, environmentVariables.userManagementV1);
        const userManagementResponse = await userManagement.searchUsers({
          body: {
            logicalExpression: '$0',
            filters: [
              {
                propertyName: 'username',
                type: 'stringEquals',
                values: [authInfo?.loginId || ''],
              },
            ],
          },
        });
        if (userManagementResponse?.data) {
          dispatch({
            type: loginActionNames.SET_USER_INFO_RESPONSE,
            payload: userManagementResponse.data,
          });
        } else {
          showNotification(dispatch, 'error', t(TEXT.USER_MANAGEMENT.ERRORS.SEARCH_USERS), {
            autoHideDuration: 8000,
          });
        }
      } catch {
        showNotification(dispatch, 'error', t(TEXT.USER_MANAGEMENT.ERRORS.SEARCH_USERS), {
          autoHideDuration: 8000,
        });
      }
    };

    if (authInfo) {
      fetchUserNamanaement();
      getPreferences();
    }
  }, [authInfo]);
  useEffect(() => {
    if (
      environmentVariables?.announcementStartDate &&
      environmentVariables?.announcementEndDate &&
      environmentVariables?.announcementContent &&
      timeGate(
        dayjs(environmentVariables?.announcementStartDate).valueOf(),
        dayjs(environmentVariables?.announcementEndDate).valueOf(),
      )
    ) {
      showNotification(dispatch, 'warning', environmentVariables.announcementContent, {
        persist: true,
        style: {
          height: `${environmentVariables.announcementContent.length / 5}px`,
          flexWrap: 'nowrap',
        },
      });
    }

    if (
      environmentVariables?.deploymentDateTime &&
      environmentVariables?.hoursBeforeMaintanenceBannerDisplay &&
      environmentVariables?.deploymentDuration
    ) {
      const deploymentStartTime = dayjs(environmentVariables.deploymentDateTime);
      const showBannerTime = dayjs(environmentVariables.deploymentDateTime).subtract(
        environmentVariables.hoursBeforeMaintanenceBannerDisplay,
        'hour',
      );
      const deploymentEndTime = dayjs(environmentVariables.deploymentDateTime).add(
        environmentVariables.deploymentDuration,
        'hour',
      );
      if (timeGate(showBannerTime.valueOf(), deploymentEndTime.valueOf())) {
        showNotification(
          dispatch,
          'warning',
          t(TEXT.COMMON.MAINTENANCE_BANNER, {
            startTime: deploymentStartTime.format('h:mm A'),
            startDate: deploymentStartTime.format('MMM, DD, YYYY'),
            duration: environmentVariables.deploymentDuration,
          }),
          {
            persist: true,
            style: {
              height: `${environmentVariables.announcementContent.length / 5}px`,
              flexWrap: 'nowrap',
            },
          },
        );
      }
    }
  }, [environmentVariables]);

  if (accessDenied)
    return (
      <ErrorPage
        heading="Access Denied"
        description="Terms and Conditions were not accepted"
      ></ErrorPage>
    );

  const getAssetsPaths = () => {
    return (
      <>
        <Route path={'add-asset'}>
          <Route path={'enter-asset-information'} element={<AddAsset />}></Route>
          <Route path={'assign-owner'} element={<AssignOwner />}></Route>
          <Route path={'review'} element={<ReviewAssetResults />}></Route>
        </Route>
        <Route path={'edit-asset'} element={<AddAsset editPage={true} />}></Route>
        <Route path={'update-ownership'}>
          <Route path={'update-ownership-information'} element={<UpdateOwnership />}></Route>
        </Route>
        <Route path={'manage-assets'} element={<ManageAssetsPage />}></Route>
      </>
    );
  };
  const landingPage = () => {
    return isDealerSupportAdmin
      ? '/admin/manage-access'
      : // handle invalid state where a user may have /home-page or /my-customers for savedPath and doesn't have permission
        (navList &&
            Object.keys(navList).some(
              (navKey: string) => (navList as NavSection)[navKey].uri === savedPath,
            )) ||
          savedPath === '/my-settings' ||
          savedPath === '/asset-recommendations' ||
          savedPath === '/ownership-requests' ||
          savedPath === '/access-management' ||
          savedPath === '/my-work-queue/access-request'
        ? savedPath
        : navList?.home
          ? '/home-page'
          : navList?.customers
            ? '/my-customers'
            : '/invitations';
  };
  return (
    <Routes>
      {!isPermissionFetching && authInfo && userPreferences?.language ? (
        permissions && !loginError ? (
          displayTerms() ? (
            <Route
              path="*"
              element={
                <Warning
                  messageText={<TermsAndConditions />}
                  primaryText="Accept"
                  titleText="Terms & Conditions"
                  primaryCallback={acceptLegalAgreement}
                  secondaryText="Decline"
                  secondaryCallback={declineLegalAgreement}
                  open={warningOpen}
                  setOpen={setWarningOpen}
                />
              }
            ></Route>
          ) : (
            <Route
              path="*"
              element={
                <div ref={globalRoutesReference}>
                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Header
                      open={open}
                      headerComponents={[]}
                      handleDrawerClick={handleDrawerToggle}
                      userInfo={userInfoResponse}
                    />

                    {navList && (
                      <SideNav
                        open={open}
                        navList={navList}
                        clickedUri={clickedUri}
                        setOpen={setOpen}
                        setClickedUri={setClickedUri}
                      >
                        <Routes>
                          <Route
                            path="redirect"
                            element={<Navigate to={landingPage() as string} />}
                          />
                          <Route path={'add-customer'}>
                            <Route
                              path={`enter-customer-info/:importPage`}
                              element={<EnterCustomerInfo steps={steps} />}
                            />
                            <Route
                              path={`enter-customer-info`}
                              element={<EnterCustomerInfo steps={steps} />}
                            />
                            <Route
                              path={`duplicate-entries`}
                              element={<DuplicateEntries steps={steps} />}
                            />
                            <Route
                              path={`duplicate-entries/:importPage`}
                              element={<DuplicateEntries steps={steps} />}
                            />
                            <Route path={`review`} element={<Review steps={steps} />} />
                            <Route
                              path={`associate-user`}
                              element={<AssociateUser steps={steps} />}
                            />
                            <Route path={`associate-dealer-customer`}>
                              <Route
                                path={`recommendations`}
                                element={<AssociateDcnRecommendations steps={steps} />}
                              />
                              <Route path={`manual`} element={<AssociateDcn steps={steps} />} />
                              <Route path={`review`} element={<Review steps={steps} />} />
                            </Route>
                          </Route>
                          <Route
                            path={`associate-user`}
                            element={<AssociateUser steps={steps} />}
                          />
                          <Route path="/my-settings" element={<MySettings></MySettings>} />
                          <Route
                            path="/invitations/select-company"
                            element={<InviteUsers></InviteUsers>}
                          />
                          <Route path="/edit-customer" element={<EditCustomer></EditCustomer>} />
                          <Route
                            path="/merge-organization"
                            element={<MergeOrganization></MergeOrganization>}
                          />
                          <Route
                            path="/invitations/add-emails"
                            element={<AddUserEmails steps={stepsForInviteUsers} />}
                          />
                          <Route
                            path="/invitations/select-application-access"
                            element={<SelectApplicationAccess steps={stepsForInviteUsers} />}
                          />
                          <Route
                            path="/invitations/review"
                            element={<InviteUsersReview steps={stepsForInviteUsers} />}
                          />
                          <Route
                            path="/invite-users-review-results"
                            element={<InviteUsersReview steps={stepsForInviteUsers} />}
                          />
                          <Route path="/access-management" element={<AccessManagement />} />
                          <Route path="associate-dealer-customer">
                            <>
                              <Route
                                path={`recommendations`}
                                element={
                                  <AssociateDcnRecommendations
                                    steps={stepsForAssociateDealerCustomers}
                                  />
                                }
                              />
                              <Route
                                path={`manual`}
                                element={<AssociateDcn steps={stepsForAssociateDealerCustomers} />}
                              />
                              <Route
                                path={`review`}
                                element={
                                  <ReviewAssociateDealerCustomerFlow
                                    steps={stepsForAssociateDealerCustomers}
                                  />
                                }
                              />
                            </>
                          </Route>
                          <Route path="/disassociate-dealer-customer">
                            <>
                              <Route path={`manual`} element={<DisAssociateDcn />} />

                              <Route
                                path={`review`}
                                element={<ReviewDisassociateDealerCustomerFlow />}
                              />
                            </>
                          </Route>
                          <Route path="associate-customers">
                            <>
                              <Route
                                path={`recommendations`}
                                element={
                                  <AssociateCustomerRecommendations
                                    steps={stepsForAssociateCustomers}
                                  />
                                }
                              />
                              <Route
                                path={`manual`}
                                element={<AssociateCustomer steps={stepsForAssociateCustomers} />}
                              />
                              <Route
                                path={`review`}
                                element={
                                  <ReviewAssociateCustomerFlow steps={stepsForAssociateCustomers} />
                                }
                              />
                            </>
                          </Route>
                          <Route path="items-feedback/:recommendationGroupId">
                            <Route
                              path={`dcn-to-single-existing-org`}
                              element={<DcnToSingleExistingOrg></DcnToSingleExistingOrg>}
                            />
                            <Route
                              path={`multiple-org-to-primary`}
                              element={<MultipleOrgToPrimary></MultipleOrgToPrimary>}
                            />
                            <Route
                              path={`multiple-dcn-to-existing-org`}
                              element={<MultipleDcnToExistingOrg></MultipleDcnToExistingOrg>}
                            />
                            <Route
                              path={`review-multiple-dcn-to-existing-org`}
                              element={
                                <ReviewMultipleDcnToExistingOrgFlow></ReviewMultipleDcnToExistingOrgFlow>
                              }
                            />
                            <Route
                              path={`associate-dealer-customer-numbers-to-new-customer`}
                              element={<AssociateDcnToNewCustomer></AssociateDcnToNewCustomer>}
                            ></Route>
                          </Route>
                          <Route
                            path={`/associate-dealer-customer-numbers-to-new-customer-review`}
                            element={
                              <AssociateDcnToNewCustomerReview></AssociateDcnToNewCustomerReview>
                            }
                          ></Route>
                          <Route path={'customers'}>
                            <Route
                              path={`assets`}
                              element={
                                environmentVariables.assetsDCDCNFlag ? (
                                  <AssetManagementDcDcn />
                                ) : (
                                  <AssetManagement />
                                )
                              }
                            />
                            {getAssetsPaths()}
                          </Route>
                          <Route
                            path="ownership-requests"
                            element={<OwnershipRequests></OwnershipRequests>}
                          ></Route>
                          <Route
                            path="ownership-details"
                            element={<OwnershipRequestDetails></OwnershipRequestDetails>}
                          ></Route>
                          <Route
                            path="asset-recommendations"
                            element={<AssetRecommendations></AssetRecommendations>}
                          ></Route>
                          <Route
                            path="ownership-change"
                            element={<AssetOwnershipChange></AssetOwnershipChange>}
                          ></Route>
                          {getAssetsPaths()}
                          {Object.entries(navList).map(([key, navItem]) => {
                            return (
                              <React.Fragment key={`${navItem}-${key}`}>
                                <Route path={navItem.uri} element={<navItem.component />}></Route>
                                {navItem.subNavItems?.map((subNavItem, subitemkey) => (
                                  <Route
                                    key={`${subNavItem}-${subitemkey}`}
                                    path={`${subNavItem.uri}`}
                                    element={<subNavItem.component />}
                                  />
                                ))}
                              </React.Fragment>
                            );
                          })}
                        </Routes>
                      </SideNav>
                    )}
                  </Box>
                </div>
              }
            />
          )
        ) : (
          <Route
            path="*"
            element={
              <ErrorPage
                heading={
                  partiesResponse?.parties?.length === 0
                    ? errorMessagesi18n.entitlements.heading
                    : errorMessagesi18n.userManagement.heading
                }
                description={
                  partiesResponse?.parties?.length === 0
                    ? errorMessagesi18n.entitlements.description
                    : errorMessagesi18n.userManagement.description
                }
                icon={partiesResponse?.parties?.length !== 0 ? <ErrorIcon /> : undefined}
                advice={
                  partiesResponse?.parties?.length === 0
                    ? errorMessagesi18n.entitlements.advice
                    : errorMessagesi18n.userManagement.advice
                }
              />
            }
          ></Route>
        )
      ) : (
        <Route
          path="*"
          element={
            <InitialLoading secondaryMessage="fetching user information and permissions..." />
          }
        ></Route>
      )}
    </Routes>
  );
};

export default RoutesComponent;
