/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Status of the invite.
 */
export type Status = 'Pending' | 'Accepted' | 'Revoked';

export const Status = {
    Pending: 'Pending' as Status,
    Accepted: 'Accepted' as Status,
    Revoked: 'Revoked' as Status
};