import {
  OwnershipRequestsState,
  RequestTypes,
  ownershipRequestsActionNames,
  ownershipRequestsActionTypes,
} from './types';

const initialState: OwnershipRequestsState = {
  cursors: [],
  ownershipRequests: undefined,
  isLoadingOwnershipRequests: false,
  isLoadingGetOwnershipRequest: false,
  isLoadingDeleteOwnershipRequest: false,
  requestType: RequestTypes.RECEIVED,
  count: 0,
  selectedOwnershipRequest: undefined,
  isLoadingUpdateOwnershipRequest: false,
  received: {
    filters: {
      status: [],
    },
    searchValue: '',
    filterTags: [],
    sortBy: [],
    orderBy: [],
  },
  sent: {
    filters: {
      status: [],
    },
    searchValue: '',
    filterTags: [],
    sortBy: [],
    orderBy: [],
  },
};

const ownershipRequestsReducer = (
  state: OwnershipRequestsState = initialState,
  action: ownershipRequestsActionTypes,
): OwnershipRequestsState => {
  switch (action.type) {
    case ownershipRequestsActionNames.SET_IS_LOADING_OWNERSHIP_REQUESTS: {
      return {
        ...state,
        isLoadingOwnershipRequests: action.payload,
      };
    }
    case ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS: {
      return {
        ...state,
        ownershipRequests: action.payload,
      };
    }
    case ownershipRequestsActionNames.SET_CURSORS_FOR_OWNERSHIP_REQUEST: {
      return {
        ...state,
        cursors: action.payload,
      };
    }
    case ownershipRequestsActionNames.RESET_CURSORS_FOR_OWNERSHIP_REQUESTS: {
      return {
        ...state,
        cursors: initialState.cursors,
      };
    }
    case ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_COUNT: {
      return {
        ...state,
        count: action.payload,
      };
    }
    case ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_TYPE: {
      return {
        ...state,
        requestType: action.payload,
      };
    }
    case ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_SORT_BY_VALUE: {
      return {
        ...state,
        [action.payload.requestType]: {
          ...state[action.payload.requestType],
          sortBy: action.payload.sortBy,
        },
      };
    }
    case ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_ORDER_BY_VALUE: {
      return {
        ...state,
        [action.payload.requestType]: {
          ...state[action.payload.requestType],
          orderBy: action.payload.orderBy,
        },
      };
    }
    case ownershipRequestsActionNames.SET_INITIAL_STATE: {
      return initialState;
    }
    case ownershipRequestsActionNames.SET_IS_LOADING_GET_OWNERSHIP_REQUEST: {
      return {
        ...state,
        isLoadingGetOwnershipRequest: action.payload,
      };
    }
    case ownershipRequestsActionNames.SET_IS_LOADING_DELETE_OWNERSHIP_REQUEST: {
      return {
        ...state,
        isLoadingDeleteOwnershipRequest: action.payload,
      };
    }
    case ownershipRequestsActionNames.SET_SELECTED_OWNERSHIP_REQUEST: {
      return {
        ...state,
        selectedOwnershipRequest: action.payload,
      };
    }
    case ownershipRequestsActionNames.SET_IS_LOADING_UPDATE_OWNERSHIP_REQUEST: {
      return {
        ...state,
        isLoadingUpdateOwnershipRequest: action.payload,
      };
    }
    case ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTERS: {
      return {
        ...state,
        [action.payload.requestType]: {
          ...state[action.payload.requestType],
          filters: action.payload.filters,
        },
      };
    }
    case ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_FILTER_TAGS: {
      return {
        ...state,
        [action.payload.requestType]: {
          ...state[action.payload.requestType],
          filterTags: action.payload.filterTags,
        },
      };
    }
    case ownershipRequestsActionNames.SET_OWNERSHIP_REQUESTS_SEARCH_VALUE: {
      return {
        ...state,
        [action.payload.requestType]: {
          ...state[action.payload.requestType],
          searchValue: action.payload.value,
        },
      };
    }
    default:
      return state;
  }
};

export default ownershipRequestsReducer;
