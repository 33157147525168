/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TimeRange } from './timeRange';

/**
 * Allows to filter date values within a certain range. See range models for more info about boundary inclusion rules. 
 */
export interface UsersPatchEventsHistoryTimeRangeFilter { 
    /**
     * Enum that defines all possible time range filter types.
     */
    type: UsersPatchEventsHistoryTimeRangeFilter.TypeEnum;
    /**
     * Users Patch properties that can be used for time range filtering. 
     */
    propertyName: UsersPatchEventsHistoryTimeRangeFilter.PropertyNameEnum;
    range: TimeRange;
}
export namespace UsersPatchEventsHistoryTimeRangeFilter {
    export type TypeEnum = 'timeRange';
    export const TypeEnum = {
        TimeRange: 'timeRange' as TypeEnum
    };
    export type PropertyNameEnum = 'createdTime';
    export const PropertyNameEnum = {
        CreatedTime: 'createdTime' as PropertyNameEnum
    };
}