/**
 * Assets V2
 * The Assets API allows you to access asset details and the latest asset telemetry information, such as fuel, location, and service meter readings. You can:  * Export assets to a data file * Explore data facets as summaries of distinct asset property values with assets counts for each value * Import manual assets and custom asset state, get import status, as well as update imported manual assets or create new manual assets after initial import of manual assets. * Add or remove assets to/from your visible population by creating, activating, or deactivating them * Modify editable fields on assets
 *
 * OpenAPI spec version: 2.10.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Indicates current relation status of the customer/dealer with an asset.
 */
export type DcnRelationStatusCode =
  | 'ACTIVE'
  | 'AGED'
  | 'OBSOLETE'
  | 'PENDING'
  | 'EXPIRED'
  | 'EXPIRE-PENDING';

export const DcnRelationStatusCode = {
  ACTIVE: 'ACTIVE' as DcnRelationStatusCode,
  AGED: 'AGED' as DcnRelationStatusCode,
  OBSOLETE: 'OBSOLETE' as DcnRelationStatusCode,
  PENDING: 'PENDING' as DcnRelationStatusCode,
  EXPIRED: 'EXPIRED' as DcnRelationStatusCode,
  EXPIREPENDING: 'EXPIRE-PENDING' as DcnRelationStatusCode,
};
