/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Represents a single filtering criteria, i.e., the name of the object field to filter by and list of possible values. This filter will only select a row in case of exact match with one of the values in the provided list. 
 */
export interface UsersPatchEventsHistoryStringEqualsFilter { 
    /**
     * Enum that defines all possible string filter types.
     */
    type: UsersPatchEventsHistoryStringEqualsFilter.TypeEnum;
    /**
     * User properties that can be used for string filtering. 
     */
    propertyName: UsersPatchEventsHistoryStringEqualsFilter.PropertyNameEnum;
    /**
     * Array of possible filter values.
     */
    values: Array<string>;
}
export namespace UsersPatchEventsHistoryStringEqualsFilter {
    export type TypeEnum = 'stringEquals';
    export const TypeEnum = {
        StringEquals: 'stringEquals' as TypeEnum
    };
    export type PropertyNameEnum = 'catrecid' | 'partyNumber' | 'action';
    export const PropertyNameEnum = {
        Catrecid: 'catrecid' as PropertyNameEnum,
        PartyNumber: 'partyNumber' as PropertyNameEnum,
        Action: 'action' as PropertyNameEnum
    };
}