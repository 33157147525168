/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerOrganizationIdentifier } from './customerOrganizationIdentifier';

/**
 * Object for association between customer organizations.
 */
export interface AuditLogCustomerOrganizationAssociationItem { 
    customerOrganizationIdentifier?: CustomerOrganizationIdentifier;
    /**
     * Whether this is relationship is a parent or child.
     */
    associationType?: AuditLogCustomerOrganizationAssociationItem.AssociationTypeEnum;
}
export namespace AuditLogCustomerOrganizationAssociationItem {
    export type AssociationTypeEnum = 'parent' | 'child';
    export const AssociationTypeEnum = {
        Parent: 'parent' as AssociationTypeEnum,
        Child: 'child' as AssociationTypeEnum
    };
}