import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import useStyles from './styles';
import FilterIcon from '../../../../assets/icons/filter-icon';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import StepperButton from '../../../../components/stepper-button';
import DropdownMultiSelect from '../../../../components/dropdown-multi-select';
import {
  ReceivedSentType,
  RequestTypes,
  StatusTypes,
} from '../../../../store/ownership-requests/types';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import globalStyles from '../../../../globals/styles';

type FiltersProperties = {
  disabled?: boolean;
  onClickOfApply: Function;
  selectedFilters?: boolean;
  clearAllFilters: Function;
  setOpenFilterModal: Function;
  openFilterModal: boolean;
};

const OwnershipRequestsFilters: FC<FiltersProperties> = ({
  onClickOfApply,
  selectedFilters,
  clearAllFilters,
  setOpenFilterModal,
  openFilterModal,
  disabled,
}) => {
  const { classes } = useStyles();
  const { classes: globalClasses } = globalStyles();

  const { requestType, received, sent } = useSelector((state: AppState) => state.ownershipRequests);
  const { t } = useTranslation();

  const [receivedSentFromStore, setReceivedSentFromStore] = useState<ReceivedSentType>({
    filters: { status: [] },
    filterTags: [],
    sortBy: [],
    orderBy: [],
    searchValue: '',
  });

  useEffect(() => {
    const newReceivedSentFromStore =
      requestType.toLocaleUpperCase() === RequestTypes.RECEIVED
        ? structuredClone(received)
        : structuredClone(sent);
    setReceivedSentFromStore(newReceivedSentFromStore);
    if (newReceivedSentFromStore.filters.status) {
      formik.setFieldValue('status', newReceivedSentFromStore.filters.status);
    }
  }, [requestType, received, sent]);

  const formik = useFormik({
    initialValues: {
      status: receivedSentFromStore.filters.status,
    },
    onSubmit: () => {},
    enableReinitialize: true,
  });

  const handleFiltersDialogClose = () => {
    setOpenFilterModal(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box data-testid="ownership-requests-filters-container">
        <Box width="100%" display="flex" justifyContent="flex-start" alignItems="center">
          <Box display="flex" alignItems="center">
            <Button
              disabled={disabled}
              data-testid="ownership-requests-filters-button"
              className={classes.filterButton}
              variant="contained"
              color="secondary"
              startIcon={
                <Badge
                  color="secondary"
                  variant="dot"
                  data-testid="ownership-requests-selected-filter-badge"
                  invisible={!selectedFilters}
                >
                  <FilterIcon width="16px" height="16px" stroke="#43485C" />
                </Badge>
              }
              onClick={() => {
                setOpenFilterModal(true);
              }}
            >
              {t(TEXT.COMMON.FILTERS_BUTTON)}
            </Button>
            <Typography className={classes.dividerText} alignSelf="baseline">
              |
            </Typography>
            <Link
              data-testid="ownership-requests-clear-all-filters"
              onClick={() => clearAllFilters()}
              underline="hover"
            >
              {t(TEXT.COMMON.CLEAR_ALL)}
            </Link>
          </Box>
        </Box>
        <Dialog
          fullWidth
          maxWidth="md"
          data-testid="ownership-requests-filter-dialog"
          aria-labelledby="ownership-requests-filters-dialog"
          open={openFilterModal}
          onClose={handleFiltersDialogClose}
          PaperProps={{
            className: globalClasses.dynamicDialog,
          }}
        >
          <DialogTitle id="ownership-requests-dialog-title">
            <Typography variant="h4" component="span" className={classes.header}>
              {t(TEXT.COMMON.FILTERS.TITLES.OWNERSHIP_REQUESTS)}
            </Typography>
            <IconButton
              className={classes.iconPosition}
              data-testid="cancel-notification-button"
              onClick={handleFiltersDialogClose}
              size="large"
              color="primary"
            >
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent className={classes.dialogContent}>
            <Box ml={8} mt={3}>
              <Typography className={classes.subheader}>
                {t(TEXT.COMMON.FILTERS.SECTIONS.CATEGORY)}
              </Typography>
              <Grid container mt={3}>
                <Grid item xs={12} sm={6}>
                  <DropdownMultiSelect
                    label={t(TEXT.COMMON.FILTERS.INPUT_LABELS.OWNERSHIP_REQUEST_STATUS)}
                    prevSelectedValue={formik.values.status}
                    dropdownValues={[StatusTypes.APPROVED, StatusTypes.PENDING, StatusTypes.DENIED]}
                    onSelectCallbackFunction={(value: string) => {
                      formik.setFieldValue('status', value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <Divider />
          <DialogActions className={classes.footerSpacing}>
            <Box display="flex" justifyContent="flex-end">
              <Box mr={2}>
                <StepperButton
                  buttonText={t(TEXT.COMMON.CLOSE)}
                  id="cancel-ownership-requests-filter"
                  color="secondary"
                  onClick={handleFiltersDialogClose}
                />
              </Box>
              <Box mr={2}>
                <StepperButton
                  buttonText={t(TEXT.COMMON.CLEAR_ALL)}
                  id="clear-all-ownership-requests-filter"
                  color="secondary"
                  onClick={() => {
                    clearAllFilters();
                    handleFiltersDialogClose();
                  }}
                />
              </Box>
              <Box mr={2}>
                <StepperButton
                  type="submit"
                  buttonText={t(TEXT.COMMON.APPLY)}
                  id="apply-ownership-requests-filters"
                  color="primary"
                  disabled={!formik.dirty || !!Object.entries(formik.errors).length}
                  onClick={() => {
                    onClickOfApply(formik.values);
                    handleFiltersDialogClose();
                  }}
                />
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </form>
  );
};

export default OwnershipRequestsFilters;
