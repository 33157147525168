import { Button } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';

import { closeSnackbarAction } from '../../store/snackbar/actions';
import { snackbarActionNames } from '../../store/snackbar/types';
import useStyles from './styles';
import { useDispatchTs } from '../../store';
import { showNotification } from '../../utils/util';
import TEXT from '../../globals/translation-map';
import { useTranslation } from 'react-i18next';

type UseCancelNotificationType = {
  handleContinue: Function;
  handleCancel?: Function;
  key?: SnackbarKey;
};

const useCancelNotification = (props: UseCancelNotificationType) => {
  const { t } = useTranslation();
  const { handleContinue, handleCancel, key } = props;
  const closeSnackbar = useSnackbar()?.closeSnackbar;
  const [visibility, setVisibility] = useState<boolean>(false);
  const dispatch = useDispatchTs();
  const { classes } = useStyles();
  const ActionButtons = useCallback(
    (keyAction: SnackbarKey) => {
      const dismissSnackbar = () => {
        closeSnackbar(key);
        dispatch(closeSnackbarAction(keyAction));
      };
      return (
        <>
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              dismissSnackbar();
              if (handleCancel) {
                handleCancel();
              }
              dispatch({
                type: snackbarActionNames.SET_CANCEL_NOTIFICATION_VISIBILITY_FLAG,
                payload: false,
              });
            }}
            className={classes.button}
            data-testid="cancel-notification-btn"
          >
            Cancel
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              handleContinue();
              dismissSnackbar();
              dispatch({
                type: snackbarActionNames.SET_CANCEL_NOTIFICATION_VISIBILITY_FLAG,
                payload: false,
              });
            }}
            className={classes.button}
            data-testid="continue-notification-button"
          >
            Continue
          </Button>
        </>
      );
    },
    [classes.button, closeSnackbar, handleContinue, handleCancel],
  );

  useEffect(() => {
    if (visibility) {
      dispatch({
        type: snackbarActionNames.SET_CANCEL_NOTIFICATION_VISIBILITY_FLAG,
        payload: true,
      });
      showNotification(
        dispatch,
        'warning',
        t(TEXT.COMMON.ERRORS.LEAVING_PAGE),
        { persist: true, action: ActionButtons },
        'cancel-notification-key',
      );
      setVisibility(false);
    }
  }, [key, visibility]);

  return setVisibility;
};

export default useCancelNotification;
