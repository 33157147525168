/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Filter will find all filter values that contain given search string.
 */
export interface SearchContainsFilter { 
    /**
     * Enum that defines contains filter type.
     */
    type: SearchContainsFilter.TypeEnum;
    /**
     * User properties that can be used with contains type filtering.
     */
    propertyName: SearchContainsFilter.PropertyNameEnum;
    /**
     * Filter will find all filter values that contain given search string.
     */
    value: string;
}
export namespace SearchContainsFilter {
    export type TypeEnum = 'contains';
    export const TypeEnum = {
        Contains: 'contains' as TypeEnum
    };
    export type PropertyNameEnum = 'catrecid' | 'email' | 'username';
    export const PropertyNameEnum = {
        Catrecid: 'catrecid' as PropertyNameEnum,
        Email: 'email' as PropertyNameEnum,
        Username: 'username' as PropertyNameEnum
    };
}