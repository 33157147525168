import React, { useState, useEffect, ReactNode, FC } from 'react';
import Grid from '@mui/material/Grid';
import { Box, Tooltip, Typography } from '@mui/material';
import useStyles from './styles';
import TransferTableList from './components/transfer-table-list';
import CheckMarkIcon from '../../assets/icons/checkmark';
import AddItemIcon from '../../assets/icons/add-item';
import RemoveItemIcon from '../../assets/icons/remove-item';
import { getValueFromObject } from '../../utils/util';
import TransferTableWarning from './components/transfer-table-warning';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';

export type WarningType = {
  text: string | ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmCallback?: any;
  cancelCallback?: any;
};

type TransferTableProperties<T> = {
  ItemComponent: FC<{ item: T }>;
  itemComponentHeight?: number;
  searchListLabel?: string;
  addedListLabel?: string;
  items: Array<T> | [];
  alreadyAddedItems?: Array<T> | [];
  primaryKeyForItem: string;
  hideClearLabels?: boolean;
  hasNextPageForItems?: boolean;
  nextPageCallbackFunction: () => Promise<void>;
  isLoadingDataForItems?: boolean;
  noSearchItemFoundText?: string;
  onClickOfActionOnSearchedItem?: (
    item: T,
    hideWarningFlag?: boolean,
    customerOrganizationCallFlag?: boolean,
  ) => Promise<boolean>;
  onClickOfActionOnAddedItem?: (item: T) => Promise<boolean>;
  onClickOfClearAllOnSearchedItemsList?: Function;
  onClickOfClearAllOnAddedItemsList?: () => Promise<boolean>;
  maxNumberOfAddedItems?: number;
  openWarning?: boolean;
  warning?: WarningType;
  listWidth?: string;
  containerClass?: string;
};

const TransferTable = <T,>(properties: TransferTableProperties<T>) => {
  const { t } = useTranslation();
  const {
    ItemComponent,
    itemComponentHeight,
    searchListLabel = t(TEXT.COMMON.SEARCH_RESULTS),
    addedListLabel = t(TEXT.COMMON.ADDED),
    hideClearLabels = false,
    items,
    alreadyAddedItems,
    primaryKeyForItem,
    hasNextPageForItems,
    nextPageCallbackFunction,
    isLoadingDataForItems,
    noSearchItemFoundText,
    onClickOfActionOnSearchedItem,
    onClickOfActionOnAddedItem,
    onClickOfClearAllOnSearchedItemsList,
    onClickOfClearAllOnAddedItemsList,
    maxNumberOfAddedItems,
    openWarning,
    warning,
    listWidth,
    containerClass,
  } = properties;
  const { classes } = useStyles();

  const [searchedItems, setSearchedItems] = useState<Array<T>>([]);
  const [addedItems, setAddedItems] = useState<Array<T>>([]);
  const [itemToBeAdded, setItemToBeAdded] = useState<T>();
  useEffect(() => {
    setSearchedItems([...items]);
  }, [items]);

  useEffect(() => {
    if (alreadyAddedItems && alreadyAddedItems?.length > 0) {
      setAddedItems([...addedItems, ...alreadyAddedItems]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyAddedItems]);

  const checkForDuplicateItem = (arrayToCheck: Array<T>, value: T) => {
    const checked = arrayToCheck.filter((object) => {
      return (
        getValueFromObject(object, primaryKeyForItem) ===
        getValueFromObject(value, primaryKeyForItem)
      );
    });
    return checked;
  };

  const handleActionClickForSearchedItem = async (
    clickedItem: T,
    hideWarning?: boolean,
    customerOrganizationCall?: boolean,
  ) => {
    let allowClick = true;
    if (!maxNumberOfAddedItems) {
      allowClick = true;
    } else if (!(addedItems.length < (maxNumberOfAddedItems || items.length))) {
      allowClick = false;
    }
    if (allowClick) {
      setItemToBeAdded(clickedItem);
      if (onClickOfActionOnSearchedItem) {
        const continueExecutionFlag = await onClickOfActionOnSearchedItem(
          clickedItem,
          hideWarning,
          customerOrganizationCall,
        );
        if (!continueExecutionFlag) {
          return;
        }
      }
      setAddedItems([...addedItems, clickedItem]);
      const indexOfClickedItem = searchedItems.findIndex((item) => {
        return (
          getValueFromObject(item, primaryKeyForItem) ===
          getValueFromObject(clickedItem, primaryKeyForItem)
        );
      });
      setSearchedItems([
        ...searchedItems.slice(0, indexOfClickedItem),
        ...searchedItems.slice(indexOfClickedItem + 1),
      ]);
      setItemToBeAdded(undefined);
    }
  };

  const handleActionClickForAddedItem = async (clickedItem: T) => {
    if (onClickOfActionOnAddedItem) {
      const continueExecutionFlag = await onClickOfActionOnAddedItem(clickedItem);
      if (!continueExecutionFlag) {
        return;
      }
    }
    if (checkForDuplicateItem(searchedItems, clickedItem).length === 0) {
      setSearchedItems([clickedItem, ...searchedItems]);
    }
    const indexOfClickedItem = addedItems.findIndex((item) => {
      return (
        getValueFromObject(item, primaryKeyForItem) ===
        getValueFromObject(clickedItem, primaryKeyForItem)
      );
    });
    setAddedItems([
      ...addedItems.slice(0, indexOfClickedItem),
      ...addedItems.slice(indexOfClickedItem + 1),
    ]);
  };

  const clearResults = () => {
    if (onClickOfClearAllOnSearchedItemsList) {
      onClickOfClearAllOnSearchedItemsList();
    }
    setSearchedItems([]);
  };

  const removeAll = async () => {
    if (onClickOfClearAllOnAddedItemsList) {
      const continueExecutionFlag = await onClickOfClearAllOnAddedItemsList();
      if (!continueExecutionFlag) {
        return;
      }
    }
    const itemsToRemove: T[] = searchedItems;
    for (const object of addedItems) {
      if (checkForDuplicateItem(searchedItems, object).length === 0) {
        itemsToRemove.unshift(object);
      }
    }
    setSearchedItems(itemsToRemove);
    setAddedItems([]);
  };

  const handleWarningConfirmButtonClick = () => {
    if (itemToBeAdded) {
      handleActionClickForSearchedItem(itemToBeAdded, true, false);
    }
    warning?.confirmCallback();
  };

  const handleWarningCancelButtonClick = () => {
    warning?.cancelCallback();
    setItemToBeAdded(undefined);
  };
  return (
    <Grid
      container
      spacing={4}
      data-testid="transfer-table-container"
      className={containerClass !== undefined ? containerClass : classes.container}
    >
      {warning && openWarning && (
        <TransferTableWarning
          item={itemToBeAdded}
          open
          warningText={warning.text}
          confirmButtonText={warning.confirmButtonText}
          cancelButtonText={warning.cancelButtonText}
          confirmCallback={handleWarningConfirmButtonClick}
          cancelCallback={handleWarningCancelButtonClick}
        />
      )}
      <Grid item xs={12} sm={6}>
        <TransferTableList
          testDataId="search-result-list"
          ItemComponent={ItemComponent}
          title={`${searchListLabel} (${searchedItems.length})`}
          clearItems={
            !hideClearLabels ? (
              <Typography
                data-testid="clear-results"
                className={classes.subheader}
                onClick={clearResults}
              >
                {t(TEXT.COMMON.CLEAR_RESULTS)}
              </Typography>
            ) : null
          }
          items={searchedItems}
          itemHeight={itemComponentHeight}
          noItemsFoundText={noSearchItemFoundText}
          hasNextPage={hasNextPageForItems}
          nextPageCallbackFunction={nextPageCallbackFunction}
          isLoadingDataForNextPage={isLoadingDataForItems}
          listWidth={listWidth}
          actionButton={(item?: T) => {
            if (item) {
              return checkForDuplicateItem(addedItems, item).length > 0 ? (
                <Tooltip
                  title={t(TEXT.COMMON.ADDED)}
                  placement="top"
                  arrow
                  className={classes.tooltip}
                >
                  <Box>
                    <CheckMarkIcon
                      data-testid="already-added-item-icon"
                      className={classes.checkmark}
                    />
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip
                  title={t(TEXT.MY_CUSTOMERS.ASSOCIATE_DEALER_CUSTOMER.ADD)}
                  placement="top"
                  arrow
                  className={classes.tooltip}
                >
                  <Box
                    onClick={() => {
                      handleActionClickForSearchedItem(item, undefined, true);
                    }}
                  >
                    <AddItemIcon
                      data-testid="add-item-icon"
                      className={classes.addRemoveItemIcon}
                    />
                  </Box>
                </Tooltip>
              );
            }
            return null;
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TransferTableList
          testDataId="added-list"
          ItemComponent={ItemComponent}
          title={`${addedListLabel} (${addedItems.length}${
            maxNumberOfAddedItems ? `/${maxNumberOfAddedItems}` : ''
          })`}
          listWidth={listWidth}
          actionButton={(item?: T) => {
            if (item) {
              return (
                <Tooltip
                  title={t(TEXT.COMMON.REMOVE)}
                  placement="top"
                  arrow
                  className={classes.tooltip}
                >
                  <Box onClick={() => handleActionClickForAddedItem(item)}>
                    <RemoveItemIcon
                      data-testid="remove-item-icon"
                      className={classes.addRemoveItemIcon}
                    />
                  </Box>
                </Tooltip>
              );
            }
            return null;
          }}
          clearItems={
            !hideClearLabels ? (
              <Typography
                data-testid="remove-all-results"
                className={classes.subheader}
                onClick={removeAll}
              >
                {t(TEXT.COMMON.REMOVE_ALL)} ({addedItems.length})
              </Typography>
            ) : null
          }
          items={addedItems}
          itemHeight={itemComponentHeight}
          nextPageCallbackFunction={nextPageCallbackFunction}
        />
      </Grid>
    </Grid>
  );
};

export default TransferTable;
