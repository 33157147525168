import React from 'react';
import { Tooltip, Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import InfoIcon from '../../assets/icons/info-icon';
import { iconVariant } from '../../assets/icons/icon-types';
import AlertInfoIcon from '../../assets/icons/alert-info-icon';

const LightTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 0px 10px 0px rgba(80, 95, 122, 0.30)',
    maxWidth: '400px',
    fontSize: '14px',
    padding: '16px',
    lineHeight: '24px',
  },
  arrow: {
    color: theme.palette.common.white,
  },
}));

type InformationIconPopOverProperties = {
  width: string;
  height: string;
  title: string | React.ReactNode;
  variant?: iconVariant;
  shape?: string;
  marginTop?: string;
  iconColor?: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

const InformationIconPopOver = (props: InformationIconPopOverProperties) => {
  const {
    width,
    height,
    title,
    variant,
    shape = 'info',
    marginTop,
    iconColor,
    placement = 'bottom-start',
  } = props;
  return (
    <LightTooltip data-testid="information-icon-tooltip" title={title} arrow placement={placement}>
      <Box data-testid="info-icon">
        {shape === 'info' && (
          <InfoIcon
            width={width}
            height={height}
            variant={variant}
            marginTop={marginTop}
            iconColor={iconColor}
          />
        )}
        {shape === 'alert-info' && (
          <AlertInfoIcon width={width} height={height} variant={variant} marginTop={marginTop} />
        )}
      </Box>
    </LightTooltip>
  );
};

export default InformationIconPopOver;
