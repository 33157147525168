import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, IconButton, Typography, Grid } from '@mui/material';
import { INVITATION_APPLICATIONS } from '../../../../services/invitations';
import Loading from '../../../../components/loading';

import DealerCustomer, {
  DealerCustomerSearchResponse,
  SearchDealerCustomerRequest,
} from '../../../../services/dealer-customer';
import { AppState, useDispatchTs } from '../../../../store';

import useStyles from './styles';
import ApplicationDisplay from '../../../invitations/components/invitations-drawer/components/application-display';
import { AccessType } from '../../../../entities/user-onboarding-v1/accessType';
import { nameIdPairArray } from '../../../../globals/generic-types';
import { PartyType } from '../../../../entities/entitlements-v1/partyType';
import TEXT from '../../../../globals/translation-map';
import { useTranslation } from 'react-i18next';
import { showNotification } from '../../../../utils/util';

type DealerCodeModalProperties = {
  open: boolean;
  setOpen: Function;
  selectedDealerCodes?: nameIdPairArray;
  setSelectedDealerCodes: Function;
  customerOrganizationIdentifier: string;
  selectedAccount?: string;
};

const DealerCodeModal = (properties: DealerCodeModalProperties) => {
  const { t } = useTranslation();
  const {
    open,
    setOpen,
    customerOrganizationIdentifier,
    selectedDealerCodes,
    setSelectedDealerCodes,
    selectedAccount,
  } = properties;
  const { classes } = useStyles();
  const dispatch = useDispatchTs();
  const { authInfo, environmentVariables } = useSelector((state: AppState) => state.login);

  const [dealerCustomers, setDealerCustomers] = useState<
    Array<{ id: string; name: string; applicationKey: string }>
  >([]);
  const [dealerCodesToAssociate, setDealerCodesToAssociate] = useState<nameIdPairArray>(
    selectedDealerCodes || [],
  );

  const [dciLoading, setDCIloading] = useState(false);

  useEffect(() => {
    if (customerOrganizationIdentifier.length > 0) getDealerCodes();
  }, [customerOrganizationIdentifier]);

  const getDealerCodes = async () => {
    try {
      if (customerOrganizationIdentifier) {
        const dealerCustomer = new DealerCustomer(authInfo, environmentVariables.customerMasterURI);
        const searchDealerCustomerRequest: SearchDealerCustomerRequest = {
          body: {
            logicalExpression: '$0',
            filters: [
              {
                propertyName: 'associatedCustomerOrganizationIdentifier',
                type: 'stringEquals',
                values: [customerOrganizationIdentifier],
              },
            ],
          },
          limit: 200,
        };

        if (selectedAccount !== PartyType.CAT && selectedAccount) {
          searchDealerCustomerRequest.body?.filters.push({
            propertyName: 'dealerCode',
            type: 'stringEquals',
            values: [selectedAccount],
          });
          if (searchDealerCustomerRequest?.body?.logicalExpression) {
            searchDealerCustomerRequest.body.logicalExpression = '$0 & $1';
          }
        }
        setDCIloading(true);
        const dealerResponse = await dealerCustomer.searchDealerCustomer(
          searchDealerCustomerRequest,
        );

        if (
          dealerResponse.status === 200 &&
          (dealerResponse.data as DealerCustomerSearchResponse).dealerCustomers.length > 0
        ) {
          let dealerCustomerIds: any = [];
          const dealerListResponse = dealerResponse.data as DealerCustomerSearchResponse;
          for (const dealer of dealerListResponse?.dealerCustomers) {
            if (dealer?.dealerCustomerDetails?.dealerCustomerIdentifier) {
              dealerCustomerIds.push({
                id: dealer?.dealerCustomerDetails?.dealerCustomerIdentifier,
                name: dealer?.dealerCustomerDetails?.dealerCustomerBusinessName,
                applicationKey: INVITATION_APPLICATIONS.PCC,
              });
            }
          }
          setDealerCustomers(dealerCustomerIds);
        }
        setDCIloading(false);
      }
    } catch {
      setDCIloading(false);
      showNotification(
        dispatch,
        'error',
        t(TEXT.MY_CUSTOMERS.ASSOCIATE_DEALER_CUSTOMER.ERRORS.SEARCH_DEALER_CUSTOMER),
      );
    }
  };

  const onApply = () => {
    setSelectedDealerCodes(dealerCodesToAssociate);
    setOpen(false);
  };
  const onDealerCodeSelect = (applicationId: string, value: nameIdPairArray) => {
    setDealerCodesToAssociate(value);
  };

  const cancelNotification = () => {
    setOpen(false);
  };

  return (
    <Dialog data-testid="dealer-code-modal-container" open={open} maxWidth="xs">
      <DialogTitle {...({ component: 'div' } as any)} data-testid="search-dealer-code-title">
        <Typography variant="h3">Edit Dealer Customers</Typography>
        <IconButton
          className={classes.iconPosition}
          onClick={() => cancelNotification()}
          data-testid="search-dealer-code-close-icon"
          size="large"
        >
          <CloseIcon color="primary" fontSize="small" />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText component="div">
          {'Select Dealer Customers to approve this access request.'}
        </DialogContentText>
        <Box pt={2}>
          <Grid item xs={12} p={1}>
            {!dciLoading ? (
              <ApplicationDisplay
                key={INVITATION_APPLICATIONS.PCC}
                application={{
                  applicationId: INVITATION_APPLICATIONS.PCC,
                  accessType: AccessType.DCI,
                  accessIdentifiers: [],
                }}
                limit={1000}
                edit={true}
                value={dealerCodesToAssociate || []}
                options={dealerCustomers || []}
                setValue={onDealerCodeSelect}
                titleClass={classes.sectionSubHeader}
                nameClass={classes.nameBlock}
                dciLoadingFlag={dciLoading}
                accessRequestModal={true}
              ></ApplicationDisplay>
            ) : (
              <Box className={classes.loading}>
                <Loading />
              </Box>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          data-testid="search-dealer-code-close-button"
          variant="outlined"
          onClick={() => cancelNotification()}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !dealerCodesToAssociate ||
            (dealerCodesToAssociate && dealerCodesToAssociate.length === 0)
          }
          data-testid="search-dealer-code-apply-button"
          onClick={onApply}
          color="primary"
          variant="contained"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DealerCodeModal;
