/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Customer organization properties that can be used for filtering. If hasAssociationToMyDealerCodes filter is set to \"true\", returned data will be based on the user's dealer codes listed in his entitlements. If hasAssociatedDcn filter is set to \"true\", only organizations with associated DCNs will be returned. 
 */
export type CustomerOrganizationBooleanPropertiesForFiltering = 'hasAssociationToMyDealerCodes' | 'hasAssociatedDcn' | 'managedAccountIndicator' | 'topLevel';

export const CustomerOrganizationBooleanPropertiesForFiltering = {
    HasAssociationToMyDealerCodes: 'hasAssociationToMyDealerCodes' as CustomerOrganizationBooleanPropertiesForFiltering,
    HasAssociatedDcn: 'hasAssociatedDcn' as CustomerOrganizationBooleanPropertiesForFiltering,
    ManagedAccountIndicator: 'managedAccountIndicator' as CustomerOrganizationBooleanPropertiesForFiltering,
    TopLevel: 'topLevel' as CustomerOrganizationBooleanPropertiesForFiltering
};