/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DealerCustomerAddress } from './dealerCustomerAddress';
import { RecommendedDealerCustomer } from './recommendedDealerCustomer';

/**
 * Response with details of the recommended dealer customers found for customer organizations for recommendation type AssociateDcnToExistingCatAssociation. 
 */
export interface AssociateDcnResponseArrayItem { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType?: AssociateDcnResponseArrayItem.RecommendationTypeEnum;
    dealerCustomerDetails?: RecommendedDealerCustomer;
    /**
     * List of addresses related to the customer organization. 
     */
    dealerCustomerAddresses?: Array<DealerCustomerAddress>;
}
export namespace AssociateDcnResponseArrayItem {
    export type RecommendationTypeEnum = 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEDCNTOEXISTINGCATASSOCIATION: 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum
    };
}