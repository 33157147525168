import React from 'react';
import { Box, Typography } from '@mui/material';
import useStyles from './styles';
import ErrorIcon from '../../assets/icons/error-icon';
type ErrorBoxType = {
  message?: string;
  fillParentContainer?: boolean;
};

const ErrorBox = (props: ErrorBoxType) => {
  const { message, fillParentContainer } = props;
  const { classes } = useStyles();
  return (
    <Box className={fillParentContainer ? classes.fillContainer : ''}>
      <Box className={`${classes.container}`} data-testid="errorbox-container">
        <Box className={classes.iconContainer} data-testid="errorbox-icon">
          <ErrorIcon />
        </Box>
        <Typography data-testid="errorbox-message">
          {message ||
            'There was an error in retrieving the requested information. Please close this view and try again later.'}
        </Typography>
      </Box>
    </Box>
  );
};

export default ErrorBox;
