import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import './calendar.css';
import App from './application';
import { disableReactDevelopmentTools } from './utils/util';
import 'blocks-web-components/dist/bedrock/bedrock.css';
import './i18n';
disableReactDevelopmentTools();
//  @ts-ignore
// eslint-disable-next-line unicorn/prefer-module
window.Buffer = window.Buffer || require('buffer').Buffer;

const domNode = document.querySelector('#root');

const root = ReactDOM.createRoot(domNode as Element);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
