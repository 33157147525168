import React from 'react';
import { Box } from '@mui/material';
import LoadingIcon from '../../assets/icons/loading';
import CatLoadingIndicator from '../cat-loading-indicator';
import useStyles from './styles';

type LoadingProperties = {
  blocks?: boolean;
  height?: string | number;
  width?: string | number;
  display?: 'block' | 'inline-block' | 'inline';
  small?: boolean;
};

const Loading = (props: LoadingProperties) => {
  const { blocks, height = 50, width = 100, display, small } = props;
  const { classes } = useStyles();
  return blocks ? (
    <CatLoadingIndicator small={small} />
  ) : (
    <Box data-testid="app-loading" className={classes.loading} display={display}>
      <LoadingIcon height={height} width={width} />
    </Box>
  );
};

export default Loading;
