/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RecommendedDealerCustomerDetails } from './recommendedDealerCustomerDetails';
import { RecommendedSecondaryCustomerOrganizationDetails } from './recommendedSecondaryCustomerOrganizationDetails';

/**
 * Request to associate customer organization to a primary customer organization and associate dealer customer to a primary customer organization.
 */
export interface UpdateCustomerOrganizationAndDealerCustomerRecommendation { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType: UpdateCustomerOrganizationAndDealerCustomerRecommendation.RecommendationTypeEnum;
    recommendedAssociations: Array<RecommendedDealerCustomerDetails | RecommendedSecondaryCustomerOrganizationDetails>;
}
export namespace UpdateCustomerOrganizationAndDealerCustomerRecommendation {
    export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE CAT ASSOCIATION & ASSOCIATE DCN TO EXISTING CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEMULTIPLECATASSOCIATIONASSOCIATEDCNTOEXISTINGCATASSOCIATION: 'ASSOCIATE MULTIPLE CAT ASSOCIATION & ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum
    };
}