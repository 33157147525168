import React, { ReactElement } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CatToggle from '../cat-toggle';

interface ToggleSwitchProperties {
  label?: string | ReactElement;
  disabled?: boolean;
  toggleSwitchCallback: Function;
  checked: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom' | undefined;
  customClass?: string;
  testId?: string;
  blocks?: boolean;
}

// Issue with material switch component. input value of checked not being updated Aug 11, 2022
const ToggleSwitch = (properties: ToggleSwitchProperties) => {
  const {
    label,
    disabled = false,
    toggleSwitchCallback,
    checked,
    labelPlacement,
    customClass,
    blocks,
    testId,
  } = properties;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleSwitchCallback(event.target.checked);
  };

  return blocks ? (
    <CatToggle
      label={label}
      labelPlacement={labelPlacement}
      toggleSwitchCallback={toggleSwitchCallback}
      checked={checked}
      disabled={disabled}
    />
  ) : (
    <FormGroup data-testid={testId || 'toggle-switch'}>
      {label ? (
        <FormControlLabel
          control={
            <Switch
              checked={checked}
              onChange={handleChange}
              color="secondary"
              data-testid={testId ? testId + '-value' : 'toggle-switch-value'}
              disabled={disabled}
            />
          }
          label={label}
          disabled={disabled}
          labelPlacement={labelPlacement}
          className={customClass}
        />
      ) : (
        <Switch
          checked={checked}
          onChange={handleChange}
          color="secondary"
          data-testid={testId ? testId + '-value' : 'toggle-switch-value'}
          disabled={disabled}
        />
      )}
    </FormGroup>
  );
};

export default ToggleSwitch;
