import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import React, { useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import StepperButton from '../../../../components/stepper-button';
import useStyles from './styles';
import globalStyles from '../../../../globals/styles';
import RadioButton from '../../../../components/radio-button';
import { IdentitySearchResponse } from '../../../../entities/user-identity-v1/identitySearchResponse';
import { AppState, useDispatchTs } from '../../../../store';
import { useSelector } from 'react-redux';
import { invitationsActionNames, InviteAccount } from '../../../../store/invitations/types';

export type MultipleUserWarningData = {
  [key: string]: IdentitySearchResponse;
};

type MultipleUserWarningProperties = {
  data: MultipleUserWarningData;
  onCancel: () => void;
  onContinue: (users: { [key: string]: string }) => void;
};

const WarningModal = (props: MultipleUserWarningProperties) => {
  const dispatch = useDispatchTs();
  const { data, onCancel, onContinue } = props;
  const { classes } = useStyles();
  const { classes: globalClasses } = globalStyles();

  const [selectedUsernames, setSelectedUsernames] = React.useState<{ [key: string]: string }>({});
  const {
    createInvitations: { accounts },
  } = useSelector((state: AppState) => state.invitation);
  const handleChange = (email: string, event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target;
    setSelectedUsernames({
      ...selectedUsernames,
      [email.toLocaleLowerCase()]: value,
    });
  };

  const handleClose = (event: React.MouseEvent) => {
    setSelectedUsernames({});
    onCancel();
    event.stopPropagation();
    event.preventDefault();
  };

  const handleContinue = (event: React.MouseEvent) => {
    let newAccountMap = [...accounts];
    for (const key of Object.keys(selectedUsernames)) {
      const index = newAccountMap.findIndex(
        (account: InviteAccount) => key === account.emailAddress,
      );
      if (index >= 0) {
        newAccountMap[index] = {
          emailAddress: key,
          username: selectedUsernames[key],
        };
      } else {
        newAccountMap.push({
          emailAddress: key,
          username: selectedUsernames[key],
        });
      }
    }
    dispatch({ type: invitationsActionNames.SET_ACCOUNTS, payload: newAccountMap });
    onContinue(selectedUsernames);
    event.stopPropagation();
    event.preventDefault();
  };

  const continueDisabled = useMemo(
    () => Object.keys(data).length !== Object.keys(selectedUsernames).length,
    [data, selectedUsernames],
  );

  return (
    <Dialog
      classes={{ paper: classes.dialog }}
      data-testid="multiple-user-warning"
      fullWidth
      maxWidth="sm"
      open={Object.keys(data).length > 0}
      onClose={handleClose}
    >
      <DialogTitle id="multiple-user-warning-title">
        <Typography className={globalClasses.dialogTitle}>Multiple CWS IDs Identified</Typography>
        <IconButton
          className={classes.iconPosition}
          data-testid="cancel-notification-button"
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography
          className={classes.selectReasonText}
          data-testid="multiple-user-warning-continue"
        >
          The following email is tied to more than one CWS ID. Select the correct username to
          continue.
        </Typography>
        {Object.keys(data).map((email) => (
          <Box key={email} pt={2}>
            <Box mb={3}>
              <Divider />
            </Box>
            <b>
              <span data-testid="multiple-user-warning-email">{email}</span>
              <sup className={classes.warningText}>*</sup>
            </b>
            {!selectedUsernames[email] && (
              <Box className={classes.warningText}>You are required to make a selection.</Box>
            )}
            {data[email].map((user, index) => {
              const userEmail = (user.email as string).toLocaleLowerCase();
              const username = user.cwsId as string;
              return (
                <Box key={index} pt={1} className={classes.radioButtonAlignment}>
                  <RadioButton
                    data-testid="multiple-user-warning-radio-button"
                    checked={selectedUsernames[email] === username}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(e) => handleChange(userEmail, e)}
                    value={username}
                  />
                  <Typography mt={-0.25}>{username}</Typography>
                </Box>
              );
            })}
          </Box>
        ))}
      </DialogContent>
      <Divider />
      <DialogActions className={classes.actionButtons}>
        <Box mr={0.5}>
          <StepperButton
            buttonText="Cancel"
            id="cancel-multiple-user-warning-popup"
            color="secondary"
            onClick={handleClose}
          />
        </Box>
        <Box mr={1.25}>
          <StepperButton
            type="submit"
            buttonText="Continue"
            disabled={continueDisabled}
            id="continue-multiple-user-warning-popup"
            color="primary"
            onClick={handleContinue}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default WarningModal;
