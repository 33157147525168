import React, { ComponentType, FC } from 'react';
import InfiniteLoader from 'react-window-infinite-loader';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';

type InfiniteLoaderWrapperProperties = {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: Array<any>;
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<any> | null;
  ItemComponent: ComponentType<ListChildComponentProps<any>>;
  height: string | number;
  itemSize: number;
  width: string | number;
};

const InfiniteLoaderWrapper: FC<InfiniteLoaderWrapperProperties> = (
  props: InfiniteLoaderWrapperProperties,
) => {
  const {
    hasNextPage,
    isNextPageLoading,
    items,
    loadNextPage,
    ItemComponent,
    height,
    itemSize,
    width,
  } = props;
  const itemCount = hasNextPage ? items.length + 1 : items.length;
  const loadMoreItems = isNextPageLoading
    ? () => {
        return null;
      }
    : loadNextPage;
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems as any}
    >
      {({ onItemsRendered, ref }) => (
        <List
          height={height}
          itemCount={itemCount}
          itemSize={itemSize}
          onItemsRendered={onItemsRendered}
          ref={ref}
          width={width}
          overscanCount={1}
          style={{ overflowY: 'auto' }}
        >
          {ItemComponent}
        </List>
      )}
    </InfiniteLoader>
  );
};

export default InfiniteLoaderWrapper;
