import { SearchOrganizationResponse } from '../../../../entities/customer-master-v1/models';
import { AccessType } from '../../../../entities/user-onboarding-v1/accessType';
import { Applications } from '../../../../entities/user-onboarding-v1/applications';
import { CreateInviteErrorResponse } from '../../../../entities/user-onboarding-v1/createInviteErrorResponse';
import { InviteResponse } from '../../../../entities/user-onboarding-v1/inviteResponse';
import { nameIdPair, nameIdPairArray } from '../../../../globals/generic-types';
import { ApplicationWithFullIdentifiers } from '../../../../services/invitations';
import TEXT from '../../../../globals/translation-map';

export type InvitationDrawerProperties = {
  edit?: boolean;
  setDisplay: Function;
  partyNumber: string;
  undoRevokeCallback: Function;
  searchCallback: Function;
  inviteDetails: InviteResponse | CreateInviteErrorResponse | undefined;
  loadCustomerFlag: boolean;
  customerInfo: SearchOrganizationResponse | undefined;
  showInviteDetailsDrawer: boolean;
};
export type InviteHeaderProperties = {
  status: any;
  dateInvited?: string;
  dateRequested?: string;
  dateApproved?: string;
};
export type ApplicationDropdownOptionType = {
  id: string;
  name: string;
  applicationKey?: string;
  dealerNumber?: string;
};
export type ApplicationPCCValue = Array<
  {
    applicationKey?: string;
  } & nameIdPair
>;
export type ApplicationMap = {
  [key: string]: {
    value: Array<string> | ApplicationPCCValue | string;
    options: Array<ApplicationDropdownOptionType>;
    applicationName: string;
    type?: string;
  };
};
export type ApplicationsType = Applications & {
  accessIdentifierType?: AccessType;
  restrictedParties?: string[];
};
export type ApplicationDisplayProperties = {
  application: ApplicationWithFullIdentifiers;
  dciLoadingFlag?: boolean;
  edit?: boolean;
  nameClass: string;
  options: Array<ApplicationDropdownOptionType>;
  setValue: Function;
  titleClass: string;
  value: nameIdPairArray;
  accessRequestModal?: boolean;
  limit?: number;
  error?: boolean;
};

export const selectAllID = 'selectAll';
export const selectAllOption = { id: selectAllID, name: TEXT.COMMON.SELECT_ALL };
