/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerOrganizationCreateForRecommendation } from './customerOrganizationCreateForRecommendation';
import { RecommendedDealerCustomerForNewlyCreatedCustomerOrganizationDetails } from './recommendedDealerCustomerForNewlyCreatedCustomerOrganizationDetails';

/**
 * Request to create customer organization and associate dealer customer.
 */
export interface CreateCustomerOrganizationAndUpdateCustomerOrganizationRecommendation { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType: CreateCustomerOrganizationAndUpdateCustomerOrganizationRecommendation.RecommendationTypeEnum;
    recommendedCustomerOrganization?: CustomerOrganizationCreateForRecommendation;
    recommendedAssociations: Array<RecommendedDealerCustomerForNewlyCreatedCustomerOrganizationDetails>;
}
export namespace CreateCustomerOrganizationAndUpdateCustomerOrganizationRecommendation {
    export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE DCN';
    export const RecommendationTypeEnum = {
        ASSOCIATEMULTIPLEDCN: 'ASSOCIATE MULTIPLE DCN' as RecommendationTypeEnum
    };
}