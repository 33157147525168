import {
  CustomerDetailsByIDState,
  customerDetailsByIDActionNames,
  customerDetailsByIDActionTypes,
} from './types';

const initialState: CustomerDetailsByIDState = {};
const customerDetailsByIDReducer = (
  state: CustomerDetailsByIDState = initialState,
  action: customerDetailsByIDActionTypes,
): CustomerDetailsByIDState => {
  switch (action.type) {
    case customerDetailsByIDActionNames.CREATE_HISTORY: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          history: {
            historyRows: action.payload.data,
            details: undefined,
            activeRow: undefined,
            nextCursor: undefined,
            historyLoading: false,
            detailsLoading: false,
            recommendationsLogs: [],
            associationsLogs: [],
          },
        },
      };
    }
    case customerDetailsByIDActionNames.SET_DETAILS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          history: {
            ...state[action.payload.id]?.history,
            details: action.payload.data,
          },
        },
      };
    }
    case customerDetailsByIDActionNames.SET_ACTIVE_ROW: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          history: {
            ...state[action.payload.id]?.history,
            activeRow: action.payload.data,
          },
        },
      };
    }
    case customerDetailsByIDActionNames.SET_HISTORY_LOADING: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          history: {
            ...state[action.payload.id]?.history,
            historyLoading: action.payload.data,
          },
        },
      };
    }
    case customerDetailsByIDActionNames.SET_DETAILS_LOADING: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          history: {
            ...state[action.payload.id]?.history,
            detailsLoading: action.payload.data,
          },
        },
      };
    }

    case customerDetailsByIDActionNames.SET_RECOMMENDATIONS_LOG: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          history: {
            ...state[action.payload.id]?.history,
            recommendationsLogs: action.payload.data,
          },
        },
      };
    }
    case customerDetailsByIDActionNames.SET_ASSOCIATIONS_LOG: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          history: {
            ...state[action.payload.id]?.history,
            associationsLogs: action.payload.data,
          },
        },
      };
    }
    case customerDetailsByIDActionNames.SET_CUSTOMER_ORGANIZATIONS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          customerOrganizations: action.payload.data,
        },
      };
    }
    case customerDetailsByIDActionNames.SET_CUSTOMER_ORGANIZATIONS_COUNT: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          customerOrganizationsCount: action.payload.data,
        },
      };
    }
    case customerDetailsByIDActionNames.SET_DEALER_CUSTOMER_COUNT: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          dealerCustomersCount: action.payload.data,
        },
      };
    }
    case customerDetailsByIDActionNames.SET_DEALER_CUSTOMERS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          dealerCustomers: action.payload.data,
        },
      };
    }
    case customerDetailsByIDActionNames.SET_ASSOCIATED_USERS: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          users: action.payload.data,
        },
      };
    }
    case customerDetailsByIDActionNames.CLEAR_TAB_DATA_BY_CUSTOMER_ORG_ID: {
      delete state[action.payload.id];
      return state;
    }
    case customerDetailsByIDActionNames.SET_STALE_DATA_BY_CUSTOMER_ORG_ID: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          stale: action.payload.stale,
        },
      };
    }

    default:
      return state;
  }
};

export default customerDetailsByIDReducer;
