import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';

const ManagedAccountIcon = ({ marginTop, marginLeft, ...restProps }: iconType) => (
  <SvgIcon
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      height: 'auto',
      fill: 'none',
      width: restProps.width || '28px',
      marginTop: marginTop || 0,
      marginLeft: marginLeft || 0,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4998 0.884766C10.059 0.884766 8.67729 1.4571 7.65853 2.47585C6.81814 3.31624 6.28154 4.40363 6.1194 5.56738H2.91557C1.63936 5.56738 0.604797 6.60195 0.604797 7.87815V12.5605V18.8035C0.604797 20.0797 1.63936 21.1143 2.91557 21.1143H20.084C21.3602 21.1143 22.3948 20.0797 22.3948 18.8035V12.5605V7.87815C22.3948 6.60195 21.3602 5.56738 20.084 5.56738H16.8801C16.718 4.40363 16.1814 3.31624 15.341 2.47585C14.3222 1.4571 12.9405 0.884766 11.4998 0.884766ZM15.3599 5.56738C15.2116 4.8035 14.8385 4.09473 14.2803 3.53651C13.5429 2.79906 12.5427 2.38477 11.4998 2.38477C10.4568 2.38477 9.45664 2.79906 8.71919 3.53651C8.16097 4.09473 7.78792 4.8035 7.63955 5.56738H15.3599ZM2.1048 7.87815C2.1048 7.43038 2.46779 7.06738 2.91557 7.06738H20.084C20.5318 7.06738 20.8948 7.43038 20.8948 7.87815V11.8105H12.2498V10.999C12.2498 10.5848 11.914 10.249 11.4998 10.249C11.0856 10.249 10.7498 10.5848 10.7498 10.999V11.8105H2.1048V7.87815ZM10.7498 14.1206V13.3105H2.1048V18.8035C2.1048 19.2513 2.46779 19.6143 2.91557 19.6143H20.084C20.5318 19.6143 20.8948 19.2513 20.8948 18.8035V13.3105H12.2498V14.1206C12.2498 14.5348 11.914 14.8706 11.4998 14.8706C11.0856 14.8706 10.7498 14.5348 10.7498 14.1206Z"
      fill="#3F3F3F"
    />
  </SvgIcon>
);

export default ManagedAccountIcon;
