import { FeedbackStatus } from '../../entities/customerAssetWorkflow-v1/feedbackStatus';
import { OwnershipChangeRecommendationResponseArrayItem } from '../../entities/customerAssetWorkflow-v1/ownershipChangeRecommendationResponseArrayItem';
import { DealerDetailsResponse } from '../../entities/dealer-details-v1/dealerDetailsResponse';

export enum assetOwnershipActionNames {
  SET_ASSET_OWNERSHIP_DATA = 'SET_ASSET_OWNERSHIP_DATA',
  SET_IS_LOADING_ASSET_OWNERSHIP = 'SET_IS_LOADING_ASSET_OWNERSHIP',
  SET_SELECTED_ASSET_RECOMMENDATION = 'SET_SELECTED_ASSET_RECOMMENDATION',
  SET_ASSET_OWNERSHIP_REASON = 'SET_ASSET_OWNERSHIP_REASON',
  SET_ASSET_OWNERSHIP_FEEDBACK = 'SET_ASSET_OWNERSHIP_FEEDBACK',
  CLEAR_ASSET_OWNERSHIP = 'CLEAR_ASSET_OWNERSHIP',
}

export enum RelationStatusCode {
  ACTIVE = 'ACTIVE',
  AGED = 'AGED',
  OBSOLETE = 'OBSOLETE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export interface AssetOwnershipState {
  isLoadingAssetOwnership: boolean;
  selectedAssetRecommendation?: any;
  assetOwnershipData?:
    | OwnershipChangeRecommendationResponseArrayItem
    | (OwnershipChangeRecommendationResponseArrayItem & DealerDetailsResponse);
  reason?: string;
  feedback?: FeedbackStatus;
}

export interface SetAssetOwnershipLoading {
  type: typeof assetOwnershipActionNames.SET_IS_LOADING_ASSET_OWNERSHIP;
  payload: boolean;
}
export interface SetSelectedAssetRecommendation {
  type: typeof assetOwnershipActionNames.SET_SELECTED_ASSET_RECOMMENDATION;
  payload: any;
}
export interface SetAssetOwnershipData {
  type: typeof assetOwnershipActionNames.SET_ASSET_OWNERSHIP_DATA;
  payload: OwnershipChangeRecommendationResponseArrayItem | undefined;
}
export interface SetAssetOwnershipFeedback {
  type: typeof assetOwnershipActionNames.SET_ASSET_OWNERSHIP_FEEDBACK;
  payload: FeedbackStatus | undefined;
}
export interface SetAssetOwnershipReason {
  type: typeof assetOwnershipActionNames.SET_ASSET_OWNERSHIP_REASON;
  payload: string | undefined;
}
export interface ClearAssetOwnership {
  type: typeof assetOwnershipActionNames.CLEAR_ASSET_OWNERSHIP;
}

export type assetOwnershipActionTypes =
  | SetAssetOwnershipLoading
  | SetSelectedAssetRecommendation
  | SetAssetOwnershipData
  | SetAssetOwnershipFeedback
  | SetAssetOwnershipReason
  | ClearAssetOwnership;
