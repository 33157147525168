/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Textual representation of the request type.
 */
export type UserRequestType = 'APPLICATION_ACCESS' | 'ASSOCIATION_REQUEST';

export const UserRequestType = {
    APPLICATIONACCESS: 'APPLICATION_ACCESS' as UserRequestType,
    ASSOCIATIONREQUEST: 'ASSOCIATION_REQUEST' as UserRequestType
};