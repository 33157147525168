import {
  Box,
  Dialog,
  Grid,
  IconButton,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Close';
import TextWithToolTip from '../../../../components/text-with-tooltip';
import { Role } from '../../../../entities/user-management-v1/role';
import { INVITATION_APPLICATIONS } from '../../../../services/invitations';
import { AppState, useDispatchTs } from '../../../../store';
import { RoleType } from '../../../../store/invitations/types';
import { getAppRoles, getApplicationsCache } from '../../../../store/metadata/actions';
import SelectCustomers from '../../../../components/select-customers';
import DealerCodeModal from '../dealer-code-modal';
import useStyles from './styles';
import { FormikProps } from 'formik';
import { OrganizationResponse } from '../../../../entities/customer-master-v1/organizationResponse';
import { getAccessRequestType } from '../row-details';
import { UserAccessRequestStatus } from '../../../../entities/user-onboarding-v1/userAccessRequestStatus';
import dayjs from 'dayjs';
import { formatDate } from '../../../../utils/util';
import { nameIdPairArray } from '../../../../globals/generic-types';
import Warning from '../../../../components/warning';
import FilterTagChip from '../../../../components/filter-tag-chip';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import globalStyles from '../../../../globals/styles';
import Loading from '../../../../components/loading';
import InfoIcon from '../../../../assets/icons/info-icon';
import CAT_COLOR from '../../../../globals/color-properties';
import InlineNotification from '../../../../components/inline-notification';

type Values = {
  selectedCustomers: Array<OrganizationResponse>;
  visionLinkRole: string;
  selectedDealerCodes: nameIdPairArray;
};

type AccessApprovalDetailsCardPropertyType = {
  header: string;
  row: any;
  formikProps: FormikProps<Values>;
  companyName: Function;
  companyId: Function;
  isCompanyDataEditable: Function;
  isRestrictedParty: boolean;
  checkApprovedProcessingFailed: boolean;
};

const AccessApprovalDetailsCard = (props: AccessApprovalDetailsCardPropertyType) => {
  const {
    header,
    row,
    formikProps,
    companyName,
    companyId,
    isCompanyDataEditable,
    isRestrictedParty,
    checkApprovedProcessingFailed,
  } = props;
  const { classes } = useStyles();
  const { classes: globalClasses } = globalStyles();
  const {
    metadata: { applicationRoles, applications, isLoadingRole, isLoadingRoleFailed },
  } = useSelector((state: AppState) => state.shared);
  const { filters } = useSelector((state: AppState) => state.accessRequest);
  const theme = useTheme();
  const { t } = useTranslation();

  const [openChangeCompanyModal, setOpenChangeCompanyModal] = useState(false);
  const [openDealerCustomerModal, setOpenDealerCustomerModal] = useState(false);
  const [openViewDealerCustomersModal, setOpenViewCustomersModal] = useState(false);
  const [showNoDataAllert, setShowNoDataAllert] = useState(true);

  const dispatch = useDispatchTs();

  useEffect(() => {
    if (applications.length === 0) {
      dispatch(getApplicationsCache());
    }
  }, []);

  useEffect(() => {
    if (filters?.selectedAccount) {
      if (
        row.status === UserAccessRequestStatus.APPROVED &&
        row?.primaryPartyDetails?.partyNumber
      ) {
        dispatch(getAppRoles(filters?.selectedAccount, row.primaryPartyDetails.partyNumber));
      } else if (
        row.status !== UserAccessRequestStatus.APPROVED &&
        formikProps.values.selectedCustomers[0]?.customerOrganizationDetails
          ?.customerOrganizationIdentifier
      ) {
        dispatch(
          getAppRoles(
            filters?.selectedAccount,
            formikProps.values.selectedCustomers[0]?.customerOrganizationDetails
              ?.customerOrganizationIdentifier ||
              row?.primaryPartyDetails?.partyNumber ||
              '',
          ),
        );
      }
    }
    formikProps.validateForm();
  }, [row?.primaryPartyDetails?.partyNumber, formikProps.values.selectedCustomers]);

  useEffect(() => {
    formikProps.setFieldValue('selectedDealerCodes', []);
  }, [
    formikProps.values.selectedCustomers[0]?.customerOrganizationDetails
      ?.customerOrganizationBusinessName,
  ]);

  const handleSaveCustomers = (customers: Array<any>) => {
    setOpenChangeCompanyModal(false);
    if (customers) {
      formikProps.setFieldValue('selectedCustomers', customers);
      formikProps.setFieldValue('visionLinkRole', '');
    }
  };

  const renderVisionLink = () => {
    const isAccepted = row.status === UserAccessRequestStatus.APPROVED;
    const isRoleExists = applicationRoles?.find(
      (role: Role) => role.roleId === formikProps.values.visionLinkRole,
    )?.roleName as string;
    const isReadOnly = isAccepted && isRoleExists;
    return (
      <Box
        className={
          formikProps.values.selectedCustomers.length > 0 || checkApprovedProcessingFailed
            ? classes.detailsContainer
            : `${classes.detailsContainer}`
        }
      >
        {(isReadOnly || !isAccepted) && (
          <>
            <InputLabel className={classes.requestTypeText}>
              {isAccepted && t(TEXT.COMMON.ROLE)}
              {!isAccepted &&
                !isLoadingRole &&
                !isLoadingRoleFailed &&
                t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.SELECT_ROLE)}{' '}
              {(isAccepted || (!isAccepted && !isLoadingRole && !isLoadingRoleFailed)) && asterisk}
            </InputLabel>
            {isAccepted && formikProps.values.visionLinkRole && (
              <TextWithToolTip
                customDataTestId="access-request-vision-link-role"
                longText={
                  (applicationRoles.find(
                    (role: Role) => role.roleId === formikProps.values.visionLinkRole,
                  )?.roleName as string) || ''
                }
                bold
                fontSize="14px"
              />
            )}
            {!isAccepted && (
              <>
                {formikProps.values.selectedCustomers.length > 0 ? (
                  !isLoadingRole && !isLoadingRoleFailed ? (
                    <Select
                      required
                      labelId="access-request-select-role-input-select"
                      data-testid="access-request-select-role-input-select"
                      className={classes.textField}
                      displayEmpty={true}
                      variant="standard"
                      value={(formikProps.values.visionLinkRole as string) || ''}
                      onChange={(event) =>
                        formikProps.setFieldValue('visionLinkRole', event.target.value)
                      }
                      disabled={formikProps.values.selectedCustomers.length === 0}
                      renderValue={(value: Array<string> | string) => {
                        return typeof value === 'string' && value ? (
                          <TextWithToolTip
                            longText={
                              (applicationRoles?.find((role: Role) => role.roleId === value)
                                ?.roleName as string) || ''
                            }
                            width="80%"
                            maxWidth="90%"
                          ></TextWithToolTip>
                        ) : (
                          <Box className={classes.placeholder}>
                            {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.SELECT_ONE)}
                          </Box>
                        );
                      }}
                    >
                      {applicationRoles
                        .filter(
                          (role: Role) =>
                            !(role.roleName === RoleType.ASSET_SECURITY) &&
                            !(role.roleName === RoleType.NO_ACCESS) &&
                            !(role.roleName === RoleType.SOS_PILOT_PRIVILEGE),
                        )
                        .map((role: Role) => (
                          <MenuItem value={role.roleId} key={role.roleId}>
                            <ListItemText primary={role.roleName} />
                          </MenuItem>
                        ))}
                    </Select>
                  ) : !isLoadingRoleFailed && isLoadingRole ? (
                    <Loading blocks={true} />
                  ) : (
                    showNoDataAllert && (
                      <Box className={classes.alertErrorBox}>
                        <Box>
                          <InfoIcon width="15px" height="15px" iconColor={CAT_COLOR.PERSIAN_RED} />
                        </Box>
                        <Box className={classes.alertErrorBoxText}>
                          <strong>
                            {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.GET_ROLES_ERROR.TITLE)}
                          </strong>
                          <br />
                          {t(TEXT.COMMON.ERRORS.TECHNICAL_DIFFICULTY)}
                        </Box>
                        <Box className={classes.closeIconBox}>
                          <IconButton
                            data-testid="cancel-notification-button"
                            onClick={() => {
                              setShowNoDataAllert(false);
                            }}
                            size="small"
                            color="primary"
                          >
                            <CancelIcon className={classes.closeIcon} />
                          </IconButton>
                        </Box>
                      </Box>
                    )
                  )
                ) : null}
                {formikProps.errors.visionLinkRole && !isLoadingRoleFailed && !isLoadingRole && (
                  <Typography className={classes.requiredFieldMessage}>
                    {formikProps.errors.visionLinkRole as any}
                  </Typography>
                )}
              </>
            )}
          </>
        )}
      </Box>
    );
  };

  const asterisk = (
    <Typography className={classes.validationColor} component="span">
      *
    </Typography>
  );

  const companyNameAndIdPresent =
    companyId(formikProps.values.selectedCustomers) !== '' && companyName() !== '';
  const applicationName = row.application.isMobile
    ? row.application.mobileApplicationName
    : row.application.applicationName;

  return (
    <Box className={classes.cardContainer} data-testid="details-card-container">
      <Typography variant="h5" className={classes.cardHeader} data-testid="details-card-header">
        {header}
      </Typography>
      <Box className={classes.detailsContainer}>
        <Typography className={classes.requestTypeText}>
          {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.COMPANY)} {asterisk}
        </Typography>
        {
          <>
            {formikProps.values.selectedCustomers && companyNameAndIdPresent && (
              <TextWithToolTip
                customDataTestId="access-request-selected-customer"
                longText={companyName()}
                bold
                fontSize="14px"
              ></TextWithToolTip>
            )}
            {formikProps.values.selectedCustomers &&
              !!companyId(formikProps.values.selectedCustomers) &&
              companyNameAndIdPresent && (
                <Box display="flex" flexDirection="row">
                  <Typography className={classes.bold} mb={1} mr={0.5}>
                    {t(TEXT.COMMON.ID)}
                  </Typography>
                  <Typography data-testid="access-request-selected-customer-id">
                    {companyId(formikProps.values.selectedCustomers)}
                  </Typography>
                </Box>
              )}
          </>
        }
        {isCompanyDataEditable() && (
          <>
            <Link
              underline="none"
              className={classes.cardLink}
              mb={0.5}
              data-testid="access-request-change-company-link"
              onClick={() => setOpenChangeCompanyModal(true)}
            >
              {formikProps.values.selectedCustomers.length === 0
                ? t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.ADD_COMPANY)
                : t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.CHANGE_COMPANY)}
            </Link>

            {formikProps.errors.selectedCustomers && (
              <Typography className={classes.requiredFieldMessage}>
                {formikProps.errors.selectedCustomers as any}
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box className={classes.detailsContainer}>
        <Typography>{t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.APPLICATION_NAME)}</Typography>
        <Typography className={classes.bold}>{applicationName}</Typography>
      </Box>
      {getAccessRequestType(row) === INVITATION_APPLICATIONS.VISION_LINK ? (
        isRestrictedParty ? (
          <InlineNotification
            text={t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.RESTRICTED_ACCOUNT_WARNING_MESSAGE)}
            testId="vl-inline-notification"
          />
        ) : (
          renderVisionLink()
        )
      ) : (
        <Box
          className={
            formikProps.values.selectedCustomers.length > 0 || checkApprovedProcessingFailed
              ? classes.detailsContainer
              : `${classes.detailsContainer}`
          }
        >
          <Typography className={classes.requestTypeText}>
            {t(TEXT.MY_CUSTOMERS.ROW_DETAILS.DEALER_CUSTOMERS)} {asterisk}
          </Typography>
          {checkApprovedProcessingFailed ? (
            <Link
              underline="none"
              className={classes.cardLink}
              onClick={() => {
                setOpenViewCustomersModal(true);
              }}
              data-testid="view-dealer-customers-link"
            >
              {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.VIEW_DEALER_CUSTOMERS, {
                count: row.application?.accessIdentifiers?.length,
              })}
            </Link>
          ) : (
            <>
              <Link
                underline="none"
                className={classes.cardLink}
                onClick={() => {
                  companyName() !== '' && setOpenDealerCustomerModal(true);
                }}
              >
                {formikProps.values.selectedDealerCodes?.length > 0
                  ? t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.EDIT_DEALER_CUSTOMERS, {
                      count: formikProps.values?.selectedDealerCodes?.length,
                    })
                  : t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.ADD_DEALER_CUSTOMERS, {
                      count: formikProps.values?.selectedDealerCodes?.length,
                    })}
              </Link>
              {formikProps.errors.selectedDealerCodes && (
                <Typography className={classes.requiredFieldMessage}>
                  {formikProps.errors.selectedDealerCodes as string}
                </Typography>
              )}
            </>
          )}
        </Box>
      )}
      {row.status === UserAccessRequestStatus.DENIED && (
        <>
          <Grid container justifyContent="flex-start" alignItems="center" direction="row">
            <Grid container mt={2}>
              <Grid item xs={6}>
                <InputLabel className={classes.inputLabel}>
                  {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.ACTIONED_BY)}
                </InputLabel>
              </Grid>
              <Grid item xs={5} ml={1}>
                <InputLabel className={classes.inputLabel}>
                  {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.DATE_DENIED)}
                </InputLabel>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" alignItems="center" direction="row">
            <Grid container mt={0.5}>
              <Grid item xs={6}>
                <TextWithToolTip
                  width="100%"
                  customDataTestId="access-request-selected-customer"
                  longText={`${row.updatedByFirstName} ${row.updatedByLastName}`}
                  bold
                  fontSize="14px"
                ></TextWithToolTip>
              </Grid>
              <Grid item xs={5} ml={1}>
                <Typography className={`${classes.bold}`}>{`${dayjs(row.updatedTime).format(
                  'MMM DD, YYYY',
                )}`}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-start" alignItems="left" direction="row" mt={2}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.COMMENTS)}
              </InputLabel>
            </Grid>
            <Grid item xs={12} mt={0.5}>
              <Box className={`${classes.reason} ${classes.bold}`}>
                <em>{row.reason}</em>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      {row.status !== UserAccessRequestStatus.DENIED &&
        row.status !== UserAccessRequestStatus.PENDING &&
        !isRestrictedParty && (
          <Grid container spacing={2}>
            <Grid item xs={6} className={classes.detailsContainer}>
              <Box>
                <InputLabel className={classes.inputLabel}>
                  {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.ACTIONED_BY)}
                </InputLabel>
                <TextWithToolTip
                  customDataTestId="access-request-vision-link-role"
                  longText={`${row.updatedByFirstName || ''} ${row.updatedByLastName || ' '}`}
                  bold
                  maxWidth="100%"
                  fontSize="14px"
                />
              </Box>
            </Grid>
            <Grid item xs={6} className={classes.detailsContainer}>
              <InputLabel className={classes.inputLabel}>
                {t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.DATE_APPROVED)}
              </InputLabel>
              <TextWithToolTip
                customDataTestId="access-request-vision-link-role"
                longText={formatDate(row.updatedTime)}
                bold
                fontSize="14px"
              />
            </Grid>
          </Grid>
        )}
      <Dialog
        open={openChangeCompanyModal}
        fullWidth
        maxWidth="md"
        data-testid="change-company-dialog"
        aria-labelledby="select-customers-dialog"
        PaperProps={{
          className: globalClasses.dynamicDialog,
        }}
      >
        <SelectCustomers
          customTitleText={
            formikProps.values.selectedCustomers.length === 0
              ? t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.ADD_COMPANY)
              : t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.CHANGE_COMPANY)
          }
          handleContinue={handleSaveCustomers}
          selectedCustomers={formikProps.values.selectedCustomers}
          suppressCancelNotification
          limit={1}
          isDialog
          applyText={t(TEXT.COMMON.SAVE)}
          selectedAccount={filters?.selectedAccount}
        />
      </Dialog>
      {openDealerCustomerModal && (
        <DealerCodeModal
          open={openDealerCustomerModal}
          selectedAccount={filters?.selectedAccount}
          setOpen={setOpenDealerCustomerModal}
          selectedDealerCodes={formikProps.values.selectedDealerCodes}
          setSelectedDealerCodes={(values: nameIdPairArray) =>
            formikProps.setFieldValue('selectedDealerCodes', values)
          }
          customerOrganizationIdentifier={
            formikProps.values.selectedCustomers[0]?.customerOrganizationDetails
              ?.customerOrganizationIdentifier ||
            row?.primaryPartyDetails?.partyNumber ||
            ''
          }
        />
      )}
      {openViewDealerCustomersModal && row.application?.accessIdentifiers?.length > 0 && (
        <Warning
          open={openViewDealerCustomersModal}
          setOpen={setOpenViewCustomersModal}
          titleText={t(TEXT.ACCESS_REQUESTS.DETAILS_CARD.VIEW_DEALER_CUSTOMERS_TITLE)}
          messageText={
            <Box className={classes.warningHeight} display="flex">
              {row.application.accessIdentifiers.map((identifier: string, index: number) => (
                <FilterTagChip key={index} text={identifier} maxWidth={theme.spacing(40)} />
              ))}
            </Box>
          }
          primaryText={t(TEXT.COMMON.OK)}
          testPrefix="view-dealer-customers"
          customWidth="500px"
          primaryCallback={() => setOpenViewCustomersModal(false)}
        />
      )}
    </Box>
  );
};

export default AccessApprovalDetailsCard;
