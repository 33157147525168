import { Box, Divider, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { ReactElement, useEffect, useState } from 'react';
import SubHeader from '../../../../components/sub-header';
import { useLocation, useNavigate } from 'react-router-dom';
import AppStepper from '../../../../components/app-stepper';
import useStyles from './styles';
import StepperButton from '../../../../components/stepper-button';
import { useSelector } from 'react-redux';
import { AppState, useDispatchTs } from '../../../../store';
import { addAssetSteps } from '../../../../constants';
import AssetDetails from '../../components/asset-details';
import shortid from 'shortid';
import TextWithToolTip from '../../../../components/text-with-tooltip';
import CustomerOrganization from '../../../../services/customer-organization';
import { showNotification } from '../../../../utils/util';
import { CustomerAddress } from '../../../../entities/customer-master-v1/customerAddress';
import { SearchOrganizationResponse } from '../../../../entities/customer-master-v1/searchOrganizationResponse';
import Loading from '../../../../components/loading';
import { assetManagementActionNames } from '../../../../store/asset-management/types';
import customerInContext from '../../assets.util';
import CAT_COLOR from '../../../../globals/color-properties';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import { DealerCustomerAddress } from '../../../../entities/customer-master-v1/dealerCustomerAddress';

const addressExist = (addressData: Record<string, string | undefined>) =>
  !!Object.keys(addressData || {}).filter((addressKey) => Boolean(addressData[addressKey])).length;

const ReviewAssetResults = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { addAsset, selectedAsset, showNoOwnership, showNoAddAsset } = useSelector(
    (state: AppState) => state.assetManagement,
  );

  const { environmentVariables } = useSelector((state: AppState) => state.login);
  const { classes } = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customerAddress, setCustomerAddress] = useState<Array<CustomerAddress>>();
  const dispatch = useDispatchTs();
  useEffect(() => {
    getCustomerAddress();
  }, []);

  const getCustomerAddress = async () => {
    if (addAsset?.ownershipDetails?.dealerCustomerAssociations?.customerOrganizationIdentifier) {
      try {
        const customerOrganizations = new CustomerOrganization(
          environmentVariables.customerMasterURI,
        );
        const customerResponse =
          await customerOrganizations.getCustomerOrganizationsByCustomerIdentifier({
            customerOrganizationIdentifier:
              addAsset?.ownershipDetails?.dealerCustomerAssociations
                ?.customerOrganizationIdentifier || '',
          });
        setCustomerAddress((customerResponse.data as SearchOrganizationResponse).customerAddresses);
      } catch {
        showNotification(
          dispatch,
          'error',
          t(TEXT.MY_CUSTOMERS.ERRORS.CUSTOMER_ORGANIZATIONS_BY_IDENTIFIER),
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const footerActionButtons = [
    <StepperButton
      buttonText={t(TEXT.COMMON.OK)}
      id="add-asset-review-ok"
      color="primary"
      type="button"
      onClick={() => {
        dispatch({
          type: assetManagementActionNames.CLEAR_SELECTED_ASSET,
        });
        dispatch({
          type: assetManagementActionNames.CLEAR_OWNERSHIP_DETAILS,
        });
        dispatch({
          type: assetManagementActionNames.SET_ASSET_MANAGEMENT_SEARCH_VALUE,
          payload: '',
        });
        if (customerInContext(location)) {
          navigate('/customers/assets');
        } else {
          navigate('/assets');
        }
      }}
    />,
  ];

  const deleteRedundantAddressData = (
    address: DealerCustomerAddress | CustomerAddress | undefined,
  ): Record<string, string | undefined> => ({
    address1: address?.address1,
    address2: address?.address2,
    address3: address?.address3,
    cityName: address?.cityName,
    stateOrProvinceCode: address?.stateOrProvinceCode,
    countryCode: address?.countryCode,
    postalCode: address?.postalCode,
  });

  const getStepContent = () => (
    <Box data-testid="asset-review-container" paddingTop={theme.spacing(8)}>
      <Box display="flex" flexDirection="initial" fontWeight="600">
        {t(TEXT.ASSETS.ADD_ASSET.REVIEW_RESULTS.ASSET_DETAILS)}
      </Box>
      <AssetDetails
        makeModelText={
          `${selectedAsset?.metadata?.makeInfo?.name} | ${selectedAsset?.metadata?.model}` || '--'
        }
        serialNumberText={selectedAsset?.metadata?.serialNumber || '--'}
        productFamilyText={selectedAsset?.metadata?.productFamily?.name || '--'}
        yearText={selectedAsset?.metadata?.modelYear?.toString() || '--'}
        dealerAssetIDText={selectedAsset?.ownership?.baseAssetName || '--'}
      />
      <Box display="flex" flexDirection="initial" fontWeight="600" paddingTop={theme.spacing(5)}>
        {t(TEXT.ASSETS.ADD_ASSET.REVIEW_RESULTS.OWNERSHIP_DETAILS)}
      </Box>

      <Box>
        {showNoOwnership || showNoAddAsset ? (
          <Box display="flex" flexDirection="column" paddingTop={theme.spacing(4)} fontWeight="600">
            {t(TEXT.ASSETS.ADD_ASSET.REVIEW_RESULTS.RECORDS_NOT_CREATED)}
          </Box>
        ) : (
          <Box display="flex" padding={theme.spacing(2)}>
            <Box flex="33%">
              <Box textAlign="left" color={CAT_COLOR.LIGHT_SLATE_GREY}>
                {t(TEXT.MY_CUSTOMERS.TYPE.DEALER_CUSTOMER)}
              </Box>
              <Box textAlign="left" paddingTop={theme.spacing(1)}>
                <TextWithToolTip
                  longText={
                    addAsset?.ownershipDetails?.dealerCustomerDetails?.dealerCustomerBusinessName ||
                    '--'
                  }
                  width="auto"
                  maxWidth="100%"
                  numberOfCharsToDisplay={20}
                  fontSize={14}
                  fontWeight={600}
                />
              </Box>
              <Box display="flex">
                <Box className={classes.label} paddingRight={theme.spacing(0.5)}>
                  ID:
                </Box>
                <Box className={classes.data}>
                  {addAsset?.ownershipDetails?.dealerCustomerDetails?.dealerCustomerIdentifier}
                </Box>
              </Box>
              {addressExist(
                deleteRedundantAddressData(
                  addAsset?.ownershipDetails?.dealerCustomerAddresses?.[0],
                ),
              ) && (
                <Box data-testid="dealer-customer-address" textAlign="left">
                  <Box className={classes.label}>{`${t(TEXT.COMMON.ADDRESS)}:`}</Box>
                  <Box className={classes.data}>
                    {addAsset?.ownershipDetails?.dealerCustomerAddresses?.[0]?.address1}
                  </Box>
                  <Box className={classes.data}>
                    {addAsset?.ownershipDetails?.dealerCustomerAddresses?.[0]?.address2}
                  </Box>
                  <Box className={classes.data}>
                    {addAsset?.ownershipDetails?.dealerCustomerAddresses?.[0]?.address3}
                  </Box>
                  <Box className={classes.data}>
                    {[
                      addAsset?.ownershipDetails?.dealerCustomerAddresses?.[0]?.cityName,
                      addAsset?.ownershipDetails?.dealerCustomerAddresses?.[0]?.stateOrProvinceCode,
                      addAsset?.ownershipDetails?.dealerCustomerAddresses?.[0]?.countryCode,
                      addAsset?.ownershipDetails?.dealerCustomerAddresses?.[0]?.postalCode,
                    ]
                      .filter(Boolean)
                      .join(', ')}
                  </Box>
                </Box>
              )}
            </Box>
            <Box flex="33%">
              <Box textAlign="left" color={CAT_COLOR.LIGHT_SLATE_GREY}>
                {t(TEXT.COMMON.TITLE.CUSTOMER)}
              </Box>
              <Box textAlign="left" paddingTop={theme.spacing(1)}>
                <TextWithToolTip
                  longText={
                    addAsset?.ownershipDetails?.dealerCustomerAssociations
                      ?.customerOrganizationBusinessName || ''
                  }
                  width="auto"
                  maxWidth="100%"
                  fontSize={14}
                  numberOfCharsToDisplay={20}
                  fontWeight={600}
                />
              </Box>
              <Box display="flex">
                <Box className={classes.label} paddingRight={theme.spacing(0.5)}>
                  ID:
                </Box>
                <Box className={classes.data}>
                  {addAsset?.ownershipDetails?.dealerCustomerAssociations
                    ?.customerOrganizationIdentifier || '--'}
                </Box>
              </Box>
              {loading ? (
                <Loading />
              ) : (
                addressExist(deleteRedundantAddressData(customerAddress?.[0])) && (
                  <Box data-testid="customer-address" textAlign="left">
                    <Box className={classes.label}>{`${t(TEXT.COMMON.ADDRESS)}:`}</Box>
                    <Box className={classes.data}>{customerAddress?.[0].address1}</Box>
                    <Box className={classes.data}>{customerAddress?.[0].address2}</Box>
                    <Box className={classes.data}>{customerAddress?.[0]?.address3}</Box>
                    <Box className={classes.data}>
                      {[
                        customerAddress?.[0]?.cityName,
                        customerAddress?.[0]?.stateOrProvinceCode,
                        customerAddress?.[0]?.countryCode,
                        customerAddress?.[0]?.postalCode,
                      ]
                        .filter(Boolean)
                        .join(', ')}
                    </Box>
                  </Box>
                )
              )}
            </Box>
            <Box flex="33%">
              <Box textAlign="left" color={CAT_COLOR.LIGHT_SLATE_GREY}>
                {t(TEXT.ASSET_RECOMMENDATION.COLUMNS.OWNERSHIP_TYPE)}
              </Box>
              <Box textAlign="left" paddingTop={theme.spacing(.75)} className={classes.label}>
                {addAsset?.ownershipDetails?.ownershipType || '--'}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
  return (
    <>
      <SubHeader
        title={'Add Asset'}
        titleText={t(addAssetSteps[2])}
        data-testid="add-asset-review-page"
        actionComponents={[
          <IconButton
            onClick={() => {
              dispatch({
                type: assetManagementActionNames.CLEAR_SELECTED_ASSET,
              });
              dispatch({
                type: assetManagementActionNames.CLEAR_OWNERSHIP_DETAILS,
              });
              dispatch({
                type: assetManagementActionNames.CLEAR_EXISTING_RECORD,
              });
              navigate('/assets');
            }}
            data-testid="add-asset-review-page-close-icon"
            size="large"
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      <Divider className={classes.divider} />
      <Box className={classes.appStepper}>
        <AppStepper
          steps={addAssetSteps}
          getStepContent={getStepContent}
          activeStep={2}
          blocks={environmentVariables.blocksComponents}
        />
      </Box>
      <Box
        bottom={0}
        right={0}
        width="100%"
        height={theme.spacing(10)}
        mr={2}
        pb={2}
        className={classes.footerActions}
        position="fixed"
      >
        <Divider className={classes.divider} />
        <Box display="flex" justifyContent="flex-end" mt={2} mb={1} width="100%">
          {footerActionButtons.map((component: ReactElement) => (
            <Box key={shortid.generate()} mr={2}>
              {component}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};
export default ReviewAssetResults;
