/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Value determines the status update.
 */
export type RecommendationFeedback = 'ACCEPTED' | 'REJECTED' | 'UNKNOWN';

export const RecommendationFeedback = {
    ACCEPTED: 'ACCEPTED' as RecommendationFeedback,
    REJECTED: 'REJECTED' as RecommendationFeedback,
    UNKNOWN: 'UNKNOWN' as RecommendationFeedback
};