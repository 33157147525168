/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Status of the customer organization.
 */
export type CustomerStatus = 'ACTIVE' | 'INACTIVE' | 'OBSOLETE' | 'DRAFT';

export const CustomerStatus = {
    ACTIVE: 'ACTIVE' as CustomerStatus,
    INACTIVE: 'INACTIVE' as CustomerStatus,
    OBSOLETE: 'OBSOLETE' as CustomerStatus,
    DRAFT: 'DRAFT' as CustomerStatus
};