import React, { ComponentType, useState } from 'react';

import { Box, Divider, IconButton, Typography, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import InfiniteLoaderWrapper from '../infinite-loader-wrapper';
import useStyles from './styles';
import { ListChildComponentProps } from 'react-window';
import { DealerCustomerResponse } from '../../services/customer-organization';
import { SearchOrganizationResponse } from '../../entities/customer-master-v1/searchOrganizationResponse';
import CustomerAddressDisplay from '../customer-address';
import { CustomerAddress } from '../../entities/customer-master-v1/customerAddress';
import { CustomerOrgAddressType } from '../../entities/customer-master-v1/customerOrgAddressType';
import { DealerCustomerOrgAddressType } from '../../entities/customer-master-v1/dealerCustomerOrgAddressType';
import { DealerCustomerAddress } from '../../entities/customer-master-v1/dealerCustomerAddress';
import TextWithToolTip from '../text-with-tooltip';
import Loading from '../loading';
import AccountTypes from '../account-types';
import { UsersSearch } from '../../entities/user-management-v1/usersSearch';
import { Trans, useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';

export enum ShowHideTableType {
  USERS = 'users',
  DEALERCUSTOMERS = 'dealerCustomers',
  CUSTOMERS = 'customers',
}
type ShowHideTableProp = {
  headerTitle: string;
  count: number;
  data: Array<DealerCustomerResponse> | Array<SearchOrganizationResponse> | Array<UsersSearch>;
  getData: Function;
  emptyMessage: string;
  loading: boolean;
  countLoading?: boolean;
  hideBottomBorder?: boolean | undefined;
  id?: string | undefined;
  type?: ShowHideTableType;
  hasNextPage?: boolean; //override next page based on count
  tableHeight?: number | undefined;
};
const addressInfo = (
  addresses: Array<CustomerAddress> | Array<DealerCustomerAddress> | undefined,
) => {
  if (addresses && addresses.length > 0) {
    let mainIndex = 0;
    /* eslint-disable unicorn/no-array-for-each */
    addresses.forEach((address: CustomerAddress | DealerCustomerAddress, index: number) => {
      if (
        address.addressType === CustomerOrgAddressType.PRIMARY ||
        address.addressType === DealerCustomerOrgAddressType.MAINOFFICE
      )
        mainIndex = index;
    });
    return addresses[mainIndex];
  }
  return {};
};
const ShowHideTable = (props: ShowHideTableProp) => {
  const {
    headerTitle,
    count,
    countLoading,
    data,
    type,
    getData,
    emptyMessage,
    hideBottomBorder,
    loading,
    id,
    hasNextPage,
    tableHeight = 200,
  } = props;
  const { t } = useTranslation();
  const testid = id ? `-${id}` : '';
  const { classes } = useStyles();
  const theme = useTheme();
  const [expandCustomer, setExpandCustomer] = useState(false);
  const fieldpicker = (index: number) => {
    if (data.length > index) {
      if ((data[index] as DealerCustomerResponse)?.dealerCustomerDetails) {
        return {
          ...(data[index] as DealerCustomerResponse).dealerCustomerDetails,
          name: (data[index] as DealerCustomerResponse).dealerCustomerDetails
            .dealerCustomerBusinessName,
          id: (data[index] as DealerCustomerResponse).dealerCustomerDetails
            .dealerCustomerIdentifier,
          address: addressInfo((data[index] as DealerCustomerResponse)?.dealerCustomerAddresses),
        };
      } else if ((data[index] as SearchOrganizationResponse).customerOrganizationDetails) {
        return {
          ...(data[index] as SearchOrganizationResponse).customerOrganizationDetails,
          name: (data[index] as SearchOrganizationResponse).customerOrganizationDetails
            ?.customerOrganizationBusinessName,
          id: (data[index] as SearchOrganizationResponse).customerOrganizationDetails
            ?.customerOrganizationIdentifier,
          address: addressInfo((data[index] as SearchOrganizationResponse)?.customerAddresses),
        };
      } else if (type === ShowHideTableType.USERS) {
        return {
          name:
            (data as Array<UsersSearch>)[index].firstName +
            ' ' +
            (data as Array<UsersSearch>)[index].lastName,
          email: (data as Array<UsersSearch>)[index].email,
          username: (data as Array<UsersSearch>)[index].username,
        };
      }
    }
    return undefined;
  };

  const getSearchResults = () => {
    return type !== ShowHideTableType.USERS ? (
      <Box className={classes.tableContainer} data-testid={`show-hide-table-container${testid}`}>
        <Box className={classes.row}>
          <Box className={`${classes.headerCell} ${classes.width3col}`}>
            {t(TEXT.COMMON.TITLE.NAME)}
          </Box>
          <Box className={`${classes.headerCell} ${classes.width3col}`}>{t(TEXT.COMMON.ID)}</Box>
          <Box className={`${classes.headerCell} ${classes.width3col}`}>
            {t(TEXT.COMMON.ADDRESS)}
          </Box>
        </Box>
        <InfiniteLoaderWrapper
          hasNextPage={hasNextPage !== undefined ? hasNextPage : data.length < count}
          isNextPageLoading={loading}
          items={data}
          loadNextPage={() => {
            return getData();
          }}
          ItemComponent={ItemComponent}
          height={tableHeight || ''}
          width="100%"
          itemSize={60}
        />
      </Box>
    ) : (
      <Box className={classes.tableContainer} data-testid={`show-hide-table-container${testid}`}>
        <Box className={classes.row}>
          <Box className={`${classes.headerCell} ${classes.width2col}`}>
            {t(TEXT.COMMON.TITLE.NAME)}
          </Box>
          <Box className={`${classes.headerCell} ${classes.width2col}`}>
            {t(TEXT.COMMON.TITLE.EMAIL_ADDRESS)}
          </Box>
        </Box>
        <InfiniteLoaderWrapper
          hasNextPage={hasNextPage !== undefined ? hasNextPage : data.length < count}
          isNextPageLoading={loading}
          items={data}
          loadNextPage={() => {
            return getData();
          }}
          ItemComponent={UserItemComponent}
          height={tableHeight || ''}
          width="100%"
          itemSize={60}
        />
      </Box>
    );
  };

  const UserItemComponent: ComponentType<ListChildComponentProps<any>> = (
    propsUserItem: ListChildComponentProps<any>,
  ) => {
    const { index, style } = propsUserItem;
    const row: any = fieldpicker(index);
    if (row) {
      return (
        <Box className={classes.row} style={style}>
          <Box className={`${classes.headerCell} ${classes.width2col}`}>
            <Box>
              <TextWithToolTip
                longText={row.name}
                fontSize={12}
                width="auto"
                maxWidth="100%"
                fontWeight={400}
              ></TextWithToolTip>
              <AccountTypes size={theme.spacing(2)} marginLeft={2} row={{ ...row }} />
            </Box>
            <Box>
              <Trans
                i18nKey={TEXT.COMMON.CWS_ID_WITH_TOOLTIP}
                components={[
                  <Typography
                    fontSize={10}
                    component="span"
                    className={`${classes.floatLeft} ${classes.username}`}
                  />,
                  <TextWithToolTip longText={row.username} fontSize={10} fontWeight={400} />,
                ]}
              />
            </Box>
          </Box>
          <Box className={`${classes.headerCell} ${classes.width2col}`}>
            <TextWithToolTip
              longText={row.email}
              fontSize={12}
              width="100%"
              fontWeight={400}
            ></TextWithToolTip>
          </Box>
        </Box>
      );
    }

    return <Box></Box>;
  };
  const ItemComponent: ComponentType<ListChildComponentProps<any>> = (
    propsItem: ListChildComponentProps<any>,
  ) => {
    const { index, style } = propsItem;
    const row: any = fieldpicker(index);
    if (row) {
      return (
        <Box className={classes.row} style={style}>
          <Box className={`${classes.cell} ${classes.width3col}`}>
            <TextWithToolTip
              marginTop={theme.spacing(0.125)}
              longText={row.name}
              fontSize={12}
              width="auto"
              fontWeight={400}
            ></TextWithToolTip>
            <AccountTypes size={theme.spacing(1.75)} marginLeft={4} row={{ ...row }} />
          </Box>
          <Box className={`${classes.cell} ${classes.width3col}`}>
            <TextWithToolTip
              longText={row.id}
              fontSize={12}
              width="100%"
              fontWeight={400}
            ></TextWithToolTip>
          </Box>
          <Box className={`${classes.cell} ${classes.width3col}`}>
            <CustomerAddressDisplay
              address1={row.address.address1}
              cityName={row.address.cityName}
              stateOrProvinceCode={row.address.stateOrProvinceCode}
              countryCode={row.address.countryCode}
              postalCode={row.address.postalCode}
              fontSize="12px"
              fontWeight={400}
              className={classes.address}
              showFullAddress={true}
              width="225px"
            ></CustomerAddressDisplay>
          </Box>
        </Box>
      );
    }

    return <Box></Box>;
  };
  return (
    <Box>
      <Box
        className={expandCustomer ? classes.tableOpenTitle : ''}
        data-testid={`show-hide-header${testid}`}
      >
        <Divider className={classes.tableDivider}></Divider>
        <Box className={classes.arrowPadding}>
          <IconButton
            onClick={() => setExpandCustomer(!expandCustomer)}
            data-testid="show-hide-table-expand"
          >
            {!expandCustomer ? (
              <ExpandMore data-testid={`show-hide-expand-more${testid}`}></ExpandMore>
            ) : (
              <ExpandLess data-testid={`show-hide-expand-less${testid}`}></ExpandLess>
            )}
          </IconButton>
          {headerTitle} (
          {countLoading && <Loading height={18} width={30} display={'inline-block'}></Loading>}
          {!countLoading && count})
        </Box>
        {!hideBottomBorder && <Divider className={classes.tableDivider}></Divider>}
      </Box>
      {expandCustomer && count > 0 && getSearchResults()}
      {expandCustomer && count === 0 && (
        <Box className={classes.empty} data-testid={`show-hide-empty-container${testid}`}>
          {emptyMessage}
        </Box>
      )}
    </Box>
  );
};

export default ShowHideTable;
