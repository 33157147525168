import React from 'react';
import { CatLoadingIndicator as LoadingIndicator } from 'blocks-react/bedrock/components/LoadingIndicator';

type LoadingProperties = {
    small?: boolean;
};

const CatLoadingIndicator = (props: LoadingProperties) => {
    const { small } = props;
    return <LoadingIndicator data-testid='cat-loading' small={small} />;
};

export default CatLoadingIndicator;
