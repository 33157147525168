import { Box, Button } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';

type FilterButtonsProp = {
  formikProps: FormikProps<any>;
  handleChange: Function;
  buttonValues: Array<{
    code: string;
    title: string;
  }>;
  defaultValue?: string;
  disabled?: boolean;
};

const FilterButtons = (props: FilterButtonsProp) => {
  const { classes } = useStyles();
  const { handleChange, buttonValues, defaultValue, disabled } = props;
  const [selectedButton, setSelectedButton] = useState<string>(defaultValue ? defaultValue : '');

  const handleButtonChange = (value: string) => {
    const newValue = selectedButton === value ? '' : value;
    setSelectedButton(newValue);
    handleChange(newValue);
  };

  useEffect(() => {
    setSelectedButton(defaultValue || '');
  }, [defaultValue]);
  return (
    <Box>
      <Box data-testid="filter-buttons" mt={1}>
        {buttonValues.map((value, index) => {
          return (
            <Button
              key={index}
              className={
                selectedButton === value.code ? classes.dateRangeActiveBtn : classes.dateRangeBtn
              }
              variant="contained"
              color="secondary"
              size="small"
              disabled={disabled}
              data-testid={value.title}
              onClick={() => {
                handleButtonChange(value.code);
              }}
            >
              {value.title}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default FilterButtons;
