import {
  accessRequestActionNames,
  AccessRequestActionTypes,
  AccessRequestFilters,
  AccessRequestState,
} from './types';

const initialState: AccessRequestState = {
  filters: {
    sortByRules: ['createdTime'],
    orderByRules: ['DESC'],
    presetValue: '',
    manualSelectedAccountEntry: false,
    selectedAccount: '',
    selectedCustomers: [],
    application: [],
    applicationRequestStatus: [],
    startDate: undefined,
    endDate: undefined,
    selectedTimeRangeButton: undefined,
  },
  cursorsForAccessRequest: {
    nextCursors: [],
    currentPage: undefined,
  },
  accessRequestSearchValue: '',
  isLoadingAccessRequests: false,
  accessRequests: [],
  totalAccessRequestCount: 0,
  isLoadingAccessRequestCount: false,
};

const accessRequestReducer = (
  state: AccessRequestState = initialState,
  action: AccessRequestActionTypes,
): AccessRequestState => {
  switch (action.type) {
    case accessRequestActionNames.SET_ACCESS_REQUEST: {
      return {
        ...state,
        accessRequests: action.payload,
      };
    }
    case accessRequestActionNames.SET_ACCESS_REQUEST_SEARCH_VALUE: {
      return {
        ...state,
        accessRequestSearchValue: action.payload as string,
      };
    }
    case accessRequestActionNames.SET_ACCESS_REQUEST_FILTERS: {
      return {
        ...state,
        filters: action.payload as AccessRequestFilters,
      };
    }
    case accessRequestActionNames.SET_CURSOR_FOR_ACCESS_REQUEST: {
      const nextCursors = [...(state.cursorsForAccessRequest?.nextCursors || [])];
      if (action.payload.currentPage >= state.cursorsForAccessRequest?.nextCursors.length) {
        nextCursors.push(action.payload.cursor);
      }
      return {
        ...state,
        cursorsForAccessRequest: {
          nextCursors,
          currentPage: action.payload.currentPage,
        },
      };
    }
    case accessRequestActionNames.RESET_CURSOR_FOR_ACCESS_REQUEST: {
      return {
        ...state,
        cursorsForAccessRequest: { currentPage: 0, nextCursors: [] },
      };
    }
    case accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST: {
      return {
        ...state,
        isLoadingAccessRequests: action.payload,
      };
    }
    case accessRequestActionNames.SET_ACCESS_REQUEST_TOTAL_COUNT: {
      return {
        ...state,
        totalAccessRequestCount: action.payload,
      };
    }
    case accessRequestActionNames.SET_IS_LOADING_ACCESS_REQUEST_COUNT: {
      return {
        ...state,
        isLoadingAccessRequestCount: action.payload,
      };
    }
    case accessRequestActionNames.CLEAR_ACCESS_REQUEST_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
      };
    }
    case accessRequestActionNames.CLEAR_SPECIFIC_ACCESS_REQUEST_FILTER: {
      const newValue = initialState?.filters?.[action.payload as keyof AccessRequestFilters];
      if (newValue !== undefined) {
        return {
          ...state,
          filters: {
            ...state.filters,
            [action.payload as keyof AccessRequestFilters]: newValue,
          },
        } as AccessRequestState;
      }
      return state;
    }
    case accessRequestActionNames.SET_ACCESS_REQUEST_SORT_BY_VALUE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          sortByRules: action.payload,
        },
      } as AccessRequestState;
    }
    case accessRequestActionNames.SET_ACCESS_REQUEST_ORDER_BY_VALUE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          orderByRules: action.payload,
        },
      } as AccessRequestState;
    }
    case accessRequestActionNames.SET_INITIAL_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default accessRequestReducer;
