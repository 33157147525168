import {
  OrganizationRequest,
  OrganizationResponse,
  SearchDealerCustomerResponse,
  SearchOrganizationResponse,
} from '../../entities/customer-master-v1/models';
import { UsersSearch } from '../../entities/user-management-v1/usersSearch';
import { SetIsLoading } from '../api/loadable';
import { ClearDuplicateCustomers, SetDuplicateCustomers } from '../customer-organization/types';

export enum addCustomerActionNames {
  CLEAR_ASSOCIATED_DEALER_CUSTOMERS = 'CLEAR_ASSOCIATED_DEALER_CUSTOMERS',
  CLEAR_ASSOCIATED_USERS = 'CLEAR_ASSOCIATED_USERS',
  CLEAR_CREATE_CUSTOMER = 'CLEAR_CREATE_CUSTOMER',
  CLEAR_DUPLICATE_CUSTOMERS = 'CLEAR_DUPLICATE_CUSTOMERS',
  CLEAR_IMPORTED_DEALER_CUSTOMER = 'CLEAR_IMPORTED_DEALER_CUSTOMER',
  CLEAR_SELECTED_CUSTOMER = 'CLEAR_SELECTED_CUSTOMER',
  SET_ASSOCIATED_DEALER_CUSTOMERS = 'SET_ASSOCIATED_DEALER_CUSTOMERS',
  SET_DISASSOCIATED_DEALER_CUSTOMERS = 'SET_DISASSOCIATED_DEALER_CUSTOMERS',
  SET_ASSOCIATED_USERS = 'SET_ASSOCIATED_USERS',
  SET_CREATE_CUSTOMER = 'SET_CREATE_CUSTOMER',
  SET_CUSTOMER_INFO_ENTERED_FLAG = 'SET_CUSTOMER_INFO_ENTERED_FLAG',
  SET_DUPLICATE_CUSTOMERS = 'SET_DUPLICATE_CUSTOMERS',
  SET_IMPORTED_DEALER_CUSTOMER = 'SET_IMPORTED_DEALER_CUSTOMER',
  SET_IS_LOADING_FOR_DUPLICATE_ENTRIES = 'SET_IS_LOADING_FOR_DUPLICATE_ENTRIES',
  SET_LOCKED_DCN_FLAG = 'SET_LOCKED_DCN_FLAG',
  SET_NEXT_CURSOR = 'SET_NEXT_CURSOR',
  SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER',
  SET_TOTAL_COUNT = 'SET_TOTAL_COUNT',
  CREATE_NEW_DEALER_FROM_IMPORT = 'CREATE_NEW_DEALER_FROM_IMPORT',
}

export type CursorType = {
  cursors: Array<string>;
};
export type SearchDealerCustomerResponseWithFailures = SearchDealerCustomerResponse & {
  failed?: string;
};
export type DissociateDealerCustomerResponseWithFailures = SearchDealerCustomerResponse & {
  associatedDcnsLength?: number;
  disassociateDealerCustomersFailed?: any;
  dcnsToDisassociateManual?: any;
};

export interface AddCustomerState {
  associatedDcns?: Array<SearchDealerCustomerResponseWithFailures>;
  disassociatedDcns?: DissociateDealerCustomerResponseWithFailures;
  associatedUsers?: Array<UsersSearch>;
  createCustomer?: OrganizationRequest;
  customerInfoEntered?: boolean;
  duplicateCustomers: Array<SearchOrganizationResponse>;
  importedDealerCustomer?: SearchDealerCustomerResponse;
  isLoadingForDuplicateEntries?: boolean;
  lockedDcnFlag?: boolean;
  nextCursor?: string;
  selectedCustomer?: OrganizationResponse;
  totalDuplicateCount?: number;
  createNewDealerFromImport?: boolean;
}

interface SetCreateCustomer {
  type: typeof addCustomerActionNames.SET_CREATE_CUSTOMER;
  payload: OrganizationRequest;
}

interface ClearCreateCustomer {
  type: typeof addCustomerActionNames.CLEAR_CREATE_CUSTOMER;
}

interface SetSelectedCustomer {
  type: typeof addCustomerActionNames.SET_SELECTED_CUSTOMER;
  payload: OrganizationResponse;
}

interface ClearSelectedCustomer {
  type: typeof addCustomerActionNames.CLEAR_SELECTED_CUSTOMER;
}

interface SetAlreadyAddedDealerCustomers {
  type: typeof addCustomerActionNames.SET_IMPORTED_DEALER_CUSTOMER;
  payload: SearchDealerCustomerResponse;
}
interface SetLockedDcnFlag {
  type: typeof addCustomerActionNames.SET_LOCKED_DCN_FLAG;
  payload: boolean;
}

interface ClearAlreadyAddedDealerCustomers {
  type: typeof addCustomerActionNames.CLEAR_IMPORTED_DEALER_CUSTOMER;
  payload: SearchDealerCustomerResponse;
}

interface SetAssociatedDelaerCustomers {
  type: typeof addCustomerActionNames.SET_ASSOCIATED_DEALER_CUSTOMERS;
  payload: Array<SearchDealerCustomerResponse>;
}

interface SetDisasociatedDelaerCustomers {
  type: typeof addCustomerActionNames.SET_DISASSOCIATED_DEALER_CUSTOMERS;
  payload: DissociateDealerCustomerResponseWithFailures;
}

interface ClearAssociatedDealerCustomers {
  type: typeof addCustomerActionNames.CLEAR_ASSOCIATED_DEALER_CUSTOMERS;
}

interface SetAssociatedUsers {
  type: typeof addCustomerActionNames.SET_ASSOCIATED_USERS;
  payload: Array<UsersSearch>;
}

interface ClearAssociatedUsers {
  type: typeof addCustomerActionNames.CLEAR_ASSOCIATED_USERS;
}

interface SetCustomerInfoEnteredFlag {
  type: typeof addCustomerActionNames.SET_CUSTOMER_INFO_ENTERED_FLAG;
  payload: boolean;
}
interface SetNextCursor {
  type: typeof addCustomerActionNames.SET_NEXT_CURSOR;
  payload: string;
}

interface SetTotalCount {
  type: typeof addCustomerActionNames.SET_TOTAL_COUNT;
  payload: number;
}

interface SetCreateDealerFromImport {
  type: typeof addCustomerActionNames.CREATE_NEW_DEALER_FROM_IMPORT;
  payload: boolean;
}

export type AddCustomerActionTypes =
  | ClearAlreadyAddedDealerCustomers
  | ClearAssociatedDealerCustomers
  | ClearAssociatedUsers
  | ClearCreateCustomer
  | ClearDuplicateCustomers<addCustomerActionNames.CLEAR_DUPLICATE_CUSTOMERS>
  | ClearSelectedCustomer
  | SetAlreadyAddedDealerCustomers
  | SetAssociatedDelaerCustomers
  | SetDisasociatedDelaerCustomers
  | SetAssociatedUsers
  | SetCreateCustomer
  | SetCustomerInfoEnteredFlag
  | SetDuplicateCustomers<addCustomerActionNames.SET_DUPLICATE_CUSTOMERS>
  | SetIsLoading<addCustomerActionNames.SET_IS_LOADING_FOR_DUPLICATE_ENTRIES>
  | SetLockedDcnFlag
  | SetNextCursor
  | SetSelectedCustomer
  | SetTotalCount
  | SetCreateDealerFromImport;
