/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerOrganizationIdentifier } from './customerOrganizationIdentifier';
import { DealerCustomerIdentifier } from './dealerCustomerIdentifier';
import { FeedbackReason } from './feedbackReason';
import { RecommendationFeedback } from './recommendationFeedback';

/**
 * Object used in request to associate dealer customer to a customer organization.
 */
export interface RecommendedDealerCustomerDetails { 
    /**
     * The recommendation relationship type represents the various types of recommendation. The field is required when request type supports various types of items. 
     */
    recommendationType?: RecommendedDealerCustomerDetails.RecommendationTypeEnum;
    dealerCustomerIdentifier: DealerCustomerIdentifier;
    customerOrganizationIdentifier: CustomerOrganizationIdentifier;
    feedbackStatus: RecommendationFeedback;
    feedbackReason?: FeedbackReason;
}
export namespace RecommendedDealerCustomerDetails {
    export type RecommendationTypeEnum = 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEDCNTOEXISTINGCATASSOCIATION: 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum
    };
}