import React, { ReactElement } from 'react';
import { CatToggle as Toggle } from 'blocks-react/bedrock/components/Toggle';
import { CatHeading } from 'blocks-react/bedrock/components/Heading';
import { BlocksToggleChangeEvent } from 'blocks-web-components/dist/bedrock/events';

interface ToggleSwitchProperties {
  label?: string | ReactElement;
  disabled?: boolean;
  toggleSwitchCallback: Function;
  checked: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom' | undefined;
}

const CatToggle = (properties: ToggleSwitchProperties) => {
  const { label, toggleSwitchCallback, disabled, checked, labelPlacement } = properties;

  const handleToggleChange = (event: BlocksToggleChangeEvent) => {
    toggleSwitchCallback(event.detail.checked);
  };

  const placementLabel = {
    start: 'row',
    end: 'row-reverse',
    top: 'column',
    bottom: 'column-reverse',
  };

  const toggleContainer: any = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: labelPlacement ? placementLabel[labelPlacement] || 'row-reverse' : 'row-reverse',
    justifyContent: 'space-between',
  };

  const toggleLabel: React.CSSProperties = {
    pointerEvents: disabled ? 'none' : 'auto',
    opacity: disabled ? 0.4 : 1,
  };

  const catToggleOuter: React.CSSProperties = {
    padding: '6px',
  };

  return (
    <div style={toggleContainer}>
      {label && (
        <CatHeading variant="footnote-bold" style={toggleLabel}>
          {label}
        </CatHeading>
      )}
      <Toggle
        data-testid="cat-toggle-switch-value"
        hideLabel={true}
        onBlToggleChange={handleToggleChange}
        checked={checked}
        disabled={disabled}
        style={catToggleOuter}
      />
    </div>
  );
};

export default CatToggle;
