import { SearchOrganizationResponse } from '../../entities/customer-master-v1/searchOrganizationResponse';

export enum accessManagementActionNames {
  SET_ACCESS_MANAGEMENT = 'SET_ACCESS_MANAGEMENT',
  SET_ACCESS_MANAGEMENT_INITIAL_STATE = 'SET_ACCESS_MANAGEMENT_INITIAL_STATE',
  SET_ACCESS_MANAGEMENT_FILTERS = 'SET_ACCESS_MANAGEMENT_FILTERS',
  CLEAR_ACCESS_MANAGEMENT_FILTERS = 'CLEAR_ACCESS_MANAGEMENT_FILTERS',
  SET_ACCESS_MANAGEMENT_SEARCH_VALUE = 'SET_ACCESS_MANAGEMENT_SEARCH_VALUE',
  SET_CURSOR_FOR_ACCESS_MANAGEMENT = 'SET_CURSOR_FOR_ACCESS_MANAGEMENT',
  SET_IS_LOADING_ACCESS_MANAGEMENT_DATA = 'SET_IS_LOADING_ACCESS_MANAGEMENT_DATA',
  REMOVE_FILTER = 'REMOVE_FILTER',
  SET_IS_ACCESS_MANAGEMENT_SERVER_ERROR = 'SET_IS_ACCESS_MANAGEMENT_SERVER_ERROR',
}

export const accessManagementPermissionName = 'users';
export const accessManagementPermissionMethods = {
  update: 'update',
  search: 'search',
};

export type AccessManagementFilters = {
  selectedAccount: string;
  manualSelectedAccountEntry: boolean;
  selectedCustomers: Array<SearchOrganizationResponse>;
};

export interface AccessManagementState {
  filters: AccessManagementFilters;
  accessManagementData: any[];
  isLoadingAccessManagement: boolean;
  accessManagementSearchValue: string;
  isAccessManagementServerError: boolean;
}

export interface SetAccessManagementInitialState {
  type: typeof accessManagementActionNames.SET_ACCESS_MANAGEMENT_INITIAL_STATE;
}
export interface SetIsAccessManagementLoading {
  type: typeof accessManagementActionNames.SET_IS_LOADING_ACCESS_MANAGEMENT_DATA;
  payload: boolean;
}
export interface SetIsAccessFailedSearch {
  type: typeof accessManagementActionNames.SET_IS_ACCESS_MANAGEMENT_SERVER_ERROR;
  payload: boolean;
}
export interface SetAccessManagement {
  type: typeof accessManagementActionNames.SET_ACCESS_MANAGEMENT;
  payload: Array<any>;
}
export interface SetAccessManagementSearchValue {
  type: typeof accessManagementActionNames.SET_ACCESS_MANAGEMENT_SEARCH_VALUE;
  payload: string;
}

export interface SetAccessManagementFilters {
  type: typeof accessManagementActionNames.SET_ACCESS_MANAGEMENT_FILTERS;
  payload: AccessManagementFilters;
}
export interface ClearAccessManagementFilters {
  type: typeof accessManagementActionNames.CLEAR_ACCESS_MANAGEMENT_FILTERS;
}
export interface removeAccessManagementFilter {
  type: typeof accessManagementActionNames.REMOVE_FILTER;
  payload: AccessManagementFilters[keyof AccessManagementFilters];
}
export type accessManagementActionTypes =
  | SetAccessManagementInitialState
  | SetIsAccessManagementLoading
  | SetAccessManagement
  | SetAccessManagementSearchValue
  | SetAccessManagementFilters
  | ClearAccessManagementFilters
  | removeAccessManagementFilter
  | SetIsAccessFailedSearch;
