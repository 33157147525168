import React, { memo } from 'react';
import { Chip } from '@mui/material';
import CatBadge from '../cat-badge';

type ChipProperties = {
  backgroundColor: string;
  foreColor: string;
  label: string;
  id: string;
  fontSize: string;
  fontWeight?: string;
  height?: string;
  verticalAlign?: string;
  minWidth?: string;
  blocks?: boolean;
  variant?:
    | 'data-primary'
    | 'data-secondary'
    | 'data-tertiary'
    | 'data-quaternary'
    | 'blue'
    | 'green'
    | 'grey'
    | 'yellow'
    | 'orange'
    | 'red'
    | 'black'
    | 'white';
  isDot?: boolean;
  position?: 'top-left' | 'top-right';
};

const ChipComponent = (props: ChipProperties) => {
  const {
    backgroundColor,
    label,
    foreColor,
    id,
    fontSize,
    fontWeight,
    height,
    verticalAlign,
    minWidth,
    blocks,
    variant,
    isDot,
    position,
  } = props;

  return blocks ? (
    <div style={{ padding: '2px' }}>
      <CatBadge id={id} label={label} variant={variant} isDot={isDot} position={position} />
    </div>
  ) : (
    <Chip
      id={id}
      data-testid="chip"
      size="small"
      label={label}
      style={{
        backgroundColor: backgroundColor,
        color: foreColor,
        marginBottom: 4,
        marginLeft: 4,
        fontSize: fontSize,
        height: height,
        verticalAlign: verticalAlign,
        fontWeight: fontWeight,
        minWidth,
      }}
    />
  );
};

export default memo(ChipComponent);
