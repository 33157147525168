import { RecommendationState } from '../recommendations/types';
import { customerRecommendationsActionNames, CustomerRecommendationsActionTypes } from './types';

const recommendationsInitialState: RecommendationState = {
  searchInputForRecommendations: '',
  itemsForSelectedRecommendationGroupId: [],
  cursorsForRecommendations: {
    nextCursors: [],
    currentPage: undefined,
  },
  selectedRecommendation: undefined,
  duplicateCustomers: [],
  sortBy: 'initiatedTime',
  orderBy: 'DESC',
  staleData: false,
};

const customerRecommendationsReducer = (
  state: RecommendationState = recommendationsInitialState,
  action: CustomerRecommendationsActionTypes,
): RecommendationState => {
  switch (action.type) {
    case customerRecommendationsActionNames.CUSTOMER_SET_IS_LOADING_RECOMMENDATIONS: {
      return {
        ...state,
        isLoadingRecommendations: action.payload,
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_RESET_CURSORS_FOR_RECOMMENDATIONS: {
      return {
        ...state,
        cursorsForRecommendations: { currentPage: 0, nextCursors: [] },
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_CLEAR_RECOMMENDATION_ITEMS: {
      return {
        ...state,
        itemsForSelectedRecommendationGroupId:
          recommendationsInitialState.itemsForSelectedRecommendationGroupId,
        cursorForItems: recommendationsInitialState.cursorForItems,
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_SET_SELECTED_RECOMMENDATION: {
      return {
        ...state,
        selectedRecommendation: action.payload,
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_SET_ITEMS_FOR_SELECTED_RECOMMENDATION_GROUP_ID: {
      return {
        ...state,
        itemsForSelectedRecommendationGroupId: [
          ...(state.itemsForSelectedRecommendationGroupId || []),
          ...action.payload,
        ],
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_SET_CURSORS_FOR_ITEMS: {
      return {
        ...state,
        cursorForItems: action.payload,
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_SET_COUNT_FOR_ITEMS: {
      return {
        ...state,
        totalItemsCount: action.payload,
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_SET_UPDATED_CUSTOMER_NAME: {
      return {
        ...state,
        selectedRecommendation: {
          ...state.selectedRecommendation,
          updatedCustomerName: action.payload,
        },
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_SET_ASSOCIATION_FEEDBACK_REQUEST: {
      return {
        ...state,
        associationFeedback: { ...state.associationFeedback, request: action.payload },
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_SET_ASSOCIATION_FEEDBACK_RESPONSE: {
      return {
        ...state,
        associationFeedback: { ...state.associationFeedback, response: action.payload },
      };
    }
    case customerRecommendationsActionNames.CUSTOMER_SET_PRIMARY_CUSTOMER_DETAILS_FOR_RECOMMENDATIONS: {
      return {
        ...state,
        primaryCustomerOrganizationDetails: action.payload,
      };
    }
    case customerRecommendationsActionNames.CLEAR_ASSOCIATIONS_FEEDBACK: {
      return {
        ...state,
        associationFeedback: undefined,
      };
    }
    default:
      return state;
  }
};
export default customerRecommendationsReducer;
