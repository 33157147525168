import React, { ChangeEvent, MouseEventHandler } from 'react';
import { Radio, styled } from '@mui/material';
import CAT_COLOR from '../../globals/color-properties';
type radioButtonType = {
  value: number | string | boolean;
  disabled?: boolean;
  testId?: string;
  size?: number;
  checked?: boolean;
  onClick?: MouseEventHandler;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

const RadioButton = (props: radioButtonType) => {
  const { size = 16, testId, ...controlledProps } = props;
  const CatThemeIcon = styled('span')(() => ({
    borderRadius: '50%',
    width: size,
    height: size,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: CAT_COLOR.WHITE_LILAC,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: CAT_COLOR.PORCELAIN,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  }));

  const CheckedIcon = styled(CatThemeIcon)({
    backgroundColor: CAT_COLOR.BRIGHT_GOLD,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: size,
      height: size,
      backgroundImage: 'radial-gradient(#000,#000 40%,transparent 56%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: CAT_COLOR.SUPERNOVA,
    },
  });
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<CheckedIcon />}
      icon={<CatThemeIcon />}
      data-testid={testId}
      {...controlledProps}
    />
  );
};

export default RadioButton;
