import React from 'react';
import { Box } from '@mui/material';
import useStyles from './styles';
import ShowMore from '../show-more';
export type HeaderGridItem = {
  label: string;
  text?: string;
  html?: any;
};
type HeaderGridProperties = {
  items: Array<HeaderGridItem>;
  width?: string;
  columns?: number;
};
const HeaderGrid = (properties: HeaderGridProperties) => {
  const { columns, items, width } = properties;
  const { classes } = useStyles();
  return (
    <Box className={classes.flexBox} width={width || '50%'}>
      {items.map((item: HeaderGridItem, index: number) => (
        <Box
          className={classes.flexItem}
          key={`header-node-${index}`}
          data-testid={`header-node-${index}`}
          style={{ flexBasis: `${100 / (columns || 6)}%` }}
        >
          <Box
            className={classes.label}
            key={`header-label-${index}`}
            data-testid={`header-label-${index}`}
          >
            {item.label}
          </Box>
          <Box
            className={classes.data}
            key={`header-text-${index}`}
            data-testid={`header-text-${index}`}
          >
            {item?.text ? (
              <ShowMore bodyText={item.text} dataTestId={`show-more-${index}`}></ShowMore>
            ) : (
              item.html
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default HeaderGrid;
