import React from 'react';
import { Box } from '@mui/material';
import useStyle from './styles';
import { CatToast } from 'blocks-react/bedrock/components/Toast/Toast';
import { CatIconInfo } from 'blocks-react/bedrock/components/Icons/Info';

type InlineToastType = {
  variant?: 'success' | 'warning' | 'error';
  testId?: string;
  header?: string;
  text?: string;
  width?: string;
};
const InlineToast = (props: InlineToastType) => {
  const { variant, testId = 'inline-toast', header, text, width } = props;
  const { classes } = useStyle();

  return (
    <CatToast autoClose={false} variant={variant || 'error'} data-testid="cat-toast-block">
      <Box
        className={classes.container}
        data-testid={`${testId}-body`}
        width={width ? width : '256px'}
      >
        <Box component="span" className={classes.iconBlock} data-testid={`${testId}-icon`}>
          <CatIconInfo color={variant}></CatIconInfo>
        </Box>
        <Box
          component="span"
          className={classes.textBlock}
          data-testid={`${testId}-text-container`}
        >
          {header && (
            <Box className={classes.header} data-testid={`${testId}-header`}>
              {header}
            </Box>
          )}
          {text && (
            <Box className={classes.text} data-testid={`${testId}-text`}>
              {text}
            </Box>
          )}
        </Box>
      </Box>
    </CatToast>
  );
};
export default InlineToast;
