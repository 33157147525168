/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The user category.
 */
export type UserCategory = 'APP_ENROLLED' | 'WORKS_FOR';

export const UserCategory = {
    APPENROLLED: 'APP_ENROLLED' as UserCategory,
    WORKSFOR: 'WORKS_FOR' as UserCategory
};