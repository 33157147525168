import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../globals/color-properties';

export default makeStyles()((theme: any) => ({
  appbar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
  },
  menuButton: {
    marginLeft: theme.spacing(0.1),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  outerContainer: {
    height: theme.spacing(8),
    zIndex: theme.zIndex.drawer + 1,
  },
  container: {
    height: theme.spacing(8),
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    background: CAT_COLOR.BLACK,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: `0 0px`,
    ...theme.mixins.toolbar,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 8,
    paddingLeft: 8,
  },
  logo: {
    width: '100%',
    height: 'auto',
    maxHeight: theme.spacing(25) + 4,
    maxWidth: theme.spacing(25) + 4,
  },
  userInfo: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: CAT_COLOR.BLACK,
    },
  },
  clickedUserInfo: {
    backgroundColor: theme.palette.secondary.main,
    color: CAT_COLOR.BLACK,
  },
  avatar: {
    marginLeft: theme.spacing(2),
  },
  userContainer: {
    height: theme.spacing(10),
  },
  boxPointer: {
    cursor: 'pointer',
  },
}));
