import React, { useRef, useState, useEffect } from 'react';
import { Typography, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import useStyles from './styles';
import CAT_COLOR from '../../globals/color-properties';

type textWithToolTipProperties = {
  bold?: boolean;
  underline?: boolean;
  fontSize?: string | number | undefined;
  fontWeight?: number | undefined;
  longText: string;
  customDataTestId?: string;
  maxWidth?: string | undefined;
  width?: string | undefined;
  height?: string | undefined;
  marginBottom?: string | undefined;
  marginTop?: string | undefined;
  marginLeft?: string | undefined;
  color?: string | undefined;
  lineHeight?: string | undefined;
  numberOfCharsToDisplay?: number;
  whiteSpaceNormal?: boolean | undefined;
};

const LightTooltip = withStyles(Tooltip, (theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[10],
    maxWidth: 'none',
  },
  arrow: { color: theme.palette.common.white },
}));

const TextWithToolTip = (props: textWithToolTipProperties) => {
  const { classes } = useStyles();
  const textElementRef = useRef<HTMLElement>(null);
  const {
    longText,
    customDataTestId,
    bold = false,
    width,
    height,
    fontSize = 11,
    maxWidth = '366px',
    marginBottom = 0,
    marginTop = 0,
    marginLeft,
    color = CAT_COLOR.BLACK,
    fontWeight,
    lineHeight,
    numberOfCharsToDisplay,
    underline,
    whiteSpaceNormal,
  } = props;
  const [hoverStatus, setHover] = useState(false);
  const [truncatedText, setTruncatedText] = useState('');

  const compareSize = () => {
    if (textElementRef.current !== null) {
      const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
      setHover(compare);
    }
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, [textElementRef, longText]);

  useEffect(() => {
    if (numberOfCharsToDisplay) {
      if (longText?.length > numberOfCharsToDisplay) {
        setTruncatedText(`${longText.slice(0, numberOfCharsToDisplay)}...`);
        setHover(true);
      } else {
        setTruncatedText(longText);
        setHover(false);
      }
    }
  }, [longText]);

  return (
    <LightTooltip
      ref={textElementRef}
      title={longText}
      arrow
      placement="top-start"
      disableHoverListener={!hoverStatus}
    >
      <Typography
        data-testid={customDataTestId || 'dealer-customer-tooltip-text'}
        component="span"
        className={clsx(classes.text, {
          [classes.bold]: bold,
          [classes.underline]: underline,
          [classes.whiteSpaceNormal]: whiteSpaceNormal,
        })}
        style={{
          width,
          height,
          fontSize,
          maxWidth,
          marginBottom,
          marginTop,
          marginLeft,
          color,
          lineHeight,
          fontWeight,
        }}
      >
        {numberOfCharsToDisplay && numberOfCharsToDisplay > 0 ? truncatedText : longText}
      </Typography>
    </LightTooltip>
  );
};

export default TextWithToolTip;
