import { makeStyles } from 'tss-react/mui';
import CAT_COLOR from '../../../../globals/color-properties';

export default makeStyles()((theme) => ({
  cardContainer: {
    width: '350px',
    border: `1px solid ${CAT_COLOR.MEDIUM_GRAY}`,
    background: CAT_COLOR.WHITE,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(3, 4, 6, 4),
    '& p, span': {
      marginBottom: theme.spacing(0.5),
    },
  },
  cardHeader: {
    marginBottom: theme.spacing(3),
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
  detailsContainer: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  cardLink: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '159%',
  },
  inputLabel: {
    fontSize: '12px',
    color: CAT_COLOR.LYNCH,
    marginBottom: theme.spacing(0.5),
  },
  textField: {
    width: '100%',
    '& div': {
      fontSize: 14,
      textAlign: 'left',
    },
  },
  placeholder: {
    fontStyle: 'italic',
    color: CAT_COLOR.LIGHT_SLATE_GREY,
    fontSize: '14px',
  },
  asterisk: {
    color: CAT_COLOR.PERSIAN_RED,
  },
  requiredFieldMessage: {
    color: CAT_COLOR.PIGMENT_RED,
    fontSize: '12px',
    marginTop: theme.spacing(1),
  },
  reason: {
    wordWrap: 'break-word',
  },
  warningHeight: {
    height: '241px',
  },
  validationColor: {
    color: CAT_COLOR.PIGMENT_RED,
  },
  validationText: {
    fontSize: '11px',
    fontWeight: 400,
  },
  requestTypeText: {
    fontSize: '12px',
    fontWeight: 400,
  },
  alertErrorBox: {
    display: 'flex',
    backgroundColor: CAT_COLOR.SOFT_PEACH,
    border: `1px solid ${CAT_COLOR.PERSIAN_RED}`,
    borderLeft: `4px solid ${CAT_COLOR.PERSIAN_RED}`,
    padding: theme.spacing(1.875),
    paddingRight: theme.spacing(0.375),
    paddingLeft: theme.spacing(1),
  },
  alertErrorBoxText: {
    fontSize: '14px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.125),
    lineHeight: '18px',
  },
  closeIcon: {
    fontSize: '18px',
  },
  closeIconBox: {
    marginTop: theme.spacing(-0.875),
  },
}));
