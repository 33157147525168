/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AuditLogRecommendationAssociateDcnToExistingCatAssociationItem } from './auditLogRecommendationAssociateDcnToExistingCatAssociationItem';

/**
 * Audit log details for ASSOCIATE DCN TO EXISTING CAT ASSOCIATION recommendation type. 
 */
export interface AuditLogRecommendationAssociateDcnToExistingCatAssociation { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType: AuditLogRecommendationAssociateDcnToExistingCatAssociation.RecommendationTypeEnum;
    recommendedAssociations: Array<AuditLogRecommendationAssociateDcnToExistingCatAssociationItem>;
}
export namespace AuditLogRecommendationAssociateDcnToExistingCatAssociation {
    export type RecommendationTypeEnum = 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEDCNTOEXISTINGCATASSOCIATION: 'ASSOCIATE DCN TO EXISTING CAT ASSOCIATION' as RecommendationTypeEnum
    };
}