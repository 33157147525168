/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { TimeRange } from './timeRange';

/**
 * Allows to filter date values within a certain range. See range models for more info about boundary inclusion rules. 
 */
export interface AccessRequestTimeRangeFilter { 
    /**
     * Enum that defines all possible time range filter types.
     */
    type: AccessRequestTimeRangeFilter.TypeEnum;
    /**
     * User request properties that can be used for time range filtering. 
     */
    propertyName: AccessRequestTimeRangeFilter.PropertyNameEnum;
    range: TimeRange;
}
export namespace AccessRequestTimeRangeFilter {
    export type TypeEnum = 'timeRange';
    export const TypeEnum = {
        TimeRange: 'timeRange' as TypeEnum
    };
    export type PropertyNameEnum = 'approvedRequests' | 'deniedRequests' | 'failedRequests' | 'pendingRequests' | 'processingRequests';
    export const PropertyNameEnum = {
        ApprovedRequests: 'approvedRequests' as PropertyNameEnum,
        DeniedRequests: 'deniedRequests' as PropertyNameEnum,
        FailedRequests: 'failedRequests' as PropertyNameEnum,
        PendingRequests: 'pendingRequests' as PropertyNameEnum,
        ProcessingRequests: 'processingRequests' as PropertyNameEnum
    };
}