export enum SecondaryFilterType {
  hasAssocation = 'hasAssocation',
  managedAccountIndicator = 'managedAccountIndicator',
  countryCode = 'countryCode',
  stateOrProvinceCode = 'stateOrProvinceCode',
  dealerCode = 'dealerCode',
  hasAssociatedDcn = 'hasAssociatedDcn',
  updateTime = 'updateTime',
  status = 'status',
  selectedTimeRangeButton = 'selectedTimeRangeButton',
}

export type UserInputFilters = {
  dealerCode?: string[];
  status?: string[];
  country?: string;
  stateOrProvince?: string;
  hasAssociation?: boolean;
  startDate?: Date;
  endDate?: Date;
  hasAssociationToMyDealerCodes?: boolean;
};
export type FiltersProperties = {
  onClickOfApply: Function;
  disabled?: boolean;
  selectedFilters: boolean;
  clearFilters: Function;
  clearAllFilters: Function;
  userSelectedFilters?: UserInputFilters;
};
