/* eslint-disable unicorn/no-invalid-remove-event-listener */
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Box, CssBaseline, styled } from '@mui/material';
import { Provider, useDispatch } from 'react-redux';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoTwoTone from '@mui/icons-material/InfoTwoTone';
import configureStore from './store';
import theme from './globals/theme';
import './application.css';
import AuthLayer from './globals/auth-layer';
import SnackbarNotifier from './components/snackbar-notifier';
import InitialLoading from './components/initial-loading';
import UnsavedChangesConfirmation from './globals/unsaved-changes-confirmation';
import RoutesComponent from './globals/routes';
import setUpInterceptor from './globals/axios/request-interceptor';
import Warning from './components/warning';
import { sessionExpiredText } from './constants';
import CAT_COLOR from './globals/color-properties';

const store = configureStore();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch();

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#E4F6E9 !important',
    color: '#000 !important',
    border: '1px solid #61CA7F',
    boxSizing: 'border-box',
    borderRadius: 5,
    paddingTop: 0,
    paddingBottom: 0,
    height: theme.spacing(6),
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#F5D0D1 !important',
    color: '#000 !important',
    border: '1px solid #ED1C24',
    boxSizing: 'border-box',
    borderRadius: 5,
    paddingTop: 0,
    paddingBottom: 0,
    height: theme.spacing(6),
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#FBE1CE !important',
    color: '#000 !important',
    border: '1px solid #127ACA',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: theme.spacing(6),
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#E6F4FF !important',
    color: '#000 !important',
    border: '1px solid #2595FF',
    boxSizing: 'border-box',
    borderRadius: 5,
    height: theme.spacing(6),
    flexWrap: 'unset !important' as 'nowrap',
  },
}));

const App = () => {
  const [sessionExpired, setSessionExpired] = useState(false);
  useEffect(() => {
    document.body.style.backgroundColor = 'white';
    window.addEventListener('beforeunload', () => {
      const isSessionRefreshed = window.sessionStorage.getItem('is-refreshed');
      if (!isSessionRefreshed || isSessionRefreshed === 'false') {
        window.sessionStorage.setItem('is-refreshed', 'true');
      }
    });
    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  }, []);
  const logoutUser = () => {
    window.localStorage.removeItem('saved-path');
    window.location.href = store.getState().login.environmentVariables.logoutURI;
  };

  setUpInterceptor(
    store,
    setSessionExpired,
    store.getState().login.environmentVariables.refreshSession,
  );

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthLayer>
            <CssBaseline>
              <SnackbarProvider
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                  warning: StyledMaterialDesignContent,
                  info: StyledMaterialDesignContent,
                }}
                preventDuplicate
                maxSnack={3}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                iconVariant={{
                  success: (
                    <Box mr={1}>
                      <CheckCircleIcon
                        fontSize="small"
                        style={{ color: CAT_COLOR.MEDIUM_SEA_GREEN }}
                      />
                    </Box>
                  ),
                  error: (
                    <Box mr={1}>
                      <InfoTwoTone fontSize="small" style={{ color: CAT_COLOR.PIGMENT_RED }} />
                    </Box>
                  ),
                  warning: (
                    <Box mr={1}>
                      <InfoTwoTone fontSize="small" style={{ color: CAT_COLOR.PIGMENT_RED }} />
                    </Box>
                  ),
                  info: (
                    <Box mr={1}>
                      <InfoTwoTone fontSize="small" style={{ color: CAT_COLOR.AZURE }} />
                    </Box>
                  ),
                }}
              >
                <BrowserRouter>
                  <Suspense fallback={<InitialLoading />}>
                    <RoutesComponent />
                    <UnsavedChangesConfirmation />
                  </Suspense>
                </BrowserRouter>
                <Warning
                  open={sessionExpired}
                  setOpen={setSessionExpired}
                  titleText="Session Expired"
                  messageText={sessionExpiredText}
                  primaryText="Refresh"
                  secondaryText="Sign Out"
                  primaryCallback={() => {
                    window.location.reload();
                  }}
                  secondaryCallback={() => logoutUser()}
                />
                <SnackbarNotifier />
              </SnackbarProvider>
            </CssBaseline>
          </AuthLayer>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};

export default App;
