import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

type RelatedActionProps = {
  children?: ReactNode;
  index: any;
  value: any;
};

const RelatedAction: FC<RelatedActionProps> = (properties) => {
  const { children, value, index } = properties;
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box marginRight={3} marginTop={1}>
          {children}
        </Box>
      )}
    </div>
  );
};

export { RelatedAction };  export type { RelatedActionProps };

