import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import useStyles from './styles';
import TEXT from '../../../../globals/translation-map';
import { OwnershipRequestDetails } from '../../../../entities/customerAssetWorkflow-v1/ownershipRequestDetails';
import { CatContextualMenu } from 'blocks-react/bedrock/components/ContextualMenu';
import { CatButton } from 'blocks-react/bedrock/components/Button';
import { CatIconMenuDots } from 'blocks-react/bedrock/components/Icons/MenuDots';
import { CatListItem } from 'blocks-react/bedrock/components/ListItem';
import Warning from '../../../../components/warning';
import { deleteOwnershipRequest } from '../../../../store/ownership-requests/actions';
import { useDispatchTs } from '../../../../store';

const MoreOptions = (properties: {
  row: OwnershipRequestDetails;
  setDeleteOwnershipRequestSuccessful: Function;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatchTs();
  const { classes } = useStyles();
  const { row, setDeleteOwnershipRequestSuccessful } = properties;
  const [cancelOwnershipRequestsWarning, setCancelOwnershipRequestsWarning] = useState(false);

  const handleCancelOwnershipRequests = () => {
    !!row?.ownershipRequestIdentifier &&
      dispatch(
        deleteOwnershipRequest(row?.ownershipRequestIdentifier, setDeleteOwnershipRequestSuccessful),
      );
  };

  return (
    <Box display="flex" flexDirection="row-reverse" mr={4}>
      <CatContextualMenu position="bottom-end" data-testId="cat-contextual-menu">
        <React.Fragment key=".0">
          <CatButton hideText slot="trigger" variant="bare" data-testId="cat-more-options-icon-btn">
            Menu
            <CatIconMenuDots slot="after" data-testId="cat-more-options-icon" />
          </CatButton>
          <CatListItem
            className={classes.textRed}
            onclick={(event: any) => {
              setCancelOwnershipRequestsWarning(true);
            }}
            data-testid="cancel-ownership-reqest"
          >
            {t(TEXT.OWNERSHIP_REQUESTS.CANCEL_REQUESTS.BUTTON)}
          </CatListItem>
        </React.Fragment>
      </CatContextualMenu>

      <Warning
        open={cancelOwnershipRequestsWarning}
        setOpen={setCancelOwnershipRequestsWarning}
        messageText={
          <Box height={'100px'}>
            {t(TEXT.OWNERSHIP_REQUESTS.CANCEL_REQUESTS.WARNING_MODAL_TEXT)}
          </Box>
        }
        primaryCallback={() => handleCancelOwnershipRequests()}
        secondaryCallback={() => setCancelOwnershipRequestsWarning(false)}
        titleText={t(TEXT.COMMON.WARNING)}
        primaryText={t(TEXT.OWNERSHIP_REQUESTS.CANCEL_REQUESTS.WARNING_CONFIRM_BUTTON)}
        secondaryText={t(TEXT.COMMON.CANCEL)}
        maxWidth="xs"
      />
    </Box>
  );
};

export default MoreOptions;
