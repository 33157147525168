/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * The `partyType` defines the type of party the role is applicable. 
 */
export type PartyType = 'CAT' | 'CUST' | 'DLR';

export const PartyType = {
    CAT: 'CAT' as PartyType,
    CUST: 'CUST' as PartyType,
    DLR: 'DLR' as PartyType
};