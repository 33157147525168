/**
 * User Onboarding
 * User Onboarding API provides functionality to manage users access, requests and invites. 
 *
 * OpenAPI spec version: 1.0.0
 * Contact: Platform_Core_Services_-_CAT@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Textual representation of the party details request type.
 */
export type PartyDetailsType = 'USER_ENTERED' | 'SELECTED_MATCH' | 'EXACT_MATCH';

export const PartyDetailsType = {
    USERENTERED: 'USER_ENTERED' as PartyDetailsType,
    SELECTEDMATCH: 'SELECTED_MATCH' as PartyDetailsType,
    EXACTMATCH: 'EXACT_MATCH' as PartyDetailsType
};