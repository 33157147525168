import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { AppState, useDispatchTs } from '../../store';
import { closeSnackbarAction } from '../../store/snackbar/actions';

const ActionButtons = (key: SnackbarKey) => {
  const dispatch = useDispatchTs();
  const { closeSnackbar } = useSnackbar();
  const dismissSnackbar = () => {
    closeSnackbar(key);
    dispatch(closeSnackbarAction(key));
  };
  return (
    <IconButton data-testid="snackbar-close-icon" onClick={() => dismissSnackbar()} size="small">
      <Close />
    </IconButton>
  );
};

type SnackbarNotifierType = {
  actionButtons?: SnackbarAction;
};

const SnackbarNotifier = (props: SnackbarNotifierType) => {
  const { actionButtons } = props;
  const dispatch = useDispatchTs();
  const notifications = useSelector((state: AppState) => state.shared.snackbar.notifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    /* eslint-disable unicorn/no-array-for-each */
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      enqueueSnackbar(message, {
        key,
        ...options,
        action: options.action || actionButtons || ActionButtons,
        onExited: (event, keyNotification) => {
          dispatch(closeSnackbarAction(keyNotification));
        },
      });
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch, actionButtons]);

  return null;
};

export default SnackbarNotifier;
