/**
 * Customer Master
 * Customer Master service APIs is used to create/update/search/delete/associate customer organizations, create/update/search dealer customer organizations, associate/disassociate dealer customers to/from customer organization, view/update/delete recommendations, search dealer codes, audit logs. 
 *
 * OpenAPI spec version: 1.36.1
 * Contact: CatDigitalSupport@cat.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AssociatedDealerCustomersCount } from './associatedDealerCustomersCount';
import { CustomerAddress } from './customerAddress';
import { RecommendedCustomerOrganizationDetails } from './recommendedCustomerOrganizationDetails';

/**
 * Response with details of the customer organizations for recommendation type AssociateMultipleCatAssociation. 
 */
export interface AssociateMultipleCatAssociationResponseArrayItem { 
    /**
     * The recommendation relationship type represents the various types of recommendation.
     */
    recommendationType?: AssociateMultipleCatAssociationResponseArrayItem.RecommendationTypeEnum;
    customerOrganizationDetails?: RecommendedCustomerOrganizationDetails;
    customerOrganizationAddresses?: Array<CustomerAddress>;
    associatedDealerCustomersCount?: AssociatedDealerCustomersCount;
}
export namespace AssociateMultipleCatAssociationResponseArrayItem {
    export type RecommendationTypeEnum = 'ASSOCIATE MULTIPLE CAT ASSOCIATION';
    export const RecommendationTypeEnum = {
        ASSOCIATEMULTIPLECATASSOCIATION: 'ASSOCIATE MULTIPLE CAT ASSOCIATION' as RecommendationTypeEnum
    };
}