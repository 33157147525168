/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Link } from '@mui/material';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import TEXT from '../../globals/translation-map';

interface ShowMoreProps {
  expandedHtml?: any;
  bodyText?: string;
  showMoreText?: string;
  dataTestId?: string;
}

const ShowMore = ({
  bodyText,
  expandedHtml,
  dataTestId = 'show-more-link',
  showMoreText = TEXT.COMMON.SEE_MORE,
}: ShowMoreProps) => {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [hasMoreText, setHasMoreText] = useState(false);
  const textRef = useRef() as any;
  const { t } = useTranslation();

  useEffect(() => {
    if (
      bodyText &&
      textRef.current &&
      textRef.current.offsetWidth !== undefined &&
      textRef.current.scrollWidth !== undefined
    ) {
      setHasMoreText(
        textRef.current.offsetWidth < textRef.current.scrollWidth ||
          textRef.current.offsetHeight < textRef.current.scrollHeight,
      );
    }
  }, [bodyText, textRef]);

  return (
    <>
      {expandedHtml && expanded && expandedHtml}
      {bodyText && (
        <Box className={`${classes.text} ${expanded ? '' : classes.truncated}`} ref={textRef}>
          {bodyText}
        </Box>
      )}
      {(expandedHtml || hasMoreText || expanded) && (
        <Box className={classes.block}>
          <Link
            underline="none"
            variant="subtitle1"
            data-testid={dataTestId}
            onClick={() => setExpanded(!expanded)}
          >
            {!expanded ? !!showMoreText && t(showMoreText) : t(TEXT.COMMON.SEE_LESS)}
          </Link>
        </Box>
      )}
    </>
  );
};

export default ShowMore;
