import React from 'react';
import { SvgIcon } from '@mui/material';
import { iconType } from './icon-types';
import CAT_COLOR from '../../globals/color-properties';

const InfoIcon = ({
  marginTop = '0px',
  iconColor = CAT_COLOR.LIGHT_SLATE_GREY,
  height = '19px',
  ...restProps
}: iconType) => (
  <SvgIcon
    {...restProps}
    viewBox="0 0 30 30"
    style={{
      stroke: restProps.variant === 'error' ? '#ED1C24' : iconColor,
      fill: restProps.variant === 'error' ? '#ED1C24' : iconColor,
      height: height,
      width: restProps.width,
      marginTop: marginTop,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '1.5',
    }}
  >
    <path
      d="M15 1C7.26675 1 1 7.2685 1 15C1 22.7315 7.26675 29 15 29C22.7315 29 29 22.7315 29 15C29 7.2685 22.7315 1 15 1V1Z"
      fill="none"
    />
    <path
      d="M15 11C16.104 11 17 10.328 17 9.5C17 8.672 16.104 8 15 8C13.896 8 13 8.672 13 9.5C13 10.328 13.896 11 15 11V11Z"
      stroke="none"
    />
    <rect x="13" y="13" width="4" height="11" rx="2" stroke="none" />
  </SvgIcon>
);

export default InfoIcon;
