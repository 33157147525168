import { default as Axios, AxiosResponse, CancelToken } from 'axios';
import {
  SearchOrganizationResponse,
  OrganizationResponse,
  Filters,
  ResponseMetadata,
  ErrorResponse,
  Count,
  OrganizationRequest,
  DealerCode,
  CustomerEmail,
  CustomerPhone,
  DealerCustomerAddress,
  OrganizationConsolidationRequest,
  AssociateCustomerOrganizationDetails,
  DealerCustomer,
  InlineResponse20013,
} from '../entities/customer-master-v1/models';

class CustomerOrganization {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  countByCustomerOrganization(
    input: CustomerOrganizationCountRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<CustomerOrganizationCountResponse | ErrorResponse>> {
    const { body, ...queryParameters } = input;
    return Axios.post<CustomerOrganizationCountResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/COUNT`,
      body,
      {
        cancelToken,
        params: queryParameters,
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    );
  }

  searchByCustomerOrganization(
    input: CustomerOrganizationSearchRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<CustomerOrganizationSearchResponse | ErrorResponse>> {
    const { body, ...queryParameters } = input;
    return Axios.post<CustomerOrganizationSearchResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/search`,
      body,
      {
        cancelToken,
        params: queryParameters,
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    );
  }

  searchMangedCustomers(
    input: ManagedCustomerOrganizationSearchRequest,
  ): Promise<AxiosResponse<InlineResponse20013 | ErrorResponse>> {
    const { body, ...queryParameters } = input;

    return Axios.post<InlineResponse20013 | ErrorResponse>(
      `${this.baseUrl}/managedCustomers/search?sortBy=managedCustomerBusinessName`,
      body || {},
      {
        params: queryParameters,
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    );
  }

  consolidateOrganizations(
    input: OrganizationConsolidationRequest,
  ): Promise<AxiosResponse<OrganizationConsolidationRequest | ErrorResponse>> {
    return Axios.post<OrganizationConsolidationRequest | ErrorResponse>(
      `${this.baseUrl}/organizations/consolidate`,
      input,
      {},
    );
  }

  getAssociatedOrganizationsByCustomerIdentifier(
    input: AssociatedCustomerOrganizationsRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<CustomerOrganizationSearchResponse | ErrorResponse>> {
    const { customerOrganizationIdentifier, ...queryParameters } = input;

    return Axios.get<CustomerOrganizationSearchResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/${customerOrganizationIdentifier}/associatedOrganizations`,
      {
        cancelToken,
        params: queryParameters,
      },
    );
  }

  getAssociatedDealerCustomersByCustomerIdentifier(
    input: AssociatedCustomerOrganizationsRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<OrganizationDealerCustomersResponse | ErrorResponse>> {
    const { customerOrganizationIdentifier, ...queryParameters } = input;

    return Axios.get<OrganizationDealerCustomersResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/${customerOrganizationIdentifier}/dealerCustomers`,
      {
        cancelToken,
        params: queryParameters,
      },
    );
  }

  getCountForAssociatedDealerCustomersByCustomerIdentifier(
    input: AssociatedCustomerOrganizationsRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<CustomerOrganizationCountResponse | ErrorResponse>> {
    const { customerOrganizationIdentifier, ...queryParameters } = input;

    return Axios.get<CustomerOrganizationCountResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/${customerOrganizationIdentifier}/dealerCustomers/COUNT`,
      {
        cancelToken,
        params: queryParameters,
      },
    );
  }

  getDealerCustomersIDsByCustomerIdentifier(
    input: DealerCustomersByCustomerIdentifierRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<any | ErrorResponse>> {
    const { customerOrganizationIdentifier, ...queryParameters } = input;
    return Axios.get<CustomerOrganizationCountResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/${customerOrganizationIdentifier}/dealerCustomerIdentifiers`,
      {
        cancelToken,
        params: {
          cwsId: queryParameters.cwsId,
          dealerCode: queryParameters?.dealerCode,
        },
      },
    );
  }

  getCustomerOrganizationsByCustomerIdentifier(
    input: AssociatedCustomerOrganizationsByCustomerIdentifierRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<SearchOrganizationResponse | ErrorResponse>> {
    const { customerOrganizationIdentifier } = input;
    return Axios.get<SearchOrganizationResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/${customerOrganizationIdentifier}`,
      {
        cancelToken,
      },
    );
  }

  getCountForAssociatedOrganizationsByCustomerIdentifier(
    input: AssociatedCustomerOrganizationsRequest,
    cancelToken?: CancelToken,
  ): Promise<AxiosResponse<CustomerOrganizationCountResponse | ErrorResponse>> {
    const { customerOrganizationIdentifier, ...queryParameters } = input;
    return Axios.get<CustomerOrganizationCountResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/${customerOrganizationIdentifier}/associatedOrganizations/COUNT`,
      {
        cancelToken,
        params: queryParameters,
      },
    );
  }

  addCustomerOrganization(
    input: CreateCustomerOrganizationRequest,
  ): Promise<AxiosResponse<OrganizationResponse | ErrorResponse>> {
    const { body, ...queryParameters } = input;
    return Axios.post<OrganizationResponse | ErrorResponse>(`${this.baseUrl}/organizations`, body, {
      params: queryParameters,
    });
  }

  editCustomerOrganization(
    customerOrganizationIdentifier: string,
    input: OrganizationRequest,
  ): Promise<AxiosResponse<OrganizationResponse | ErrorResponse>> {
    const { ...queryParameters } = input;
    return Axios.put<OrganizationResponse | ErrorResponse>(
      `${this.baseUrl}/organizations/${customerOrganizationIdentifier}`,
      queryParameters,
      {},
    );
  }

  setOrganizationsStatus(orgId: string, status: string): Promise<AxiosResponse> {
    return Axios.patch(
      `${this.baseUrl}/organizations/${orgId}/status`,
      {
        status: status,
      },
      {
        headers: {
          'Cache-Control': 'no-cache',
        },
      },
    );
  }

  associateCustomerOrganization(
    input: AssociateCustomerOrganizationDetails,
  ): Promise<AxiosResponse<{ description: string } | ErrorResponse>> {
    const { ...body } = input;

    return Axios.post<{ description: string } | ErrorResponse>(
      `${this.baseUrl}/organizations/associate`,
      body,
      {},
    );
  }
}

export type CustomerOrganizationSearchRequest = {
  body?: Filters;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
  sortBy?: Array<string> | string;
  orderBy?: Array<string> | string;
};
export type ManagedCustomerOrganizationSearchRequest = {
  body?: Filters;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
  sortBy?: Array<string> | string;
  orderBy?: Array<string> | string;
};

export type CustomerOrganizationCountRequest = {
  body?: Filters | {};
  xCatUserPreferences?: string;
};

export type CustomerOrganizationCountResponse = {
  count: Count;
};

export type CustomerOrganizationSearchResponse = {
  customerOrganizations: Array<SearchOrganizationResponse>;
  responseMetadata?: ResponseMetadata;
};

export type CustomerOrganizationAddRequest = {
  body?: OrganizationRequest;
  xCatUserPreferences?: string;
};

export type CreateCustomerOrganizationRequest = {
  body: OrganizationRequest;
  xCatUserPreferences?: string;
  dealerCode?: DealerCode;
};

export type AssociatedCustomerOrganizationsRequest = {
  customerOrganizationIdentifier: string;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
};

export type DealerCustomersByCustomerIdentifierRequest = {
  customerOrganizationIdentifier: string;
  cwsId: string;
  dealerCode?: string;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
};


export type DeleteCustomerOrganizationsResponse = {
  responseMetadata?: ResponseMetadata;
};

export type AssociatedCustomerOrganizationsByCustomerIdentifierRequest = {
  customerOrganizationIdentifier: string;
};

export type AssociatedDealerCustomerOrganizationsRequest = {
  customerOrganizationIdentifier: string;
  xCatUserPreferences?: string;
  cursor?: string;
  limit?: number;
  skip?: number;
};

export type DealerCustomerAssociations = {
  customerOrganizationIdentifier: string;
  customerOrganizationBusinessName: string;
  locked: boolean;
  status: string;
};

export type DealerCustomerResponse = {
  dealerCustomerDetails: DealerCustomer;
  dealerCustomerAddresses: Array<DealerCustomerAddress>;
  dealerCustomerEmails: Array<CustomerEmail>;
  dealerCustomerPhones: Array<CustomerPhone>;
  dealerCustomerAssociations?: DealerCustomerAssociations;
};

export type OrganizationDealerCustomersResponse = {
  dealerCustomers: Array<DealerCustomerResponse>;
  responseMetadata?: ResponseMetadata;
};

export default CustomerOrganization;
