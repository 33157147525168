import React from 'react';
import { CatBadge as Badge } from 'blocks-react/bedrock/components/Badge';

type BadgeProperties = {
  id: string;
  label: string;
  variant?:
    | 'data-primary'
    | 'data-secondary'
    | 'data-tertiary'
    | 'data-quaternary'
    | 'blue'
    | 'green'
    | 'grey'
    | 'yellow'
    | 'orange'
    | 'red'
    | 'black'
    | 'white';
  isDot?: boolean;
  position?: 'top-left' | 'top-right';
};

const CatBadge = (props: BadgeProperties) => {
  const { id, label, variant, isDot, position } = props;
  return (
    <Badge
      id={id}
      data-testid="badge"
      text={label}
      variant={variant}
      isDot={isDot}
      position={position}
    />
  );
};

export default CatBadge;
