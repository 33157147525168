import React, { ComponentType, ReactNode, FC } from 'react';
import { Box, Grid, Typography, Card, List } from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import { ListChildComponentProps } from 'react-window';
import useStyles from './styles';
import InfiniteLoaderWrapper from '../../../infinite-loader-wrapper';
import Loading from '../../../loading';
import { useTranslation } from 'react-i18next';
import TEXT from '../../../../globals/translation-map';
import CAT_COLOR from '../../../../globals/color-properties';

type TransferTableListProperties<T> = {
  ItemComponent: FC<{ item: T }>;
  title: ReactNode;
  testDataId: string;
  clearItems: ReactNode;
  items: Array<T>;
  itemHeight?: number;
  noItemsFoundText?: string;
  hasNextPage?: boolean;
  nextPageCallbackFunction: () => Promise<void>;
  isLoadingDataForNextPage?: boolean;
  actionButton?: (item?: T) => ReactNode;
  listWidth?: string;
};

const TransferTableList = <T,>(properties: TransferTableListProperties<T>) => {
  const {
    ItemComponent,
    title,
    testDataId,
    clearItems,
    items,
    itemHeight,
    noItemsFoundText,
    hasNextPage,
    nextPageCallbackFunction,
    isLoadingDataForNextPage,
    actionButton,
    listWidth,
  } = properties;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const ItemComponentToRender: ComponentType<ListChildComponentProps<T>> = (
    props: ListChildComponentProps<T>,
  ) => {
    const { index, style } = props;
    if (!isItemLoaded(index)) {
      return (
        <Box style={style} display="flex" justifyContent="center" alignItems="center" mt={2}>
          <Typography>
            {t(TEXT.MY_CUSTOMERS.ASSOCIATE_DEALER_CUSTOMER.LOADING_MORE_RECORDS)}
          </Typography>
        </Box>
      );
    }
    return (
      <Box
        style={{
          ...style,
          // eslint-disable-next-line unicorn/no-useless-spread
          ...{ borderBottom: `1px solid ${CAT_COLOR.TRANSFER_TABLE_BORDER_GRAY}` },
        }}
      >
        <Grid container>
          <Grid xs={10} item>
            <ItemComponent item={items[index]} />
          </Grid>
          <Grid xs={2} item>
            <Box pr={3}>{actionButton && actionButton(items[index])}</Box>
          </Grid>
        </Grid>
      </Box>
    );
  };
  return (
    <Card className={classes.card} style={{ width: listWidth }}>
      <CardHeader className={classes.cardHeader} title={title} subheader={clearItems} />
      <List className={classes.list} dense component="div" role="list" data-testid={testDataId}>
        {items.length > 0 ? (
          <InfiniteLoaderWrapper
            hasNextPage={!!hasNextPage}
            isNextPageLoading={!!isLoadingDataForNextPage}
            items={items}
            loadNextPage={nextPageCallbackFunction}
            ItemComponent={ItemComponentToRender}
            height={350}
            width="100%"
            itemSize={itemHeight || 128}
          />
        ) : (
          <Box textAlign="center">
            {isLoadingDataForNextPage ? (
              <Typography component="div">
                <Loading />
              </Typography>
            ) : (
              <Typography data-testid="no-item-found-text" className={classes.noMatchFoundText}>
                {noItemsFoundText || ''}
              </Typography>
            )}
          </Box>
        )}
        <ListItem />
      </List>
    </Card>
  );
};

export default TransferTableList;
